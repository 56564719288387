@import "src/scss/variables";

.AddOfferAutomoto {
  .MuiGrid-spacing-xs-1 {
    width: calc(100% + 8px);
  }

  .darkGrey {
    color: $dark-grey;
  }

  .mt-3 {
    margin-top: 3px;
  }

  .mt-5 {
    margin-top: 5px;
  }

  .mb-15 {
    margin-bottom: 15px;
  }

  .color {
    display: flex;
    align-items: flex-end;

    @media all and (max-width: 959.98px) {
      margin-top: 1rem;
    }
  }

  .MuiSelect-root, .MuiInputBase-root {
    font-size: 13px !important;
    color: $dark-grey !important;
  }

  .MuiFormHelperText-root {
    margin-left: 0px;
    margin-right: 0px;
  }

  .MuiTextField-root {
    .MuiInputLabel-shrink {
      background-color: $white;
      padding-right: 10px;
    }
  }

  .borderSolid {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  /* For Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type="number"]::-ms-clear {
    display: none;
  }

  /* Webkit browsers like Safari and Chrome */
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .mileage, .AdditionalInfo {
    .MuiFormHelperText-root {
      color: $red;
      position: absolute;
      bottom: -22px;
    }

    &--disabled {
      opacity: 0.2;
    }
  }

  .BasicInfo {

    .customSelect--disabled, .text-right--disabled h6 {
      opacity: 0.2;
    }

    .multilineTextfield {
      .MuiFormHelperText-root {
        text-align: right;
      }

      .MuiInputBase-root {
        height: auto;
        padding: 10px;

        .MuiInputBase-input {
          height: auto;
          padding: 0;
        }          
      }

      &--disabled {
        opacity: 0.2;
      }
    }

    .offerNameWrapper {

      @media all and (max-width: 499.98px) {
        display: flex;
        flex-direction: column;

        .offerTitleName {
          margin-top: 6px;
        }
      }
    }

    .detailInformation {
      @media all and (min-width: 960px) {
        &.MuiGrid-grid-md-4 {
          flex-grow: 0;
          max-width: 32.5%;
          flex-basis: 32.5%;
        }
      }
    }

    .loading {
      left: 0;
    }

    .alternativeFuelParams {
      .param {
        width: calc(25% - 3px);

        &:not(:last-of-type) {
          margin-right: 4px;
        }

        @media all and (min-width: 400px) and (max-width: 1279.98px) {
          width: calc(50% - 4px);

          &:not(:nth-last-of-type(2)) {
            margin-right: 4px;
          }
        }

        @media all and (max-width: 399.98px) {
          width: 100%;

          &:not(:last-of-type) {
            margin-right: 0;
          }
        }
      }
    }
  }

  .DetailedInfo .showDetails {
    width: 260px;
    height: 38px;
    color: $white;
    font: bold 14px/18px $open-sans;
    background: transparent linear-gradient(180deg, $blue-pastel 0%, $blue-pastel-dark 100%) 0% 0% no-repeat;
    
    @media all and (max-width: 499.98px) {
      width: 100%;
    }

    .MuiSvgIcon-root {
      color: $white;
      position: relative;
      pointer-events: none;
    }
  }

  .DetailedInfo, .AdditionalInfo, .PriceInfo, .Comment {
    .gridColumn {
      display: grid;
      grid-template-columns: 33.3% 33.3% 33.3%;
      grid-auto-flow: column;
      width: 100%;
      @media all and (max-width: 959.98px) {
        grid-template-columns: 50% 50%;
      }

      @media all and (max-width: 499.98px) {
        grid-template-columns: 100%;
        grid-template-rows: auto !important;
        grid-auto-flow: row;
      }
    }

    .item {
      min-height: 45px;
      align-items: flex-start;
    }

    .customSelect {
      width: calc(100% - 41px);
      padding: 10px 10px 10px 31px;

      .MuiInputBase-root .MuiSelect-root {
        font-size: 12px !important;
        line-height: 14px;
        height: unset;
      }
    }

    .multilineTextfield {
      .MuiFormHelperText-root {
        text-align: right;
      }

      .MuiInputBase-root .MuiInputBase-input {
        padding: 10px;
      }
    }

    .MuiButtonBase-root {
      padding: 0 9px !important;
    }

    .MuiTypography-body1 {
      font-size: 13px;
      letter-spacing: 0.12px;
      color: $dark-grey;
    }

    .makeStyles-icon-30 {
      border: 2px solid #e1e1e1;
    }
  }

  .DetailedInfo, .Comment {
    .MuiInputBase-root {
      height: auto;
      min-height: 30px;
    }

    .MuiOutlinedInput-multiline {
      padding: 0px 0px;
    }

    .chip-input {
      .MuiInputBase-root {
        padding: 10px;
        height: auto;
        min-height: 76px;
        align-items: flex-start;

        @media all and (max-width: 1079.98px) {
          padding: 20px 10px 10px;
        }
  
        input.MuiInputBase-input {
          margin: 6px 0 0 0;
        }
  
        .MuiChip-root {
          height: 24px;
          margin: 8px 8px 0 4px;
        }
      }
    }
  }

  .status {
    margin-bottom: 0px;

    .active {
      border: 1px solid $blue-pastel;

      svg {
        color: $blue-pastel;
      }
    }
  }

  .AdditionalInfo {
    .customSelect {
      min-width: 120px;
      margin: 10px 31px;
      padding: 0;

      @media (min-width: 960px) and (max-width: 1350px) {
        margin-left: 35px;
      }

      @media all and (min-width: 500px) and (max-width: 700px) {
        margin: 10px 35px;
      }

      @media all and (max-width: 389.98px) {
        margin: 10px 40px;
      }

      &.en {
        margin-top: 10px;
        margin-left: 35px;
      }
    }

    .multilineTextfield {
      .MuiFormHelperText-root {
        text-align: left;
      }
    }
  }

  .PriceInfo {
    .price {
      margin: 0;
    }

    .priceInfoDetail {
      @media all and (min-width: 960px) {
        padding-left: 15px;
      }
      @media all and (max-width: 960px) {
        padding-top: 15px;
      }
    }
  }

  .Locality {
    .holder {
      display: flex;
      align-items: baseline;
      @media all and (max-width: 499.98px) {
        flex-direction: column;
        h6 {
          margin-top: 5px;
        }
      }
    }

    .location {
      h6 {
        margin-bottom: 0px;
      }
    }
  }

  .PublicationSettings {
    .postAsItem {
      min-height: 90px;

      .icon {
        @media all and (max-width: 499.98px) {
          padding-right: 10px;

          img {
            width: 100%;
          }
        }
      }

      .holder {
        margin-bottom: 5px !important;

        .recommended {
          background-color: $blue-pastel;
          font-weight: normal;
        }
      }

      &.active {
        border: 1px solid $blue-pastel !important;
      }

      &.disabled {
        pointer-events: none;
        cursor: not-allowed;
      }

      .checkIcon {
        color: $blue-pastel;

        svg {
          color: $blue-pastel;
        }
      }

      .descPriceHolder {
        h4 {
          letter-spacing: 0.12px;
        }
      }
    }

    .topOffer {
      max-width: 450px;
      padding-left: 25px;
      margin-top: 10px;
      @media all and (max-width: 499.98px) {
        padding-left: 0px;
      }

      .price {
        padding-right: 15px;
        margin: 0;
      }

      .topItemHolder, .padding50 {
        padding-left: 50px;
        padding-right: 0px;

        &.disabled {
          pointer-events: none;
          cursor: not-allowed;
        }        

        .postAsItem {
          border: 1px dashed $grey;
          border-radius: 4px;
          padding: 5px 15px 10px 15px;

          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
    
            img {
              max-width: calc(100% - 10px);
              max-height: 50px;
            }
          }

          .contentHolder {
            padding-left: 5px;

            .days {
              margin-bottom: 5px;
              color: $grey-mat-focus;
            }
          }
        }
      }
    }

    .switch {
      .MuiFormControlLabel-root {
        margin-right: 0px;

        .MuiSwitch-switchBase {
          color: $light-grey;
        }

        .Mui-checked {
          color: $blue-pastel;
        }
      }

      .MuiSwitch-track {
        background-color: $grey-mat-focus;
      }

      .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: $blue-pastel;
      }
    }
  }

  .busAttributes {
    .attribute {
      max-width: calc(25% - 4px);
      flex-basis: calc(25% - 4px);
      
      @media all and (max-width: 959.98px) {
        max-width: calc(50% - 4px);
        flex-basis: calc(50% - 4px);
      }
    }
  }

  .caravanAttributes {
    .attribute {
      max-width: calc(33.33% - 4px);
      flex-basis: calc(33.33% - 4px);
      
      @media all and (max-width: 499.98px) {
        max-width: calc(50% - 4px);
        flex-basis: calc(50% - 4px);
      }
    }

    &.double {

      @media all and (min-width: 600px) {
        max-width: calc(66.66%);
        flex-basis: calc(66.66%);          
      }

      .attribute {
        max-width: calc(50% - 3px);
        flex-basis: calc(50% - 3px);

        @media all and (min-width: 420px) and (max-width: 499.98px) {
          max-width: calc(50% - 4px);
          flex-basis: calc(50% - 4px);
        }

        @media all and (max-width: 419.98px) {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
    }
  }
}

.rightSideAutomoto {
  .title {
    margin: 0 !important;
  }

  .additionalText {
    img {
      width: 30px;
    }
  }

  .paymentObligation {
    padding-top: 5px;

    @media all and (max-width: 499.98px) {
      font-size: 10px;
    }
  }

  .publishButtonSpinner {
    margin-left: 10px;
    color: $white;
  }
}