@import "src/scss/variables";

.MyOrders {
  .pr-20 {
    padding-right: 20px;
  }

  .filters {
    padding: 20px 30px 20px 20px;

    @media all and (max-width: 499.98px) {
      min-height: 140px;
      align-items: flex-start;

      .total {
        margin-top: 10px;
      }
    }

    .MuiInputBase-input {
      padding: 10.5px 30px 10.5px 14px;
    }

    .MuiFormControl-root {
      padding-right: 10px !important;
      min-width: 180px;

      @media all and (max-width: 499.98px) {
        width: 100%;
        margin-top: 5px;
        margin-bottom: 10px;
        padding-right: 0px !important;

        .MuiInputLabel-shrink {
          transform: translate(14px, 1px) scale(0.75) !important;
        }
      }
    }
  }

  .ordersLoading {
    position: unset;
    min-height: 440px;  
  }  
}

.totalPrice {
  min-width: 80px;
}

.orderStatus {
  min-width: 170px;
}

.collapsedTable {
  .status {
    padding: 5px 10px;
    border-radius: 16px;
    letter-spacing: 0.25px;
  }

  .received {
    background-color: $grey-mat-unfocus;
  }

  .processing {
    background-color: $gold-digger;
  }

  .shipped {
    background-color: $green-pastel;
  }

  .delivered {
    background-color: $green;
  }

  .refunded {
    background-color: $grey-mat-unfocus;
  }

  .canceled {
    background-color: $grey-mat-unfocus;
  }

  .partiallyRefunded {
    background-color: $grey-mat-unfocus;
  }

  .customButton {
    padding: 5px 10px !important;
    color: $dark-grey !important;
    background-color: $white-snow;
    border: 1px solid $grey-mat-unfocus;
    border-radius: 4px;
    font-weight: normal !important;
    font-size: 13px;
  }

  .itemImage {
    max-width: 125px;
    width: 125px;
  }

  .itemPrice {
    max-width: 100px;
    width: 100px;
  }

  .orderDetailImg {
    max-width: 100px;
    max-height: 100px;
    object-fit: contain;
  }

  .MuiTableCell-root:empty {
    border-bottom: 0;
  }

  .MuiCollapse-wrapper {
    .MuiTableCell-root {
      border-bottom: 0;
    }
  }

  .light {
    color: $grey;
    cursor: pointer;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .loadingSpinner {
    margin-left: 10px;
    color: $red-pastel;
  }
}

.cancelOrderPopUp {
  .swal2-header {
    display: none;
  }

  .link {
    margin-right: 20px;
  }
}