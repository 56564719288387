@import "../../../../../scss/_variables";
@import "../DetailedRating/DetailedRating.scss";

.userSummaryRatingWrapper {
  background-color: $white;
  padding: 25px 30px;
  border-radius: 4px;

  @media all and (max-width: 499.98px) {
    padding: 10px 15px;
  }

  .link {
    color: $cyan-pastel
  }

  .sellerStoreLink {
    font-size: 14px;

    &__skeleton {
      width: 30%;
      max-width: 200px;
      height: 14px;
    }
  }


  .overallSummaryWrapper {
    max-width: 300px;
    font-size: 14px;

    .overallSummaryRating {
      font-size: 36px;
      font-family: $roboto;

      &__skeleton {
        width: 50px;
        height: 46px;
      }
    }

    .overallSummaryRatingDescription {
      font-family: $open-sans;

      &__skeleton {
        width: 70%;
        height: 24px;
      }
    }

    .ratingThumbsWrapper {
      display: inline-flex;
      align-items: center;

      .ratingThumbsUpIcon {
        color: $green;
        svg {
          padding-bottom: 5px;
        }
      }

      .ratingThumbsDownIcon {
        color: $red;

        svg {
          transform: rotate(180deg);
          padding-bottom: 5px;
        }
      }

      .ratingCount {
        padding-left: 11px;
        font-family: $open-sans;

        &Positive {
          padding-right: 40px;
        }
      }

      &__skeleton {
        margin-right: 10px;
        width: 90px;
        height: 44px;
      }
    }
  }
  
  .detailedRatingWrapper {
    order: 2;

    @media all and (max-width: 959.98px) {
      order: 1;
      padding-top: 15px;
    }

    .detailedRatingTitleWrapper {
      padding-top: 10px;
      padding-bottom: 10px;

      .detailedRatingTitle{
        font-size: 16px;
        font-weight: bold;
        padding-left: 2px;

        &__skeleton {
          width: 40%;
          max-width: 250px;
          height: 24px;
        }
      }
    }

    .detailedRatingItem {
      @media all and (max-width: 1219.98px) {
        padding-top: 20px;
      }
    }

    .ratingsWrapper {
      @media all and (min-width: 1220px) {
        flex-direction: row;
      }
    }
  }
  
  
  .storeLinkWrapper {
    order: 1;

    @media all and (max-width: 959.98px) {
      order: 2;
      justify-content: center;
      padding-top: 15px;
    }

    @media all and (max-width: 1199.98px) {

    }

  }
}