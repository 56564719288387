@import "../../../../scss/variables";

.filters {
  margin-top: 8px;
  padding-bottom: 30px;
  background-color: #FFFFFF;

  &.d-none {
    display: none;
  }

  @media all and (max-width: 959.98px) {
    padding-bottom: 8px;
  }

  .filtersTitle {
    width: 100%;
    text-align: left;
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
    color: $dark-grey;
    font-family: $open-sans;
    padding: 10px 0px 20px 40px;

    @media all and (max-width: 959.98px) {
      display: none;
    }
  }

  .filtersWrapper {
    padding: 10px 30px 0 40px;

    @media all and (min-width: 500px) and (max-width: 959.98px) {
      padding: 20px 30px 0 40px;
    }

    @media all and (max-width: 499.98px) {
      padding: 20px 20px 0 20px;
    }

    .filterOptions {
      margin-bottom: 1rem;

      &.noMargin {
        margin-bottom: 0;
      }

      &.colors {
        display: flex;
        flex-wrap: wrap;
      }

      &--last:last-of-type {
        margin-bottom: 0;
      }

      .removeVehicleInfo {
        width: 100%;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 0.5rem;

        svg {
          color: $grey-mat-unfocus;

          &:hover {
            color: $red-pastel;
          }
        }
      }

      .filterHeader {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;

        &.brandModel {
          margin-bottom: 1.5rem;
        }

        .removeFilter {
          display: flex;
          align-items: center;
          cursor: pointer;
          font: normal 13px/16px $roboto;

          &:hover {
            color: $medium-aquamarine;

            svg {
              color: $red-pastel;
            }
          }

          svg {
            color: $grey-mat-unfocus;
            margin-right: 4px;
          }
        }
      }

      .filterTitle {
        font: bold 14px/16px $open-sans;
      }

      .rangeValues {
        display: flex;
        justify-content: space-between;
        font: normal 14px/20px $open-sans
      }

      @media all and (max-width: 959.98px) {
        margin-bottom: 0.5rem;
      }
    }

      .filterLabel {
        .MuiTypography-root {
          font-size: 14px;
        }

        &:hover {
          .MuiTypography-root {
            color: $medium-aquamarine;
          }
        }

        .handShakeIcon svg {
          width: 15px;
          height: auto;
          padding-left: 4px;
          color: $red-pastel;
        }
      }
  }

  .divider {
    width: 100%;
    margin: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }

  .expandAll {
    width: 100%;
    color: $medium-aquamarine;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    text-transform: uppercase !important;
    font: normal 14px/18px $roboto;

    &.upper {
      margin-bottom: 0;
    }

    @media all and (max-width: 959.98px) {
      margin-bottom: 0.5rem;
    }
  }
}

.colorTooltip {
  top: 26px !important;
}