@import "src/scss/variables";

.carousel {
  &__container {
    position: relative;

    .image-gallery {
      &.fullscreen-modal {
        z-index: 1002;
        background: rgba(0, 0, 0, 0.45);

        .image-gallery-index {
          display: block;
          font: 14px $roboto;
        }

        @media all and (max-height: 580px) {
          overflow-y: scroll;
        }

        .image-gallery-content.fullscreen {
          max-width: 1500px;
          margin: 20px;
          padding: 50px;
          border-radius: 10px;
          background: $white;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: row;

          @media all and (max-height: 399.98px) {
            top: 0;
            transform: none;
          }

          @media all and (max-width: 767.98px) {
            margin: 0;
            height: 100%;
            padding: 0;
            flex-direction: column;
          }

          @media all and (min-height: 400px) and (max-width: 767.98px) {
            justify-content: center;
          }

          @media all and (max-height: 399.98px) and (max-width: 767.98px) {
            justify-content: flex-end;
          }

          @media all and (min-width: 1440px) {
            margin: auto;
            height: 80vh;
            max-width: 90vw;
          }

          .image-gallery-slide-wrapper {
            width: 80%;
            outline: none;
            margin-right: 20px;

            @media all and (min-width: 1440px) {
              height: 100%;

              .image-gallery-swipe {
                height: 100%;

                .image-gallery-slides {
                  height: 100%;
                }
              }
            }

            @media all and (max-width: 767.98px) {
              width: 100%;
            }

            .image-gallery-fullscreen-button {
              display: flex;
              top: calc(-1vh - 3px);
              right: calc(-19vw - 3px);
              padding: 0;
              color: $grey;
              border: 0;
              height: 30px;
              position: absolute;
              background: transparent;
              
              &:hover {
                cursor: pointer;
              }

              @media all and (min-width: 1640px) {
                top: -3vh;
                right: calc(-21vw - 4px);

                .MuiSvgIcon-root {
                  margin-right: 0.5rem;
                }
              }

              @media all and (min-width: 768px) and (max-width: 1639.98px) {
                top: -4vh;
                right: calc(-21vw - 10px);
              }

              @media all and (max-width: 767.98px) {
                position: fixed;
                top: 8px;
                right: 8px;
              }
            }

            .image-gallery-slide {
              display: flex;
              height: 650px;
              max-height: 650px;

              div {
                height: 650px;
                max-height: 650px;

                @media all and (min-width: 1440px) {
                  height: 100%;
                  max-height: 100%;
                }
              }

              .image-gallery-image {
                max-height: 650px;

                @media all and (min-width: 1440px) {
                  max-height: 100%;
                }
              }

              @media all and (min-width: 1440px) {
                height: 100%;
                max-height: 100%;
              }

              @media all and (min-width: 500px) and (max-width: 767.98px) {
                height: 300px;
                max-height: 300px;

                .image-gallery-image {
                  max-height: 300px;
                }
              }

              @media all and (max-width: 499.98px) {
                height: 280px;
                max-height: 280px;

                .image-gallery-image {
                  max-height: 280px;
                }
              }
            }
          }

          .image-gallery-thumbnails-wrapper {
            width: 20%;

            @media all and (max-width: 767.98px) {
              width: 100%;
            }
          }

          .image-gallery-thumbnails {
            margin-top: 30px;

            @media all and (max-width: 767.98px) {
              display: none;
            }

            @media all and (max-width: 499.98px) {
              margin-top: 10px;
            }

            .image-gallery-thumbnails-container {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex-wrap: wrap;

              .image-gallery-thumbnail {
                width: 50px;
                height: 50px;
                margin-bottom: 10px;
                
                &:first-child {
                  margin-left: 10px;
                }

                &:last-child {
                  margin-right: 0;
                }
            
                &:hover {
                  cursor: pointer;
                }
            
                &.active, &:hover {
                  border: 1px solid $red-pastel;
                  box-shadow: 0px 0px 10px $red-pastel;
                }

                .image-gallery-thumbnail-image {
                  max-width: 48px;
                  max-height: 48px;
                  object-fit: scale-down;
                }
              }
            }
          }

          .product-title {
            margin: 10px 0 0 10px;
            display: block;
          
            @media all and (max-width: 767.98px) {
              margin: 10px;
              text-align: center;
            }
          }

          .fullscreenModalIcon {
            z-index: 1001;
            cursor: pointer;
            position: absolute;
            right: 0;
            bottom: 0;
            background: none;
            border: none;
            padding: 10px;
            border: 1px solid $grey;
            background-color: rgba(255, 255, 255, 0.5);

            @media all and (min-width: 768px) {
              display: block;
            }
          }
        }
      }
    }

    .fullscreenIcon {
      cursor: pointer;
      position: absolute;
      right: 0;
      bottom: 80px;
      top: 355px;
      background: none;
      border: none;
      color: #767676;
      padding: 10px;
      border: 1px solid $grey;
      background-color: rgba(255, 255, 255, 0.5);
  
      @media all and (max-width: 1365.98px) {
        display: none;
      }
    }

    .fullscreenModalIcon {
      display: none;
    }

    &.browserFullscreen {
      .image-gallery:not(.fullscreen-modal) {
        .image-gallery-content.fullscreen {
          overflow: auto;

          @media all and (max-height: 579.98px) {
            height: 100%;
          }

          .image-gallery-slide-wrapper {
            margin: 3rem 0 1rem 0;

            @media all and (max-width: 767.98px) {
              height: calc(100% - 6rem);
            }

            .image-gallery-fullscreen-button {
              display: flex;
              top: -3rem;
              padding: 1rem;
              color: $white;
              border: 0;
              height: 30px;
              position: absolute;
              background: transparent;
              
              &:hover {
                cursor: pointer;
              }
            }

            .image-gallery-slides {
              height: 100%;
            }

            .image-gallery-slide {
              max-width: 100%;
              max-height: 100%;
              height: calc(85vh - 6rem);

              @media all and (max-width: 767.98px) {
                height: calc(100vh - 6rem);
              }

              @media all and (max-height: 579.98px) {
                height: unset;
                min-height: unset;
              }

              .image-gallery-image {
                max-height: calc(100vh - 240px);

                @media all and (max-width: 767.98px) {
                  max-height: 100%;
                }

                @media all and (min-height: 580px) and (max-height: 767.98px) {
                  max-height: calc(100vh - 12rem);
                }

                @media all and (min-height: 450px) and (max-height: 579.98px) {
                  max-height: calc(100vh - 6rem);
                }

                @media all and (max-height: 449.98px) {
                  max-height: 350px;
                }
              }
            }
          }

          .product-title {
            color: $white;
            display: block;
            margin: 1rem 1rem 0 1rem;
            text-align: center;
          
            @media all and (max-width: 767.98px) {
              margin: 10px;
            }

            @media all and (max-height: 579.98px) {
              margin: 1rem;
            }
          }

          .image-gallery-thumbnails {
            @media all and (max-height: 579.98px) {
              display: none;
            }
          }
        }
      }
    }
  }

  &__imagesNotAvailable {
    display: flex;
    justify-content: center;

    img {
      @media all and (min-width: 800px) {
        margin-top: 50px;
      }
    }
  }
}

.product-title {
  display: none;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  font: 14px $roboto;
}

.image-gallery-index {
  display: none;
}

.image-gallery-thumbnails {
  margin-top: 10px;

  .image-gallery-thumbnails-container {
    
    @media all and (max-width: 767.98px) {
      display: none;
    }

    .image-gallery-thumbnail + .image-gallery-thumbnail {
      margin-left: 10px;
    }  

    .image-gallery-thumbnail {
      width: 60px;
      height: 60px;
      border: 1px solid $grey;

      &:first-child {
        margin-left: 4px;
      }

      &:last-child {
        margin-right: 4px;
      }

      &:hover {
        cursor: pointer;
      }

      &.active, &:hover {
        border: 1px solid $red-pastel;
        box-shadow: 0px 0px 10px $red-pastel;
      }

      .image-gallery-thumbnail-image {
        max-height: 58px;
        object-fit: scale-down;
      }
    }
  }
}

.image-gallery-slides {
  display: flex;
  height: 100%;
  max-height: 100%;
}

.image-gallery-swipe {
  .image-gallery-slides {
    .image-gallery-slide {
      height: 400px;
      min-height: 400px;
      cursor: pointer !important;

      &:focus { 
        outline: none;
      }

      &:hover {
        cursor: pointer !important;
      }

      @media all and (max-width: 767.98px) {
        height: 280px;
        min-height: 280px;
      }
    }
  }
}


.image-gallery {

  div {
    &:focus {
      outline: none !important;
    }
  }

  .image-gallery-slide-wrapper {
    &:focus {
      outline: none;
    }

    .image-gallery-slide {
      height: 400px;
      min-height: 400px;
      align-items: center;
      justify-content: center;

      .image-gallery-image {
        width: unset;
        max-height: 400px;
        object-fit: contain;
        cursor: pointer !important;
        max-width: calc(100% - 3px);

        @media all and (max-width: 767.98px) {
          max-height: 280px;
        }
      }

      @media all and (max-width: 767.98px) {
        height: 280px;
        min-height: 280px;
      }
    }

    .image-gallery-fullscreen-button {
      display: none;
    }

    .image-gallery-play-button, .image-gallery-left-nav, .image-gallery-right-nav {

      &.image-gallery-icon {
        filter: unset;
        padding-top: 0;
        padding-bottom: 0;
        max-height: 50px;      
        color: $grey-mat-focus;
        border: 1px solid $grey;
        background-color: rgba(255, 255, 255,0.5);

        .image-gallery-svg {
          width: 25px;
          height: 50px;
        }
      }
    }
  }
}