@import "../../../../scss/variables";
.searchResultsPageHeader {

    @media all and (min-width: 960px) {
        &.MuiGrid-container {
            flex-wrap: nowrap;
        }
    }

    .mr-5 {
        margin-right: 5px;
    }
    .mb-5 {
        margin-bottom: 5px;
    }
    .mb-10 {
        margin-bottom: 10px;
    }
    .mt-5 {
        margin-top: 5px;
    }
    .mt-10 {
        margin-top: 10px;
    }
    .mt-20 {
        margin-top: 20px;
    }
    .pr-10 {
        padding-right: 10px;
    }
    .pr-40 {
        padding-right: 40px;
    }
    .dark {
        color: $dark-grey;
    }
    .primary {
        color: $red-pastel;
    }
    .white {
        color: $white;
    }
    .auto {
        width: auto;
    }
    .ml-20 {
        margin-left: 20px;
    }

    & > .title {
        width: 380px;
        max-width: 297px;
        @media all and (max-width: 959.98px) {
          display: none;
        }
        h2 {
            margin: auto 0;
            color: $dark-grey;
            padding-left: 40px;
            margin-right: auto;
        }
    }

    .skeleton {
        &__rect {
            height: 28px;
            margin: 0px;
            margin-left: 40px;

            &.MuiSkeleton-text {
              transform: none;
            }

            &__seller {
                width: 700px;
            }

            &__category {
                width: 200px;
            }

            @media all and (min-width: 500px) and (max-width: 959.98px) {
                margin-left: 0px;
                height: 22px;
                width: 550px;
            }

            @media all and (max-width: 499.98px) {
                margin-left: 0px;
                height: 14px;
                width: 200px;
            }
        }
    }

    .userPageTitleHolder {
        align-self: center;
        align-items: center;

        @media all and (max-width: 499.98px) {
            display: block;
            padding-left: 20px;
        }

        @media all and (min-width: 500px) and (max-width: 959.98px) {
            padding-left: 20px;
        }

        .userPageTitle {
            display: inline-flex;

            .sellerName {
                cursor: pointer;
            }

            h2 {
                margin-top: 0;
                margin-bottom: 0;
                color: $dark-grey;
                padding-left: 40px;
                margin-right: auto;
            }
            
            @media all and (max-width: 959.98px) {
                h2 {
                    font-size: 23px;
                    padding-left: 0px;
                    margin-right: 10px;
                }
            }

            @media all and (max-width: 499.98px) {
                h2 {
                    font-size: 16px !important;
                    padding-left: 0px;
                    margin-right: 10px;
                }
            }
        }
    }

    .sellerInfoHolder {
        padding-left: 10px;
        align-self: center;

        @media all and (max-width: 960px) {
            padding-left: 0px;
        }
    }

    .searchHeader {
        align-items: flex-end;
        padding-left: 10px;
        padding-right: 10px;
        justify-content: space-between;
        @media (min-width: 960px) and (max-width: 1300px) {
            justify-content: flex-end;
        }
        @media all and (max-width: 960px) {
            .resultsNum {
                width: auto;
                margin-top: 10px;
            }
            .MuiButtonBase-root,
            .MuiInputBase-root {
                height: 30px;
            }
        }
        @media (min-width: 500px) and (max-width: 649.98px) {
            justify-content: flex-end;
        }
        @media all and (max-width: 499.98px) {
            display: none;
        }
        .resultsNum {
            .primary {
                width: calc(100% - 230px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: inline-block;
                vertical-align: bottom;
            }
            @media (min-width: 960px) and (max-width: 1300px) {
                display: none;
            }
            @media all and (max-width: 649.98px) {
                display: none;
            }
            h5 {
                letter-spacing: 0.13px;
                color: $dark-grey;
            }
        }
        .searchChangeViewHolder {
            justify-content: flex-end;
            min-height: 36px;
            @media all and (max-width: 499.98px) {
                justify-content: flex-start;
            }
            .search {
                width: 200px;
                margin-right: 100px;

                @media all and (max-width: 959.98px) {
                    display: none;
                }

                @media all and (max-width: 1065px) {
                    margin-right: 20px;
                }

                @media all and (max-width: 1199px) {
                    margin-right: 0;
                }

                .MuiFormControl-root {
                    width: 200px;
                    @media all and (max-width: 499.98px) {
                        margin: 15px 0;
                    }
                }
                .MuiInputLabel {
                    color: $light-grey;
                }
                .MuiInputBase-input {
                    color: $light-grey;
                }
                .MuiSvgIcon-root {
                    color: $grey-mat-focus;
                    width: 1.2em;
                    height: 1.2em;
                    right: 0;
                    position: absolute;
                    pointer-events: none;
                }
                .MuiSelect-select:focus {
                    background-color: transparent;
                }
            }
            .changeView {
                width: auto;
                align-items: center;
                display: flex;
                @media all and (max-width: 1199px) {
                    display: none;
                }
                .cardLayoutImage {
                    margin-right: 20px;
                    cursor: pointer;
                }
                .listLayoutImage {
                    cursor: pointer;
                }
            }
            .viewTypeSelection {
                max-height: 36px;
                button:not([class*='Mui-selected']) {
                    background-color: $white
                }
            }

        }
    }

    .mobile-header {
        width: 100%;
        margin-top: -10px;
        text-align: center;

        @media all and (min-width: 500px) and (max-width: 959.98px) {
            width: calc(100% + 20px);
            margin: -10px -12px 12px -8px;
        }

        @media all and (min-width: 960px) {
            display: none;
        }

        &.shop {
            padding-bottom: 8px;
        }

        .carousel {
            margin-bottom: 0;
        }
    }

    .collapsibleFilters,
    .collapsibleSortOptions {
        width: calc(100% + 20px);
        margin: -20px -12px 12px -8px;

        @media all and (min-width: 960px) {
            display: none;
        }
        
        @media all and (max-width: 499.98px) {
            margin: -8px 0 0 0;

            &.seller {
                margin: -16px 0 8px 0;
            }
        }
    }

    .collapsibleSortOptions{
        width: calc(100% + 16px);
    }
}

.floatingButtonWrapper {
    display: none;

    @media all and (max-width: 959.98px) {
      display: block;
      position: fixed;
      z-index: 100;
      right: 20px;
      bottom: 90px;
      border-radius: 50px;
      background-color: $white;
    }

    .floatingButton {
      padding: 10px;
      border-radius: 50px;
      min-height: 50px;
      min-width: 50px;
      color: $white;
    }
  }