.sellerInfo {
  color: $light-grey;
  font-size: 13px;
  line-height: 18px;
  font-family: $open-sans;
  display: flex;
  align-items: center;
  width: auto !important;

  .userInfoHolder {
    display: flex;
    align-items: center;

    @media all and (max-width: 500px) {
      font-size: 11px;
      flex-wrap: wrap;
    }
  }

  .ratingHolder {
    display: flex;
    align-content: center;
    height: 18px;

    a {
      text-decoration: none;
      color: $cyan-pastel;

      &:hover {
        color: $cyan-focus;
      }
    }

    .starsRatings {
      display: flex;
      align-content: center;

      @media all and (max-width: 499.98px) {
        display: none;
      }
    }

    .starsRatingsOnMobile {
      display: flex;
      align-content: center;

      @media all and (min-width: 500px) {
        display: none;
      }
    }

  }

  .handShakeIcon {
    display: flex;
    margin: 0 5px;

    svg {
      width: 22px;
      height: auto;
      color: $red-pastel;

      @media all and (max-width: 500px) {
        width: 17px;
      }
    }
  }

  .userIcon {
    margin: 0 5px;

    svg {
      width: 12px;
      height: auto;
      color: $light-grey;
    }
  }

  .nickname {
    margin-right: 15px;

    @media all and (max-width: 500px) {
      margin-right: 0;
    }

    .link {
      font-size: 13px !important;

      @media all and (max-width: 500px) {
        font-size: 11px !important;
      }
    }
  }

  .MuiRating-root {
    font-size: 18px;
    color: #F6CA6E;
    margin-right: 15px;
  }

  .MuiRating-iconEmpty {
    color: #F6CA6E;
  }
}
