@import 'variables';
@import './fonts.scss';
@import 'src/views/components/Footer/Footer.scss';
@import 'src/views/components/Header/Header.scss';
@import 'src/views/components/Dropdown/Dropdown.scss';
@import 'src/views/components/AutomotoSlider/AutomotoSlider.scss';
@import 'src/views/components/Checkbox/Checkbox.scss';
@import 'src/views/components/ColoredCheckbox/ColoredCheckbox.scss';
@import 'src/views/components/Radiobutton/Radiobutton.scss';
@import 'src/views/components/Container/Container.scss';
@import 'src/views/pages/Register/Register.scss';
@import 'src/views/components/Content/Content';
@import 'src/views/components/CookiesBar/CookiesBar.scss';
@import "src/views/pages/NewPassword/NewPassword";
@import "src/views/components/PasswordInput/PasswordInput";
@import "src/views/components/EnhancedTable/EnhancedTable";
@import "src/views/pages/Login/Login";
@import "src/views/pages/PasswordReset/PasswordReset";
@import "src/views/pages/LandingPage/LandingPage";
@import "src/views/pages/AddOffers/Marketplace/AddOfferMarketplace";
@import "src/views/pages/MyAccount/MyAccount.scss";
@import "src/views/components/AutomotoDropdown/AutomotoDropdown";
@import "src/views/components/MultilevelDropdown/MultilevelDropdown";
@import "src/views/components/MediaCarousel/MediaCarousel";
@import "src/views/pages/SearchResultPage/SearchResultPage";
@import "src/views/pages/ProductDetail/Main";
@import "src/views/components/NotificationBar/NotificationBar.scss";
@import "src/views/pages/AddOffers/Automoto/AddOfferAutomoto";
@import "src/views/pages/Cart/AddedToCart/AddedToCart";
@import "src/views/pages/Cart/Cart/Cart";
@import "src/views/components/AddToCartCounter/AddToCartCounter";
@import "src/views/pages/Cart/Checkout/Checkout";
@import "src/views/components/EditDeliveryTypeMobilePopup/EditDeliveryTypeMobilePopup";
@import "src/views/pages/UserRatingPage/UserRatingPage";
@import "src/views/pages/VehicleComparisonPage/VehicleComparisonPage";
@import "src/views/pages/WatchListPage/WatchListPage";
@import "src/views/components/ConfirmationPopup/ConfirmationPopup";
@import 'src/views/components/OauthButtons/OauthButtons.scss';

html, body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0 !important;
  line-height: unset !important;

  #root {
    height: 100%;

    & > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
  }

  @media all and (max-width: 319.98px) {
    overflow: auto;
  }
}

.text-danger {
  color: $red;
  width: 100%;
  min-height: 19px;

  &.MuiFormHelperText-root {
    color: $red;
  }
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.error-container {
  width: 100%;
  height: 20px;
}

.uppercase {
  text-transform: uppercase;
}

.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $white;
  opacity: 0.5;
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;

  &--image {
    position: absolute;
    opacity: 0.7;
  }
}

/* Default Contained Button */

.MuiButton-contained {
  color: $white;
  font: 14px/16px $open-sans;
  height: 36px;
  border-radius: 5px;
  background: transparent linear-gradient(180deg, #FF8080 0%, #EF6666 100%) 0 0 no-repeat padding-box;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none !important;

  &.automotoButton {
    background: transparent linear-gradient(180deg, $blue-pastel 0%, $blue-pastel-dark 100%) 0 0 no-repeat;
  }

  &.marketplaceButton {
    background: transparent linear-gradient(180deg, $yellow-pastel 0%, $yellow-pastel-dark 100%) 0 0 no-repeat;
  }

  &.spare-partsButton {
    background: transparent linear-gradient(180deg, $purple-pastel 0%, $purple-pastel-dark 100%) 0 0 no-repeat;
  }

  &:hover {
    opacity: 0.7;
  }
}

/* Default Button */

.MuiButton-root {
  text-transform: none !important;
  letter-spacing: 0 !important;
}

.MuiLink-root {
  font-family: $open-sans;
  color: $cyan-pastel;
  text-transform: uppercase;
  letter-spacing: 0.47px;
  cursor: pointer;

  &--no-uppercase {
    text-transform: none;
  }

  &:hover {
    color: $medium-aquamarine;
    text-decoration: none !important;
  }
}

/* Change the white to any color ;) */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px $white inset !important;
  -webkit-box-shadow: 0 0 0 30px $white inset !important;
  @media all and (max-width: 499.98px) {
    box-shadow: 0 0 0 30px $light-grey-bg inset !important;
    -webkit-box-shadow: 0 0 0 30px $light-grey-bg inset !important;
  }
}

body {
  margin: 0;
  overflow-y: auto !important;

  h1, h2, h3, h4, h5, h6, span, div {
    font-family: $open-sans;
  }

  h2 {
    text-align: left;
    font-size: 28px;
    font-weight: 600;
    color: $dark-grey;
    margin-bottom: 40px;
    margin-top: 0;
    @media all and (max-width: 499.98px) {
      font-size: 18px !important;
    }
  }

  h3 {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    font-weight: normal;
    margin: 0;
  }

  h4 {
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
    margin: 0;
    text-align: left;
  }

  h5 {
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
    color: $light-grey;
    margin: 0;
  }

  h6 {
    font-size: 12px;
    font-weight: normal;
    color: $light-grey;
    margin: 0;
  }

  .text {
    font-size: 14px;
    line-height: 18px;
    font-family: $open-sans;
    font-weight: normal;
    color: $dark-grey;
    margin: 0;
    @media (max-width: $media-xs) {
      font-size: 13px;
      line-height: 16px;
    }
  }

  .link {
    text-decoration: none;
    color: $cyan-pastel !important;
    font-family: $open-sans;
    cursor: pointer;

    &:hover {
      color: $cyan-focus !important;
    }
  }

  &.noscroll {
    overflow: hidden !important;
  }

  &.swal2-shown.swal2-height-auto {
    max-height: 100vh;
    height: inherit !important;
    overflow: hidden;
  }

  .fullHeight {
    height: 100%;
  }

  .pointer {
    cursor: pointer;
  }

  .multiline-text-field {
    width: 100%;

    .MuiInputBase-root {
      min-height: 60px !important;
      height: auto !important;
      align-items: flex-start;
      font-size: 13px;
      padding: 5px 14px;

      .MuiInputBase-input {
        padding: 5px 0;
      }
    }

    .MuiInputLabel-shrink {
      padding-right: 5px;
      background-color: $white;
    }
  }

  .disabled {
    opacity: 0.2;
    cursor: auto;
  }

  .desktop {
    @media all and (max-width: 499.98px) {
      &.MuiGrid-container {
        display: none;
      }
    }
  }
  
  .mobile {
    @media all and (min-width: 500px) {
      &.MuiGrid-container {
        display: none;
      }
    }
  }

  .recaptchaV2Wrapper {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .grecaptcha-badge {
    bottom: 30% !important;
    z-index: 20;
    visibility: hidden;
  }
}

.sectionWrapper {
  display: inline-block;
  width: 100%;
  margin-bottom: 80px;

  @media all and (max-width: 768px) {
    margin-bottom: 50px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  img {
    box-sizing: border-box;
    padding: 5px;
    max-height: 100%;
    max-width: 100%;
  }

  .descriptionTwoColumns {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    & > div {
      @media all and (max-width: 768px) {
        min-width: 100%;
      }

      &.text {
        align-self: flex-start;

        &.alignContentCenter {
          align-self: center;
        }
      }

      &.image {
        display: flex;
        justify-content: center;
        max-width: 100%;

        &:first-of-type {
          @media all and (max-width: 768px) {
            margin-bottom: 50px;
          }
        }
      }

      &:nth-of-type(1) {
        width: 45%;
        padding-right: 2px;
      }

      &:nth-of-type(2) {
        width: 45%;
        padding-left: 2px;
      }
    }
  }

  .descriptionOneColumn {
    display: flex;
    align-items: center;
    font-family: $open-sans;

    &.image {
      justify-content: center;
    }

    img {
      width: 100%;
      box-sizing: border-box;
      padding: 5px;
    }

    td {
      word-break: break-all;
    }
  }
}
