@import "MyOrders/OrderDetail/OrderDetail";
@import "MyOrders/OrderAddReview/OrderAddReview";
@import "MyOrders/OrderReviewThank/OrderReviewThank";
@import "MyOrders/OrderCreated/OrderCreated";
@import "CreditTransactionsCard/CreditTransactionCard";
@import "SalesSettings/SalesSettings.scss";
@import "MyOrders/MyOrders";
@import "Invoices/Invoices";
@import "MyReceivedOrders/MyReceivedOrders";
@import "ProductReturns/ProductReturns";
@import "Chat/Chat";
@import "MyReceivedOrders/ReceivedOrderDetail/ReceivedOrderDetail";
@import "ProductReturns/CreateReturnRequest/CreateReturnRequest";
@import "ProductReturns/ReturnRequestDetailPage/ReturnRequestDetailPage";
@import "OffersImporter/OffersImporter";

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: auto;
  padding-right: 0 !important;
}

.swal2-container {
  overflow: auto !important;

  .swal2-popup {

    .code-error {
      color: red;
      width: 100%;
      min-height: 16px;
      font: 14px/16px $open-sans;
    }

    .swal2-content {
      padding: 0 !important;

      h3 {
        letter-spacing: 0.15px;
        font-weight: bold;
        margin-top: 30px;
      }

      form {
        width: 100%;

        .validation {
          font-family: $open-sans;
          margin-top: 2px;
          margin-bottom: 5px;
          text-align: left;
          color: $red;
          font-size: 12px;
          line-height: 20px;
          min-height: 20px;
          letter-spacing: 0.11px;
          z-index: 1;
        }
      }

      .MuiFormControlLabel-root {
        display: flex;

        .MuiTypography-body1 {
          text-align: left;
          font-size: 14px;
          font-family: $open-sans;
          color: $dark-grey;
        }
      }

      .MuiSelect-select:focus {
        background-color: $white !important;
      }

      .MuiSelect-outlined {
        text-align: left;
      }

      .MuiSvgIcon-root {
        width: 1.5em;
        height: 1.5em;
        pointer-events: none;
        position: absolute;
        right: 0;
        color: #767676;
      }

      .mr-40 {
        margin-right: 40px;
      }

      .mt-40 {
        margin-top: 40px;
      }

      .mt-30 {
        margin-top: 30px;
      }

      .mt-20 {
        margin-top: 20px;
      }

      .mb-20 {
        margin-bottom: 20px;
      }

      .mb-30 {
        margin-bottom: 30px;
      }

      .MuiButton-root {
        color: $white;
        text-transform: none;
        font-family: $open-sans;
      }

      .MuiFormControl-root {
        width: 80%;

        .MuiFormLabel-root {
          color: $grey-mat-focus;
          margin-top: -8px;
          font-size: 16px;

          &.Mui-error {
            color: #f44336 !important;
          }
        }

        .MuiInputBase-root {
          color: $dark-grey;
          height: 36px;
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: $grey-mat-unfocus;
        }

        .Mui-error {
          .MuiOutlinedInput-notchedOutline {
            border-color: #f44336 !important;
          }
        }

        .MuiInputLabel-shrink {
          transform: translate(14px, 1px) scale(0.75);
        }
      }

      .MuiInputBase-input {
        font: normal 14px/24px $open-sans;
        color: $dark-grey;
        height: 100%;
      }

      .Mui-disabled {
        color: $grey-mat-focus;

        &.MuiInputBase-input {
          color: $grey-mat-focus;
        }
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
        transition: $white 5000s ease-in-out 0s;
      }

      //remove X
      input[type="number"]::-webkit-clear-button {
        display: none;
      }

      //remove inside of arrows button
      input[type="number"]::-webkit-inner-spin-button {
        display: none;
      }

      //remove outsideof arrows button
      input[type="number"]::-webkit-outside-spin-button {
        display: none;
      }

      .sms-code {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &__container {
          width: 100%;
          max-width: 285px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .MuiFormControl-root {
            width: 60px !important;
          }

          .MuiInputBase-input {
            height: 45px;
            text-align: center;
            text-transform: uppercase;
            font: 24px/30px $open-sans;
          }

          .MuiOutlinedInput-notchedOutline {
            top: -8px;
          }
        }
      }

      .refundRequestStatusChange {
        &__reason {
          width: 100%;

          .MuiInputBase-root {
            height: auto;
            white-space: pre-wrap;
            text-align: justify;
            overflow-wrap: break-word;
            word-break: break-word;
            max-width: 100%;
          }

          .MuiFormHelperText-root {
            justify-content: space-between;
            margin-bottom: 20px;
          }

          &__helper {
            display: flex;
            justify-content: space-between;

            .message {
              flex: 1;
            }
          }
        }

        &__amount {
          width: 100%;

          .Mui-disabled {
            color: rgba(0, 0, 0, 0.38);

            .MuiTypography-root {
              color: rgba(0, 0, 0, 0.38);
            }
          }
        }

        &__actions {
          .loadingSpinner {
            margin-left: 10px;
            color: $white;
          }
        }

      }
    }
  }
}

@media all and (max-width: 500px) {
  .swal2-container {
    padding: 0 !important;

    @media all and (max-width: 319.98px) {
      top: -20px;
      min-width: 320px;
      position: sticky !important;
    }

    .swal2-popup {
      min-height: 100vh;
      padding: 10px !important;
      justify-content: end;

      .swal2-header {
        .swal2-close {
          display: flex !important;
          outline: none;
        }
      }

      .swal2-actions {
        .swal2-deny, .swal2-cancel {
          order: 2;
        }
      }

      .swal2-content {
        padding-top: 20px !important;
      }

      .buttonsHolder {
        flex-direction: column;
        align-items: inherit;

        h5 {
          order: 2;
          margin-right: 0 !important;
          margin-top: 20px;
        }
      }

      .MuiFormControl-root {
        width: 100% !important;
      }
    }
  }

}

.klasa {
  display: flex;
}

.MyAccount {
  max-width: 1400px;
  margin: 0 auto !important;
  padding: 0 6px;
  flex-grow: 1;

  @media all and (max-width: 499.98px) {
    padding: 0;
  }

  .title {
    a {
      color: $cyan-pastel;
    }
  }

  .d-flex {
    display: flex;
    align-items: center;
  }

  .MuiButton-root {
    color: $white;
    font-weight: bold;
    padding: 0 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .w-auto {
    width: auto;
  }

  .table-container {
    max-width: 100%;
    flex-basis: 100%;

    .MuiTableContainer-root {
      display: flex;
    }

    .MuiTablePagination-root {
      overflow: visible;
    }

    .MuiCheckbox-colorSecondary.Mui-checked {
      color: $red-pastel;
    }

    .MuiTableRow-root.Mui-selected {
      background-color: rgb(255, 235, 235);
    }
  }

  .menu {
    background-color: white;
    width: 100%;
    border-radius: 4px;
    padding-top: 10px;

    .primary {
      color: $red-pastel;
    }

    h2 {
      padding-left: 30px;
      margin-bottom: 20px;
    }

    .ButtonGroup {
      width: 100%;

      .MuiButtonGroup-grouped {
        min-height: 55px;
        justify-content: flex-start;
        text-decoration: none;
        @media (min-width: 960px) and (max-width: 1000px) {
          height: auto;
          min-height: 55px !important;
        }

        .MuiButton-root {
          height: 100%;
          width: 100%;
          justify-content: flex-start;
        }

        a {
          text-decoration: none;
          color: $dark-grey;
          width: 100%;
          height: 100%;
          padding-left: 30px;
        }

        .submenu {
          width: 100%;
          margin: 0 30px 12px 30px;        
          display: flex;
          flex-direction: column;
  
          &__item {
            padding-left: 64px;
            color: $dark-grey;
            text-transform: none;
            margin-bottom: 10px;
            font: normal 13px/13px $open-sans;
  
            &:first-of-type {
              margin-bottom: 16px;
            }

            &:hover, &--active {
              color: $red-pastel;
            }
          }
        }
      }

      .MuiButton-label {
        color: $dark-grey;
        text-align: start;
        padding-right: 3px;
        font: normal 14px/16px $open-sans;
        width: 100%;
        height: 100%;
        flex-wrap: wrap;

        @media all and (max-width: 1155px) {
          padding-right: 10px;
        }

        svg {
          width: 24px !important;
          height: 24px !important;
          margin-right: 30px;
          color: $dark-grey;
          stroke: inherit;
          @media all and (max-width: 1155px) {
            margin-right: 20px;
          }
        }
      }
    }

    .active {
      background-color: $white-snow;

      .MuiButton-label {
        color: $red-pastel;
        font-weight: bold;
        font-family: $open-sans;

        a {
          color: $red-pastel;
        }

        svg {
          stroke: currentColor;
          color: $red-pastel;
        }
      }

      .router-link {
        &--with-submenu {
          padding: 14px 0;
        }
      }
    }

    .MuiButtonGroup-groupedTextVertical:first-child {
      border-top: 1px solid $light-grey-bg;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .MuiButtonGroup-groupedTextVertical:not(:last-child) {
      border-bottom: 1px solid $light-grey-bg;
    }
  }


  .buttonHolder {
    margin-top: 15px;

    .MuiButton-root {
      width: 100%;
      color: $white;
      font-family: $open-sans;
    }
  }

  .holder {
    align-self: flex-start;

    h2 {
      margin-bottom: 10px;
      padding-left: 20px;
      padding-top: 10px;
    }

    .MuiButton-root {
      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.26);
      }
    }

    .personalInfo {
      padding: 20px 20px 0 20px;
      margin-bottom: 20px;

      .personalItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
        border-bottom: 1px solid $grey;
        margin-right: 30px;
        margin-bottom: 20px;

        @media all and (max-width: 959px) {
          margin-right: 0;
        }

        .green {
          color: $green !important;
          text-transform: none !important;
          cursor: auto !important;
        }

        .dark {
          color: $dark-grey !important;
          cursor: auto;
        }

        .w-50 {
          width: 50%;
        }

        .rightSide {
          color: $cyan-pastel;
          font-family: $open-sans;
          font-size: 13px;
          cursor: pointer;
          max-width: 50%;
          text-transform: uppercase;

          &:hover {
            color: #69C4C3;
          }

          h6 {
            text-transform: none !important;
          }

          @media all and (max-width: 499.98px) {
            .oauth {
              display: none;
            }
          }
        }

        .leftSide {
          h5 {
            color: $black;

            &.text-danger {
              color: $red;
            }
          }
        }

        .bankAccountNumber {
          margin-top: 10px;

          &:first-of-type {
            margin-top: 0px;
          }
        }

        &.oauthButtons {
          @media all and (min-width: 500px) {
            display: none;
          }

          .leftSide {
            width: 100%;
          }

          .oauth {
            margin-top: 0;
          }
        }
      }

      .dicdph {
        .w-50 {
          width: 50%;

          h5 {
            color: $black;
          }
        }
      }
    }

    .MuiFormControl-root {
      .MuiFormLabel-root {
        margin-top: -7px;
      }

      .MuiInputBase-root {
        color: $grey-mat-focus;
        height: 36px;
      }

      .MuiInputLabel-shrink {
        transform: translate(14px, 1px) scale(0.75);
        // @media all and (max-width: 499.98px) {
        //   transform: translate(1px, 15px) scale(0.75);
        // }
      }

      .MuiSelect-select:focus {
        background-color: transparent !important;
      }
    }

    // ADRESSES
    .klasa {
      display: flex;
    }

    .adresses {
      margin: 0;

      .items {
        @media all and (max-width: 750px) {
          max-width: 100%;
          flex-basis: 100%;
        }

        @media only screen and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 2),
          only screen and (max-device-width: 750px) and (-webkit-min-device-pixel-ratio: 3) {
          max-width: 100%;
          flex-basis: 100%;
        }

      }

      .noAdress {
        border: 2px dashed $grey;
        border-radius: 4px;
        padding: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $open-sans;

        .link {
          font-family: $open-sans;
        }
      }

      svg {
        width: 12px !important;
        height: 12px !important;
        font-weight: bold;
        color: $grey;
        cursor: pointer;
      }

      .default {
        border: 1px solid $grey;
      }

      .defaultBtn {
        background-color: $grey;
        color: $white;
        padding: 5px 10px;
        position: absolute;
        top: 0;
        right: 0;
      }

      @media all and (min-width: 500px) {

        .MuiGrid-item:first-of-type {
          padding-left: 0;
        }

        .MuiGrid-item:last-of-type {
          padding-right: 0;
        }
      }

      @media all and (max-width: 499.98px) {

        .MuiGrid-item {
          padding: 4px 0;
        }
      }
    }

    // END ADRESSES

    // CHANGE PASSWORD
    .passwordChange {
      padding: 45px 45px 30px 45px;
      margin: 0 auto;

      @media all and (max-width: 499.98px) {
        padding: 20px;
        margin-bottom: 20px;
      }

      .form {
        width: 100%;
      }

      .divider {
        border-bottom: 1px solid $grey-mat-unfocus;
        margin-bottom: 40px;
        margin-left: -50%;
        margin-right: -50%;
        @media all and (max-width: 1280px) {
          margin-left: -25%;
          margin-right: -25%;
        }
        @media all and (max-width: 960px) {
          margin-left: -10%;
          margin-right: -10%;
        }
        @media all and (max-width: 499.98px) {
          display: none;
        }
      }


      .passwordRules {
        color: $grey-mat-focus;
        font-family: $open-sans;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        margin-top: 2px;
        margin-bottom: 0;
      }

      .validation {
        font-family: $open-sans;
        margin-top: 2px;
        margin-bottom: 10px;
        text-align: left;
        color: $red;
        font-size: 12px;
        line-height: 24px;
        min-height: 24px;
        letter-spacing: 0.11px;
      }

      .backendInfoHolder {
        padding: 30px;
        margin-bottom: 20px;
        font: normal 14px/20px $open-sans;
        letter-spacing: 0.47px;
        color: $dark-grey;
        border: 1px solid $red;
        display: flex;
        justify-content: center;
        border-radius: 4px;

        .iconHolder {
          width: auto;

          .icon {
            width: 40px;
            margin-right: 30px;
          }
        }

        .title {
          font: normal 600 16px/20px $open-sans;
        }

        @media all and (max-width: 499.98px) {
          background-color: $white;
        }
      }

      .error {
        border: 1px solid $red;

        .title {
          color: $red;
        }
      }

      .success {
        border: 1px solid $green;

        .title {
          color: $green;
        }
      }
    }

    // END CHANGE PASSWORD

    .myOffers {
      position: relative;

      .no-results {
        margin: 20px 0 0 32px;
        font: normal 14px/16px $open-sans;
      }

      .border {
        border: 2px dashed #C3C3C3;
      }

      .offerItemImage {
        padding: 15px 0;
        max-width: 60px;
        max-height: 60px;
        object-fit: contain;
      }

      .addTopOptionButton {
        text-transform: uppercase;
        color: $green;
        text-decoration: none;
        font-family: "OpenSans", sans-serif;
        cursor: pointer;

        &:hover {
          opacity: 0.6;
        }
      }

      .lines-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &__line {
          width: 100%;
          margin-bottom: 10px;

          &--half {
            width: 50%;
            margin-left: 10px;
          }

          @media all and (min-width: 600px) and (max-width: 799.98px) {
            width: 90%;
          }

          @media all and (min-width: 500px) and (max-width: 599.98px) {
            width: 80%;
          }
        }

        &--half {
          display: flex;
          width: 50%;
        }
      }

      .rectangle {
        width: 100px;
        height: 100px;
        margin: 0 20px 10px 38px;
      }

      .tabs {
        width: 100%;
        border-radius: 4px;
        margin-bottom: 1rem;

        .MuiTab-root {
          max-width: unset;
        }
      }

      .arrow-down {
        width: 0; 
        height: 0;
        top: 60px;
        position: absolute;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid $red-pastel;

        &.left {
          left: calc(25% - 8px);
        }

        &.right {
          right: calc(25% - 8px);
        }
      }

      .offerItem {
        margin-bottom: 10px !important;
        padding: 40px 30px 20px 30px;
        position: relative;
        @media all and (max-width: 959.98px) {
          padding: 40px 10px 20px 10px;
        }

        .customCheckbox {
          width: 22px;
          height: 22px;
          display: contents;
          @media all and (max-width: 959.98px) {
            width: 16px;
            height: 16px;
          }

          .MuiIconButton-root {
            padding: 0 9px 9px 0;
          }
        }

        .offerItemImage {
          width: calc(90% - 33PX);
          height: auto;
          @media all and (max-width: 959.98px) {
            width: 90%;
            height: auto;
            margin-top: 10px;
          }
        }

        .price {
          font-family: $roboto;
          font-weight: bold;
          font-size: 26px;
          color: $dark-grey;

          .coins {
            font-size: 16px;
          }
        }

        .concept {
          position: absolute;
          top: 0;
          right: 0;
          background-color: $grey;
          color: $white;
          padding: 5px 20px;
          text-align: center;
          border-radius: 0 4px 0 0;
        }

        .offerItemButton {
          width: 100%;
          height: 30px;
          margin-bottom: 20px;
          font-size: 14px;
          @media all and (max-width: 959.98px) {
            margin-top: 15px;
          }
        }

        .rightSideHolder {
          @media all and (max-width: 959.98px) {
            justify-content: flex-start;
            margin-top: 20px;
            .editLink {
              margin-right: 30px;
            }
          }
        }

        .centerHolder {
          padding-left: 5px;
        }

        .leftSideHolder {
          @media all and (max-width: 959.98px) {
            flex-direction: column;
            align-items: flex-start;
          }
        }

        .MuiLink-root {
          font-size: 13px;

          &.text-danger {
            color: $red !important;
          }

          @media all and (max-width: 959.98px) {
            text-align: right;
          }
        }

        h5 {
          color: $dark-grey;
          margin-bottom: 5px;
          min-height: 16px;

          &.freeShipping {
            color: $green;
            margin-bottom: 10px;
          }

          &.title {
            @media all and (max-width: 959.98px) {
              margin-bottom: 15px;
            }
          }
        }

        h4:not(.MuiLink-root) {
          color: $dark-grey;

          &.description {
            color: $grey-price;
          }

          &.text-danger {
            color: $red;
            cursor: pointer;
          }
        }

        .finished {
          opacity: 0.5;
        }
      }

      .filters {
        padding: 15px 0 15px 16px;

        @media all and (max-width: 959.98px) {
          padding: 15px 8px 0 15px;
        }

        .checkboxes.MuiGrid-container {
          order: 3;
          margin-right: 8px;

          @media all and (max-width: 499.98px) {
            margin-right: 0;
          }

          @media all and (max-width: 349.98px) {
            display: none;
          }
        }

        .customCheckbox {
          margin-left: 0;
          margin-right: 0;
          text-transform: lowercase;

          @media all and (min-width: 500px) and (max-width: 599.98px) {
            width: calc(50% - 16px);
          }

          .MuiButtonBase-root {
            padding-left: 0;
          }
        }

        .filterHolder {
          order: 1;

          @media all and (min-width: 960px) {
            .customSelect {
              margin-right: 15px;
            }
          }

          @media all and (min-width: 500px) and (max-width: 959.98px) {
            .customSelect {
              margin-right: 15px;
              width: calc(100% - 15px);
            }
          }

          @media all and (min-width: 350px) and (max-width: 499.98px) {
            justify-content: flex-start;
            flex-direction: row;

            .customCheckbox {
              width: 50%;
            }
          }
        }

        .MuiTypography-body1 {
          color: $dark-grey;
          font-size: 14px;
          letter-spacing: 0.47px;
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: $grey-mat-unfocus !important;
        }

        .MuiInputLabel-shrink {
          @media all and (max-width: 499.98px) {
            transform: translate(14px, 1px) scale(0.75);
          }
        }

        .makeStyles-icon-26 {
          border: 1px solid $grey-mat-unfocus;
        }

        .makeStyles-checkedIcon-27 {
          border: 2px solid $red-pastel;
          background-color: $white;
        }

        .MuiSvgIcon-root {
          width: 1.5em;
          height: 1.5em;
          pointer-events: none;
          position: absolute;
          right: 0;
          color: #767676;
        }

        .customSelect {
          @media all and (max-width: 959.98px) {
            margin-bottom: 15px;
          }
        }

        .MuiInputBase-root {
          color: $dark-grey !important;
        }
      }

      .options {
        padding: 10px 30px;
        @media all and (max-width: 959.98px) {
          padding: 20px 10px;
        }
        @media all and (max-width: 499.98px) {
          align-items: center;
          text-align: center;
          margin: 0;
          .MuiFormControlLabel-root {
            margin-right: 0;
            margin-bottom: 15px;
          }
        }

        .customCheckbox {
          .MuiTypography-body1 {
            font-size: 14px;
            @media all and (max-width: 499.98px) {
              font-size: 13px;
            }
          }

          .MuiButtonBase-root {
            padding: 0 9px;
          }
        }

        .pastelRed {
          color: $red-pastel !important;
          @media all and (max-width: 600px) {
            text-align: center;
          }
          @media all and (max-width: 499.98px) {
            margin-bottom: 15px;
            font-size: 13px;
          }
        }

        .lightGrey {
          color: $grey-mat-unfocus !important;
          @media all and (max-width: 600px) {
            text-align: right;
          }
          @media all and (max-width: 499.98px) {
            text-align: center;
            font-size: 13px;
          }
        }
      }

      .pagination {
        padding: 15px 30px 15px 20px;
        @media all and (max-width: 818px) {
          padding: 20px 10px;
          justify-content: center;
          flex-direction: column;
        }
        @media (min-width: 960px) and (max-width: 1038px) {
          justify-content: center;
          flex-direction: column;
        }

        h4 {
          color: $dark-grey;
        }

        .customPagination {
          @media all and (max-width: 818px) {
            margin-top: 15px;
          }
          @media (min-width: 960px) and (max-width: 1038px) {
            margin-top: 15px;
          }

          h4 {
            color: $red-pastel !important;
            text-transform: uppercase;
          }

          .Mui-selected {
            background-color: transparent;
            color: $red-pastel;
          }

          .MuiPaginationItem-icon {
            margin: 0;
            font-size: 1.8rem;
            color: $grey-mat-focus;
          }

          .MuiPagination-ul > li:last-child {
            padding-left: 30px;
            @media (min-width: 1100px) and (max-width: 1118px) {
              padding-left: 5px;
            }
            @media (min-width: 1038px) and (max-width: 1099px) {
              padding-left: 0;
            }
            @media all and (max-width: 1000px) {
              padding-left: 15px;
            }
            @media all and (max-width: 589px) {
              padding-left: 0;
            }
          }

          .MuiPagination-ul > li:first-child {
            padding-right: 30px;
            @media (min-width: 1100px) and (max-width: 1118px) {
              padding-right: 5px;
            }
            @media (min-width: 1038px) and (max-width: 1099px) {
              padding-right: 0;
            }
            @media all and (max-width: 1000px) {
              padding-right: 15px;
            }
            @media all and (max-width: 589px) {
              padding-right: 0;
            }
          }
        }
      }

      .toppedTo {
        white-space: nowrap;
        padding: 12px 4px;
      }
    }
  }
}

.background {
  background-color: $white;
  border-radius: 4px;
}

.MyAccount.MyOffers {
  .myOffersLoading {
    position: unset;
    min-height: 440px;

    @media all and (max-width: 960px) {
      height: unset;
    }
  }
}


h4 {
  margin-bottom: 2px;
}

h5, h6 {
  color: $black;
}

.link {
  color: $cyan-pastel;
  text-decoration: none;
  cursor: pointer;
  font-weight: normal;

  &:hover {
    color: #69C4C3;
  }

}

.bold {
  font-weight: bold;
}

.p-20 {
  padding: 20px;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-20 {
  padding-left: 20px;
}

.pb-10 {
  padding-bottom: 10px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.pr-10 {
  padding-right: 10px;
}

.dark {
  color: $dark-grey;
}




