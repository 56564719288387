@import "../../../scss/variables";
.desktop {
    @media all and (max-width: 499.98px) {
        display: none;
        &.MuiFormGroup-root {
            display: none;
        }
    }
}

.mobile {
    @media all and (min-width: 500px) {
        display: none;
        &.MuiTabs-root {
            display: none;
        }
    }
}

.month {
    &.MuiFormHelperText-root {
        width: calc(100% - 40px);
    }
}

.double-input {
    &.MuiFormHelperText-root {
        width: 50%;
        @media all and (max-width: 499.98px) {
            width: 100%;
            margin-bottom: 11px;
        }
    }
    &--right {
        &.MuiFormHelperText-root {
            margin-left: 15px;
            width: calc(50% - 15px);
            @media all and (max-width: 499.98px) {
                margin-left: 0px;
            }
        }
    }
}

.account-type {
    @media all and (max-width: 499.98px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    &.MuiGrid-item {
        margin-bottom: 10px;
    }
}

.tabs {
    .MuiTab-root {
        width: 50%;
        height: 60px;
        background-color: $white;
        &:last-of-type {
            border-left: 1px solid $grey;
        }
    }
}

.register {
    @media all and (max-width: 499.98px) {
        padding-bottom: 10px;
        background-color: $white;
        &__title {
            background-color: $white;
        }
    }
    &__form {
        width: 100%;
    }
    &__label {
        margin: 5px 0 10px 20px;
        color: $dark-grey;
        font: 600 28px/32px $open-sans;

        @media all and (max-width: 499.98px) {
            margin: 10px 0 0 20px;
        }
    }
    &__card {
        padding: 20px;
        border-radius: 4px;
        background-color: $white;
        &.MuiGrid-item {
            margin-bottom: 30px;
        }
        @media all and (max-width: 499.98px) {
            &.MuiGrid-item {
                margin-bottom: 0px;
            }
        }
    }
    &__type-radio {
        &.MuiFormGroup-root {
            flex-direction: row;
        }
        .MuiFormControlLabel-label {
            color: $dark-grey;
            font: bold 14px/16px $open-sans;
        }
    }
    &__birth-date {
        margin-bottom: 16px;
        color: $grey-mat-focus;
        text-transform: uppercase;
        letter-spacing: 0.4px;
        font: normal 12px/16px $open-sans;
    }
    &__type-description {
        margin-right: 16px;
        color: $dark-grey;
        font: normal 15px/17px $open-sans;
    }
    &__input-container {
        margin-bottom: 11px;
        &.MuiGrid-item {
            margin-bottom: 11px;
        }
        &.MuiGrid-container {
            margin-bottom: 11px;
        }
        &.hidden, .hidden {
            height: 0;
            visibility: hidden;
            margin-bottom: 0;
        }
        .marketing .MuiTypography-root {
            font-size: 0.875rem;
        }
        .taxpayer {
            @media all and (max-width: 499.98px) {
                margin-bottom: 20px;
            }
        }
    }
    &__agreement {
        font: normal 14px/16px $open-sans;
    }
    &__input-description {
        width: 100%;
        margin-top: 4px;
        color: $grey-mat-focus;
        font: normal 12px/16px $open-sans;
        letter-spacing: 0.4px;
    }
    &__create-account {
        &.MuiButton-text {
            padding: 10px 25px;
        }
        &.MuiButton-root {
            transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
        &.MuiButtonBase-root {
            min-height: 42px;
            color: $white;
            background-color: $red-pastel;
            font: bold 14px/16px $open-sans;
            &:hover {
                opacity: 0.7;
                background-color: $red-pastel;
            }
        }

        @media all and (max-width: 499.98px) {
            width: 100%;
        }
    }
    &__login-account {
        &.MuiButton-text {
            padding: 10px 25px;
        }
        &.MuiButton-root {
            transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
        &.MuiButtonBase-root {
            color: $black;
            margin-top: 25px;
            font: bold 14px/16px $open-sans;
            border: 1px solid $black;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
            .login-button {
                display: flex;
                align-items: center;
                .icon {
                    margin-right: 10px;
                }
            }

            @media all and (min-width: 500px) {
                display: none;
            }
        }
    }
    .MuiTextField-root,
    .MuiFormControl-root {
        .MuiInputLabel-outlined {
            transform: translate(14px, 13px) scale(1);
            &.MuiInputLabel-shrink {
                transform: translate(14px, -6px) scale(0.75);
            }
        }
    }
    .success {
        background-color: $white;
        padding: 15px 30px 64px 30px;
        border-radius: 4px;

        &__image {
            width: 80%;
        }

        .customLink {
            color: $cyan-pastel;
            text-decoration: none;
            &:hover {
                color: $cyan-focus;
            }
        }
        
        h5 {
            margin-bottom: 20px;
            letter-spacing: 0.47px;
            
            @media all and (max-width: 499.98px) {
                text-align: center;
            }
        }

        h6 {
            letter-spacing: 0.4px;
            text-align: center;
        }
    }

    @media all and (max-width: 499.98px) {
        .success {
            background-color: transparent !important;

            .infoHolder {
                align-items: center;
            }

            &__image {
                width: 100%;
                max-width: 300px;
                padding-bottom: 20px;
            }
        }
    }

    &__password-description {
        @media all and (max-width: 499.98px) {
            margin-bottom: 20px;
        }
    }
}

.zip-code {
    &.MuiGrid-item {
        @media all and (max-width: 959.98px) {
            margin-bottom: 20px;
        }
        @media all and (min-width: 960px) and (max-width: 1279.98px) {
            margin-right: 20px;
            max-width: calc(25% - 20px);
            flex-basis: calc(25% - 20px);
        }
        @media all and (min-width: 1280px) {
            margin-right: 20px;
            max-width: calc(33.33% - 20px);
            flex-basis: calc(33.33% - 20px);
        }
    }
}

.input {
    
    .MuiFormControl-root, .register__input-description, .text-danger {
        width: 100%;
    }

    &--center {
        .MuiFormControl-root {
            width: calc(100% - 40px);
        }        
    }

    &--left, &--right {
        .MuiFormControl-root, .register__input-description, .text-danger {
            width: calc(100% - 15px);

            @media all and (max-width: 499.98px) {
                width: 100%;
            }
        }
    }
}
