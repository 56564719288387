@import "src/scss/variables";

.productDetailAutomoto {
  padding-bottom: 20px !important;

  .imagesHolder {
    margin-top: 0 !important;
  }

  .breadcrumbs {
    //margin-bottom: 30px !important;
  }

  .returnToResults {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $cyan-pastel;
    font: normal 14px/16px $open-sans;
    margin-bottom: 30px;

    &:hover {
      color: $medium-aquamarine;
    }
  }

  @media all and (min-width: 800px) {
    .imagesHolder {
      max-width: 40% !important;
      flex-basis: 40% !important;
    }
    .infoHolder {
      max-width: 60% !important;
      flex-basis: 60% !important;
    }
  }

  .imagesHolder {
    @media all and (max-width: 799.98px) {
      padding-right: 0 !important;
    }
    @media all and (max-width: 499.98px) {
      top: 0;
    }
  }


  .auto {
    width: auto;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mobileLG2 {
    max-width: 80px;
    flex-basis: 80px;
  }

  .mobileLG10 {
    max-width: calc(100% - 80px);
    flex-basis: calc(100% - 80px);
  }


  @media all and (max-width: 499.98px) {
    h5 {
      font-size: 12px;
      line-height: 18px;
    }
    h4 {
      font-size: 12px;
      line-height: 18px;
    }
    h6 {
      font-size: 11px;
      line-height: 16px;
    }
  }

  .title {
    @media all and (max-width: 499.98px) {
      font-size: 16px;
      text-transform: unset;
    }
  }

  .seller {
    @media (min-width: 800px) and (max-width: 1100px) {
      max-width: 100%;
      flex-basis: 100%;
    }
    @media all and (max-width: 700px) {
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  .sellerInfo {
    line-height: 25px;

    .ratingHolder {
      span:first-child {
        display: flex !important;
      }
    }

    .ratingHolder {
      @media (min-width: 960px) and (max-width: 1360px) {
        display: none;
      }
      @media all and (max-width: 770px) {
        display: none;
      }
    }
  }

  .optionsHolder {
    @media (min-width: 800px) and (max-width: 1100px) {
      max-width: 100%;
      flex-basis: 100%;
      justify-content: flex-start;
      margin-top: 5px;
      .productDetailAutomoto__optionsBtn {
        margin-left: 0 !important;
        margin-right: 10px;
      }
    }

    @media all and (max-width: 700px) {
      max-width: 100%;
      flex-basis: 100%;
      justify-content: flex-start;
      margin-top: 5px;
      .productDetailAutomoto__optionsBtn {
        margin-left: 0 !important;
        margin-right: 10px;
      }
    }

    &__watch {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font: normal 14px/19px $open-sans;
      color: $blue-pastel;

      svg {
        font-size: 24px;
      }

      .hoverVisible {
        display: none;
      }

      .watchText {
        margin-top: 4px;
        margin-right: 4px;
      }

      &:hover, &.alreadyWatching {
        .hoverVisible {
          display: block;
        }

        .hoverHidden {
          display: none;
        }
      }
    }

    &__active {
      color: $white;
      background-color: $blue-pastel;

      svg {
        color: $white;
      }
    }
  }

  &__optionsBtn {
    font: normal 14px/19px $open-sans;
    width: 130px;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    margin-left: 10px !important;
    cursor: pointer;
    @media all and (max-width: 1200px) {
      width: 110px;
    }
  }

  &__contained {
    background: $blue-pastel !important;
    color: $white !important;
    padding: 2px;
    border: 1px solid $blue-pastel;
  }

  &__outlined {
    border: 1px solid $blue-pastel;
    color: $blue-pastel;
    padding: 2px;

    svg {
      color: $blue-pastel;
    }
  }


  .metroIcon {
    filter: invert(53%) sepia(45%) saturate(687%) hue-rotate(171deg) brightness(108%) contrast(102%);
  }

  .newOffer {
    color: $blue-pastel !important;
  }

  .basicInfo {
    @media all and (max-width: 499.98px) {
      .mb-15 {
        margin-bottom: 5px;
      }
      .price {
        font-size: 24px !important;
      }
    }

    .price {
      font-size: 28px;
    }
  }

  .priceHolder {
    margin-bottom: 10px;

    .priceOptionsContent {
      margin-left: 20px
    }

    .priceAddInfo {
      display: flex;
      align-items: center;
      margin-right: 10px;

      .checkIcon {
        background-color: $green-pastel-dark;
        border-radius: 50%;
        color: $white;
        width: 13px;
        height: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;

        &.backgroundUnset {
          background-color: unset;
        }

        img {
          width: 16px;
        }

        svg {
          width: 8px;
          height: 8px;
        }
      }
    }
  }

  .detailInfo {
    margin-top: 10px;

    .detailInfoItem {
      margin-right: 25px;
      @media all and (max-width: 380px) {
        margin-right: 15px;
      }

      &:last-child {
        margin-right: 0;
      }

      h5 {
        font-size: 14px;

        @media all and (max-width: 500px) {
          font-size: 13px;
        }

        @media all and (max-width: 400px) {
          font-size: 12px;
        }

        @media all and (max-width: 350px) {
          font-size: 11px;
        }
      }

      .iconHolder {
        height: 30px;
        width: auto !important;
        min-width: auto !important;
        max-width: none;

        img {
          height: 24px;
          width: auto;
        }
      }
    }
  }

  .detailLabels {
    min-height: 25px;
    align-items: center;

    .labelsAutomoto {
      font-size: 14px;
      color: $white;
      font-family: $open-sans;
      width: auto;
      height: 100%;
      border-radius: 24px;
      padding: 2px 10px;
      margin-right: 5px;
      margin-bottom: 5px;
      @media all and (max-width: 350px) {
        &:last-child {
          display: none;
        }
      }

    }
  }

  .consumption, .otherTechnicalSpec {
    margin: 10px 0;

    &__holder {
      @media all and (max-width: 360px) {
        justify-content: flex-start !important;
      }
    }

    h4 {
      line-break: anywhere;

      &.without-sup {
        height: 22px;
        display: flex;
        align-items: flex-end;
      }
    }
  }

  &__labels {
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 24px;
    padding: 2px 10px;
    font-size: 14px;
    font-family: $open-sans;
  }

  .equipmentHolder {
    .equipment {
      margin-top: 10px;
    }

    h5 {
      margin-top: 10px;
      @media all and (max-width: 499.98px) {
        font-size: 13px;
      }
    }
  }

  .buyButtons {
    @media all and (max-width: 499.98px) {
      display: flex !important;
      flex-direction: column;
      margin-top: 25px !important;
      .addToCartButton, .viewNumberButton {
        width: 100% !important;
      }
    }
  }

  .secureAndQuestions {
    margin-left: auto;
    margin-right: auto;
  }

  .mobileButtonsHolder {

    @media all and (max-width: 360px) {
      .favouriteButton, .shareButton, .compareButton {
        min-width: 40px !important;
        width: 40px !important;
        height: 40px !important;
      }
      .callButton, .messageButton {
        min-width: 46px !important;
        width: 46px !important;
        height: 46px !important;
      }
    }
  }

  .secureAndQuestions {
    @media all and (max-width: 499.98px) {
      h4, .link {
        font-size: 12px;
      }
    }
  }
}