@import "src/scss/variables";

.item-offer-data {
  width: calc(100% - 30px);
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  position: relative;

  @media (max-width: $media-xs) {
    width: 100%;
    border-bottom: 1px solid $grey-mat-unfocus;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .text {
    font-size: 13px;
    color: $black;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white;
    opacity: 0.6;
    z-index: 1;
  }

  &__photo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: 80px;
    width: 80px;
    margin-right: 20px;

    @media (max-width: $media-xs) {
      margin-right: 10px;
    }

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .offer-info {
    width: calc(100% - 100px);

    &__item {
      margin-bottom: 2px;
      font-weight: bold;
      text-decoration: none;
    }

    &__price {
      color: $red-pastel;
    }

    &__status {
      font-weight: normal;
    }

    .maxCount {
      font-size: 13px;
    }
  }

  .unavailable-offer {
    display: flex;
    flex-flow: row wrap;

    &__message {
      flex: 1 1 auto;
      margin-right: 10px;
      margin-top: 10px;

      font-size: 12px;
      font-family: $open-sans;
      color: $red-pastel;

      z-index: 2;
    }

    &__delete-button {
      flex: 1 1 auto;
      margin-top: 10px;

      font-family: $open-sans;
      font-size: 12px;
      text-transform: uppercase;
      z-index: 2;

      @media all and  (max-width: 499.98px){
        display: none;
      }
    }
  }

  .cart-count {
    display: flex;
    min-height: 25px;

    &__title {
      display: flex;
      align-items: center;
      margin-right: 5px;
      font-weight: bold;
      height: 25px;

      @media (max-width: $media-xs) {
        display: none;
      }

      &--open {
        margin-right: 10px;
      }
    }

    &__value {
      display: none;
      align-items: center;
      flex-wrap: wrap;

      &--show {
        display: flex;

        @media (max-width: $media-xs) {
          display: none;
        }
      }

      &--opened {
        @media (max-width: $media-xs) {
          display: flex;
          margin-top: 5px;
        }
      }
    }

    &__number {
      margin-right: 10px;
    }

    &__edit {
      margin-right: 5px;
      font-size: 12px;
      text-transform: uppercase;

      @media (max-width: $media-xs) {
        display: none;
      }
    }

    &__delete {
      @media (max-width: $media-xs) {
        display: block;
        margin-left: 15px;
        z-index: 2;
      }
    }

    &__update {
      display: flex;
      margin-left: 10px;

      @media all and (max-width: 750px) {
        width: 100%;
        margin-left: 0;
      }

      @media all and (min-width: 500px) and (max-width: 650px) {
        flex-wrap: wrap;

        .cart-count__edit {
          width: 100%;
          margin-top: 5px;

          &:not(.link) {
            display: none;
          }
        }
      }

      @media (max-width: $media-xs) {
        width: auto;
      }
    }
  }
}
