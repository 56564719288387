@import "../../../scss/variables";

$headerHeight: 144px;
$headerHeightMobile: 128px;


.d-none {
  display: none !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.popper-overlay {
  top: 0;
  width: 100%;
  height: var(--popperOverlay);
  z-index: 1000;
  position: absolute;
  background: rgba(0, 0, 0, 0.45);
}

.searchBar-popper {
  border: 0 !important;
  z-index: 1301;

  &.drawerActive {
    @media all and (max-width: 499.98px) {
      position: fixed !important;
    }
  }

  @media all and (max-width: 499.98px) {
    padding-top: 5px;
    top: -19px !important;
    width: 100% !important;
    transform: translate3d(0px, 122px, 0px) !important;
  }

  .MuiAutocomplete-option {
    height: 42px;
    padding: 8px 10px;
  }
}

.headerLink, .loginLink, .customLink, .headerLink.MuiLink-root {
  color: $black;
  text-transform: none;
  text-decoration: none;

  &:hover {
    color: $black;
  }
}

.header {
  padding: 0 0 8px 0;
  position: relative;
  background-color: $white;
  z-index: 1001;

  @media all and (max-width: 319.98px) {
    min-width: 320px;
    overflow: visible;
  }

  a {
    text-decoration: none;
  }

  &__sticky {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  &__content_overlay {
    top: $headerHeight;
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    background: rgba(0, 0, 0, 0.45);

    @media all and (max-width: 499.98px) {
      top: $headerHeight;
    }
  }
}

.hamburger-menu {
  top: 0;
  right: 0;
  z-index: 1000;
  width: 243px;
  height: 100vh;
  position: relative;
  overflow-X: hidden;
  overflow-Y: auto;
  background-color: $white;

  .MuiListItem-gutters {
    padding-left: 38px;
    padding-right: 24px;
  }

  &__header.MuiListItem-root {
    display: flex;
    justify-content: flex-end;
  }

  &__account.MuiListItem-root {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    height: 60px;
    color: $white;
    font: normal 16px/20px $open-sans;
    background-color: $red-pastel;

    &.Mui-disabled {
      opacity: 1;
    }

    &.login {
      padding-left: 20px;

      .MuiListItemText-root {
        flex: unset;
      }

      .icon {
        margin-left: 10px;
      }
    }
  }

  &__settings.MuiListItem-root {
    margin-bottom: 10px;
    height: 60px;
    padding-left: 33px;
    border-left: 5px solid $red-pastel;

    &.Mui-disabled {
      opacity: 1;
    }

    .MuiTypography-root {
      font: bold 18px/24px $open-sans;
    }
  }

  &__close {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__categories {
    font: bold 18px/24px $open-sans;
  }

  &__menu-icon {
    margin-right: 10px;

    &.plus {
      padding-left: 2px;
      padding-right: 2px;
    }

    &.logo {
      margin-right: 20px;
    }
  }

  .settings, .main-menu {
    position: absolute;
    width: 100%;
    transition: transform 250ms ease-in-out;

    &--visible {
      visibility: visible;
      will-change: transform;
      transform: translateX(0);
    }

    &.MuiList-padding {
      padding-top: 0;
    }

    a:not(.oauth__link) {
      color: $black;
      text-decoration: none !important;
    }
  }

  .settings {

    &--hidden {
      visibility: hidden;
      will-change: transform;
      transform: translateX(243px);
    }

    &__back-button {
      padding-left: 12px;
      padding-right: 12px;

      .MuiListItemText-root {
        margin-left: 2px;
      }
    }
    
    .MuiCollapse-wrapper {
      .MuiButtonBase-root {
        margin-left: 10px;
      }
    }
  }

  .main-menu {

    &--hidden {
      visibility: hidden;
      will-change: transform;
      transform: translateX(-243px);
    }

    .MuiDivider-root {
      margin: 5px 0;
    }
  }
}

.header, .hamburger-menu {
  .motor-vehicles,
  .spare-parts,
  .marketplace,
  .properties,
  .otherOptions {
    color: $dark-grey;
    display: inline-flex;
    align-items: center;
    font: normal 14px/16px $open-sans;
  
    &:before {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 6px;
      margin-right: 8px;
    }
  
    &.MuiBox-root {
      margin-left: 10px;
    }
  }
  
  .motor-vehicles {
    cursor: pointer;

    &:before {
      background-color: $blue-pastel;
    }
  }
  
  .spare-parts {
    cursor: pointer;

    &:before {
      background-color: $purple-pastel;
    }
  }
  
  .marketplace {
    cursor: pointer;

    &:before {
      background-color: $yellow-pastel;
    }
  }
  
  .properties {
    cursor: pointer;
    
    &:before {
      background-color: $green-pastel;
    }
  }

  .noCategory {
    &::before {
      background-color: $red-pastel;
    }
  }
}

.company-logo {
  width: 180px;

  @media all and (min-width: 350px) and (max-width: 767.98px) {
    width: 125px;
    height: 34px;
  }

  @media all and (max-width: 349.98px) {
    width: 120px;
    height: 24px;
  }
}

.logo-container {
  @media all and (min-width: 1280px) {
    &.MuiGrid-container {
      display: none;
    }
  @media all and (max-width: 499.98px) {
    padding: 5px 12px 5px 12px;
  }
  }

  &__link {
    max-height: 24px;
  }

  &__drawerSearchBar {
    min-height: 44px;
  }
}

.m-xs-0 {
  @media all and (max-width: 499.98px) {
    margin: 0 !important;
  }
}

.d-md-none {
  @media all and (max-width: 1279.98px) {
    &.MuiGrid-item {
      display: none;
    }
  }
}

.d-sm-none {
  @media all and (max-width: 767.98px) {
    display: none !important;
  }
}

.d-xs-none {
  @media all and (max-width: 499.98px) {
    display: none !important;
  }
}

.d-sm-block {
  display: none;

  @media all and (max-width: 767.98px) {
    display: block;
  }
}

.d-xs-block {
  display: none;

  @media all and (max-width: 499.98px) {
    display: block;
  }

  &.MuiButtonBase-root {
    display: none;

    @media all and (max-width: 499.98px) {
      display: block;
    }
  }
}

.login-button.MuiButton-root {
  border: 1px solid $dark-grey;
  font: bold 12px/17px $open-sans;
}

.hamburger-button.MuiButton-root {
  display: flex;
  min-width: unset;

  @media all and (min-width: 320px) and (max-width: 349.98px) {
    padding: 6px;
  }
}

.search-area-container {
  @media all and (max-width: 1279.98px) {
    &.MuiGrid-container {
      display: none;
    }
  }
}

.categories {
  font: normal 14px/16px $open-sans;

  &:first-child:nth-last-child(1) {
    margin-left: 270px;
  }
}

.MuiPopover-root {
  background: rgba(0, 0, 0, 0.45);
}

.county {
  max-width: 160px;

  &.MuiButtonBase-root {
    margin-right: 5px;
  }

  &__container {
    margin-left: 8px;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
  }

  &__searcharea {
    color: $black;
    font: normal 12px/16px $open-sans;
  }

  &__county-name {
    display: inline-flex;
    color: $black;
    font: normal 14px/16px $open-sans;
  }
}

.account-buttons {
  &.MuiGrid-container {
    width: auto;
  }
}

.account-button {
  max-width: 215px;

  .MuiButton-label {
    text-align: left;
    display: flex;

    .MuiSvgIcon-root {
      margin-top: 16px;
    }

    .account-container {
      display: flex;
      flex-direction: column;

      &__login {
        color: $light-grey;
        font: normal 12px/16px $open-sans;
      }

      &__account {
        color: $black;
        font: 600 14px/16px $open-sans;

        .MuiBadge-badge {
          display: none;
          position: relative;
          height: 18px;
          min-width: 18px;
          margin-left: 8px;
          top: unset;
          right: unset;
          transform: none;
          transform-origin: unset;
        }

        @media all and (min-width: 1280px) {
          min-width: $headerHeight;
        }

        @media all and (max-width: 1279.98px) {
          min-width: 83px;
        }
      }
    }
  }
}

.dropdown-list {
  &.dropdown__content {
    padding: 0px;

    .MuiListItemIcon-root {
      min-width: 40px;
    }

    .MuiTypography-root {
      font-family: $open-sans;
    }
  }

  &--lang.dropdown__content {
    margin-left: -2px;
  }

  &--currency.dropdown__content {
    margin-left: -24px;

    .MuiListItem-root {
      width: 95px;
    }
  }
}

.account-dropdown {

  .MuiTypography-body1 {
    color: $black;
    font: normal 16px/18px $open-sans;
  }

  svg {
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
    color: $dark-grey;
  }

  

  &.dropdown__content {
    min-width: 255px;
    padding: 0px;
    border-radius: 2px;
    border: 1px solid $grey-mat-unfocus;

    @media all and (min-width: 768px) and (max-width: 1279.98px) {
      margin-left: -56px;
    }

    @media all and (max-width: 767.98px) {
      margin-top: 10px;
      margin-left: -188px;
    }

    .NoLoginDropdown {
      max-width: 225px;
      padding: 10px 15px 30px 15px;

      h6 {
        text-align: center;
        line-height: 17px;
        letter-spacing: 0.4px;
        margin-bottom: 15px;
        padding: 0 10px;
        color: $grey-mat-focus;
      }

      .buttonLogin {
        margin-bottom: 20px;

        .loginLink {
          color: $white !important;
          font-weight: bold;
        }
      }

      .lineHolder {
        margin-bottom: 10px;

        .border {
          border-bottom: 1px solid $grey-mat-focus;
          padding: 0;
          align-items: center;
        }

        h6 {
          margin-bottom: 0;
          font-size: 13px;
          padding: 0;
        }
      }
    }
  }

  &:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: -10px;
    right: 65px;
    border-style: solid;
    display: inline-block;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent $grey-mat-unfocus transparent;

    @media all and (min-width: 768px) and (max-width: 1279.98px) {
      right: 9px;
    }

    @media all and (max-width: 767.98px) {
      right: 28px;
    }
  }

  &:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: -8px;
    right: 67.5px;
    border-style: solid;
    display: inline-block;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent $white transparent;

    @media all and (min-width: 768px) and (max-width: 1279.98px) {
      right: 11.5px;
    }

    @media all and (max-width: 767.98px) {
      right: 29.5px;
    }
  }

  .MuiDivider-root {
    max-width: 90%;
    margin: auto;
  }

  .MuiListItemIcon-root {
    min-width: 30px;
    justify-content: center;
  }

  &__icon {
    margin-right: 9px;
    max-width: 20px;

    &.list {
      margin-right: 11px;
    }
  }

  &__my-listings {
    &.MuiButtonBase-root {
      display: flex;
      flex-direction: column;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &__options {
    width: 90%;
  }
}

.listings-container {

  &__label {
    width: 100%;
    display: flex;
    align-items: center;
    font: normal 16px/16px $open-sans;
  }

  &__badge {
    .MuiBadge-badge {
      position: relative;
      height: 18px;
      min-width: 18px;
      margin-left: 8px;
      top: unset;
      right: unset;
      transform: none;
      transform-origin: unset;
    }
  }
}

.header-button {
  &.MuiButton-root {
    border: 2px solid transparent;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      border-radius: 1px;
      background-color: $white;
      border: 2px solid $dark-grey;
    }

    .MuiTypography-body2 {
      font-family: $open-sans;
      letter-spacing: normal;
    }
  }
}

.cart {
  height: 52px;

  .MuiButton-label {
    color: $black;
    font: 600 14px/16px $open-sans;
  }

  &__icon {
    margin-right: 5px;
  }

  &__items-count {
    position: absolute;
    left: 27px;
    top: 5px;
    color: $red-pastel;
    font: bold 16px/22px $open-sans;

    &.two-digits {
      font-size: 14px;
      left: 23px;
    }

    &.three-digits {
      font-size: 12px;
      left: 23px;
    }
  }
}

.listings {
  max-width: 130px;

  &__icon {
    @media all and (max-width: 767.98px) {
      height: 27px;
    }
  }

  &__labels {
    margin-left: 8px;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
  }

  &__spotted {
    color: $light-grey;
    font: normal 12px/16px $open-sans;
  }

  &__ads {
    color: $black;
    font: 600 14px/16px $open-sans;
  }

  &__aditionalInfo {
    display: flex;
    align-items: center;
  }

  &__count {
    margin-left: 4px;
    font-weight: bold;
    color: $red-pastel;
  }

  .MuiSvgIcon-root {
    margin-left: 8px;
  }
}

.MuiGrid-spacing-xs-3 {
  margin: 0;
}

.search-area.MuiGrid-item {
  min-width: 205px;

  @media all and (min-width: 1280px) {
    margin-right: 50px;
  }
}

.search-container {

  @media all and (min-width: 1280px) {
    &.MuiGrid-root {
      max-width: calc(100% - 780px);
      flex-basis: calc(100% - 780px);
    }
  }
}

.MuiGrid-spacing-xs-3 > .search-container.MuiGrid-item {
  @media all and (max-width: 499.98px) {
    padding: 0px 12px 8px 12px;
  }
}

.search-filter {
  border: 2px solid transparent;
  border-radius: 4px;

  &:focus-within {
    outline: none;
    background: $red-pastel;
    border: 2px solid $red-pastel;

    .search-filter__button .MuiButton-root {
      border-radius: 0px;
    }

    .search-filter__all-categories.MuiButton-root {
      border-color: $white-snow;
    }

    .search-filter__input {
      .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
        background: $white;
        border-color: $white;
        border-width: 1px 0 1px 1px;
        -webkit-appearance: none;
  
        @media all and (max-width: 499.98px) {
          border-color: $white;
        }
      }
    }
  }

  &__options {
    width: 100%;
    margin: 0;
    font: 15px $roboto;
    color: $black;
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;

    @media all and (max-width: 499.98px) {
      font-size: 14px;
    }

    .name {
      max-width: 93%;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-transform: lowercase;

      em {
        font-weight: normal;
        font-style: normal;
      }
    }

    .category {
      color: $grey;
      font-weight: normal;
      font-size: 14px;
      white-space: pre;

      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @media all and (max-width: 499.98px) {
        font-size: 12px;
      }

      &__main {
        @media all and (max-width: 499.98px) {
          display: none;
        }
      }
    }
  }  

  &__all-categories.MuiButton-root {
    width: 204px;
    color: $black;
    background-color: $white-snow !important;
    text-align: left;
    padding: 12px 11px;
    border-width: 1px 0 1px 1px;
    border-color: $grey-mat-unfocus;
    border-style: solid;
    border-radius: 4px 0px 0px 4px;

    &:before {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 6px;
      margin-right: 8px;
    }

    .MuiButton-label {
      font: bold 14px/16px $open-sans;
    }
  }

  &__button.MuiButton-root {
    height: 48px;
    border-radius: 0px 4px 4px 0px;
    background-color: $red-pastel !important;
    border-color:  $red-pastel !important;
    transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover {
      opacity: 0.7;
    }
  }

  &__categories.dropdown__content {
    width: 204px;
    background-color: $white-snow;
    border-color: $grey-mat-unfocus;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    padding: 0;
  }

  &__container {
    width: calc(100% - 204px);
    display: flex;

    @media all and (max-width: 499.98px) {
      width: 100%;
    }

    .MuiAutocomplete-root {
      width: calc(100% - 64px);
    }

    &.borderUnset {
      .searchBar-popper {
        border: 0;
      }
    }
  }

  &__input {
    .MuiAutocomplete-inputRoot {
      &.MuiAutocomplete-inputRoot[class*="MuiInput-root"] {
        padding-bottom: 0;
      }

      &.MuiInput-underline:before,
      &.MuiInput-underline:after {
        display: none;
      }

      .MuiAutocomplete-input {
        border-width: 1px 0 1px 0;
        border-color: $grey-mat-unfocus;
        border-style: solid;
        height: 20px;
        letter-spacing: normal;
        padding: 13px 18px !important;
        font: normal 16px/20px $open-sans;
        -webkit-appearance: none;

        @media all and (max-width: 499.98px) {
          border-width: 1px 0 1px 1px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
    }
  }
}

.searchBarDrawer > .MuiPaper-root {
  min-height: $headerHeightMobile;
}

.searchBar-popper {
  border: 1px solid $grey-mat-unfocus;
  
  .MuiAutocomplete-listbox {
    padding: 0;
  }
}