@import "../../../scss/variables";
@import "./components/CookieSettingsPopUp.scss";

.cookies-bar {
    left: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    position: fixed;
    background: $white;
    padding: 1rem 1.5rem;
    justify-content: center;
    width: calc(100% - 3rem);
    box-shadow: 0px -1px 5px 0px rgba(0,0,0,0.2);

    @media all and (max-width: 319.98px) {
        position: unset;
        width: calc(320px - 3rem);
    }

    &__wrapper {
        display: flex;
        max-width: 1592px;
        align-items: center;
        justify-content: space-between;

        @media all and (max-width: 767.98px) {
            flex-direction: column;
        }
    }

    &__button.MuiButton-contained {
        min-width: 230px;
        color: $white;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    &__settings {
        font-size: 14px;
        text-transform: uppercase;

        @media all and (max-width: 767.98px) {
            margin-bottom: 0.5rem;
        }
    }

    &__image {
        max-height: 130px;

        @media all and (max-width: 767.98px) {
            margin-bottom: 1rem;
        }
    }

    .swal2-container &__pop-up.swal2-popup {
        @media all and (max-width: 500px) {
            justify-content: start;
        }
    }
}

.desc {
    margin-bottom: 1rem;

    @media all and (max-width: 767.98px) {
        max-width: unset;
    }

    &__label {
        margin-bottom: 1rem;
        font: bold 16px/18px $roboto;
    }

    &__content {
        font: normal 14px/16px $roboto;
    }
}