@import "../../../../../scss/_variables";

.userRatingOverview {
  background-color: $white;
  padding: 25px 30px;
  border-radius: 4px;
  font-family: $open-sans;

  @media all and (max-width: 499.98px) {
    padding: 10px 15px;
  }

  .title {
    font-size: 21px;
  }

  .RatingOverviewFilter {
    padding-top: 20px;
    .MuiTypography-body1  {
      font-size: 14px;
    }

    &__skeleton {
      margin-right: 50px;
      width: 200px;
      height: 24px;
    }
  }
}

.userRatingOverviewTable {
  padding-top: 7px;

  .link {
    color: $cyan-pastel
  }

  .offerLinkHolder {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .offerLink {
    font-size: 16px;
    text-decoration: none;
    @media all and (max-width: 499.98px) {
        max-width: 40ch;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    @media all and (max-width: 1199px) {
      max-width: 60ch;
    }
    @media all and (max-width: 959px) {
      max-width: 80ch;
    }
  }

  .ratingThumbsUpIcon {
    color: $green;
  }

  .ratingThumbsDownIcon {
    color: $red;

    svg {
      transform: rotate(180deg);
    }
  }

  .dateCell {
    white-space: pre;
  }

  .starsRatingWrapper {
    padding-top: 10px;

    .starsRating {
      .MuiRating-root {
        font-size: 25px;
        color: #f6ca6e;
        margin-right: 15px;
      }

      .MuiRating-iconEmpty {
        color: #f6ca6e;
      }
    }
  }

  .descriptionCell {
    max-width: 400px;
  }
}