@import "src/scss/variables";
@import "../DropZone";

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.pulse1 {
  animation-name: pulse;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: 1;

  &.delayed {
    animation-delay: 1s;
  }
}

.swal {
  &__title.swal2-title {
    color: $black;
    font: bold 16px/18px $open-sans;
  }

  &__button {
    &--deny.swal2-deny.swal2-styled {
      color: $cyan-pastel;
      text-transform: uppercase;
      background-color: transparent;
      font: normal 14px/16px $open-sans;

      @media all and (max-width: 499.98px) {
        margin-top: 0.5rem;
      }

      &.red {
        color: $red-pastel;
      }

      &.swal2-styled:hover {
        color: $medium-aquamarine;
        background-color: transparent;
        background-image: none;

        &.red {
          color: $red-pastel-dark;
        }
      }

      @media all and (max-width: 499.98px) {
        width: 100%;
      }
    }

    &--confirm.swal2-confirm.swal2-styled {
      color: $white;
      background-color: $grey;
      font: normal 14px/16px $open-sans;

      @media all and (max-width: 499.98px) {
        width: 100%;
      }
    }
  }
}


.swal2-container {
  .swal2-popup.deliveryCalculation {
    width: 800px;

    .swal2-content {
      .subtitle {
        margin-top: 0;
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 14px;
      }

      .MuiFormControlLabel-root {
        @media all and (min-width: 500px) and (max-width: 767.98px) {
          margin-bottom: 5px;
        }

        @media all and (max-width: 499.98px) {
          margin-bottom: 10px;
        }
      }

      .bottomText {
        font-size: 14px;
      }
    }
  }
}

.AddOffer {
  max-width: 1400px;
  margin: 0 auto !important;
  padding: 0 10px;

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .mt-30 {
    margin-top: 30px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mt-5 {
    margin-top: 5px;
  }

  .mt-15 {
    margin-top: 15px;
  }

  .ml-30 {
    margin-left: 30px;
  }

  .pair {
    width: calc(50% - 4px);
    max-width: calc(50% - 4px);

    .MuiFormControl-root {
      width: 100%;
    }
  }

  .triplet {
    width: calc(33.3333% - 5px);

    @media all and (max-width: 449.98px) {
      width: calc(50% - 5px);
    }

    .customSelect.MuiFormControl-root {
      min-width: unset;
    }
  }

  .subTitle {
    font-size: 14px;

    &-button {
      @media all and (max-width: 399.98px) {
        margin: 10px;
      }
    }
  }

  .chooseSubCategory {
    .disabled {
      opacity: 0.2;
    }
  }

  h5 {
    color: #767676;
  }

  .marketplace {
    color: $yellow-pastel !important;
  }

  .automoto {
    color: $blue-pastel !important;
  }

  .realestate, .nehnutelnosti {
    color: $green-pastel !important;
  }

  .spareparts, .spare-parts {
    color: $purple-pastel;
  }

  .marketplaceBg {
    background-color: $yellow-pastel !important;
  }

  .automotoBg {
    color: $white;
    background-color: $blue-pastel !important;
  }

  .realestateBg, .nehnutelnostiBG {
    background-color: $green-pastel !important;
  }

  .sparepartsBg, .spare-partsBg {
    color: $white;
    background-color: $purple-pastel;
  }

  .marketplaceActive {
    border: 1px solid $yellow-pastel !important;
  }

  .automotoActive {
    border: 1px solid $blue-pastel !important;
  }

  .marketplaceActive, .automotoActive {
    &.status-error {
      border: 2px solid $red !important;
    }
  }

  .realestateActive, .nehnutelnostiActive {
    border: 1px solid $green-pastel !important;

    &.status-error {
      border: 2px solid $red !important;
    }
  }

  .sparepartsActive, .spare-partsActive {
    border: 1px solid $purple-pastel !important;

    &.status-error {
      border: 2px solid $red !important;
    }

    .spare-parts:before {
      display: none;
    }
  }

  .redPastel, .buy-now {
    color: $red-pastel;

    &.active {
      border: 1px solid $red-pastel !important;
    }

    &.recommended {
      background: $red-pastel !important;
    }
  }

  .powderBlue, .auction {
    color: $powder-blue;

    &.active {
      border: 1px solid $powder-blue !important;
    }

    &.recommended {
      background: $powder-blue !important;
    }
  }

  .yellowPastel, .free-offer {
    color: $yellow-pastel;

    &.active {
      border: 1px solid $yellow-pastel !important;
    }

    &.recommended {
      background: $yellow-pastel !important;
    }
  }

  .relative {
    position: relative;
  }

  // Arrow icon in select
  .MuiInputBase-root {
    .MuiSvgIcon-root:not(.MuiChip-deleteIcon) {
      width: 1.5em !important;
      height: 1.5em !important;
      pointer-events: none;
      position: absolute;
      right: 0;
      color: #767676;
    }
  }

  // select height
  .MuiInputBase-root {
    height: 36px;
  }

  //input border color on hover
  .MuiOutlinedInput-root:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline {
    border-color: $grey-mat-unfocus;
  }

  .MuiOutlinedInput-root:not(.Mui-error), .MuiOutlinedInput-root.Mui-disabled {

    //input border color
    .MuiOutlinedInput-notchedOutline {
      border-color: $grey-mat-unfocus;
    }
    
    //input border color on focus
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: $black;
      }
    }
  }

  // select on focus background
  .MuiSelect-select:focus {
    background-color: transparent;
  }

  .MuiFormLabel-asterisk {
    color: red;
    position: absolute;
    top: 0;
    left: -5px;
  }

  .MuiButton-root {
    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.26) !important;
    }
  }

  .background {
    background-color: $white;
    width: 100%;
  }

  .shortDesc {

    .chip-input {
      .MuiInputBase-root {
        padding: 10px;
        height: auto;
        min-height: 108px;
        align-items: flex-start;

        @media all and (max-width: 499.98px) {
          min-height: 150px;
        }
  
        input.MuiInputBase-input {
          margin: 6px 0 0 0;
        }
  
        .MuiChip-root {
          height: 24px;
          overflow: hidden;
          margin: 8px 8px 0 4px;
        }
      }
    }
  }

  .borderSolid {
    border-bottom: 1px solid $grey-mat-unfocus;
    margin-right: -40px;
    margin-left: -40px;

    &--price {
      width: calc(100% + 80px);
    }

    @media all and (max-width: 768px) {
      margin-right: -15px;
      margin-left: -15px;

      &--price {
        width: calc(100% + 30px);
      }
    }
  }

  .dark {
    color: $dark-grey;
  }

  h2 {
    margin-bottom: 5px;
    margin-top: 15px;
    padding-left: 30px;
  }

  .displayNone {
    display: none;
  }

  .marginAuto {
    margin: 0 auto;
  }

  .notFullAuth {
    background-color: rgba(255, 128, 128, 0.5);
    padding: 15px 30px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    @media all and (max-width: 499.98px) {
      flex-direction: column;
    }

    h5 {
      color: $white;
      line-height: 19px;
    }

    img {
      margin-right: 25px;
      @media all and (max-width: 499.98px) {
        margin-right: 0px;
        margin-bottom: 15px;
      }
    }
  }

  .addOfferMarketplace {
    padding: 30px 40px 20px 40px;
    @media all and (max-width: 768px) {
      padding: 20px 15px;
    }
  }

  .chooseCategory {
    .category {
      border: 1px solid $grey-mat-unfocus;
      border-radius: 4px;
      padding: 10px;
      cursor: pointer;
      width: calc(33.33% - 15px);

      @media all and (min-width: 500px) and (max-width: 767.98px) {
        width: calc(33.33% - 6px);
      }

      @media all and (max-width: 499.98px) {
        width: 100%;
        margin-bottom: 10px;
      }

      .categoryInfo {
        justify-content: center;

        @media all and (min-width: 960px) and(max-width: 1079.98px) {
          display: flex;
          flex-direction: column;
          .bold {
            text-align: center;
          }
        }

        @media all and (min-width: 500px) and(max-width: 767.98px) {
          display: flex;
          flex-direction: column;
          .bold {
            text-align: center;
          }
        }

        .rectangle {
          width: 100%;
          height: 40px;

          @media all and (min-width: 500px) and (max-width: 767.98px) {
            height: 64px;
          }

          @media all and (min-width: 960px) and (max-width: 1079.98px) {
            height: 64px;
          }
        }
      }

      .categoryIcon {
        height: 40px;
        display: flex;
        align-items: center;
        margin-right: 10px;

        &:before{
          display: none;
        }

        @media all and (min-width: 960px) and (max-width: 1079.98px) {
          margin-right: 0;
        }

        @media all and (min-width: 500px) and (max-width: 767.98px) {
          margin-right: 0;
        }
      }
    }

    .nonActive {
      opacity: 0.2;
      cursor: auto;
    }

    .noCategory {
      opacity: 1;
      cursor: pointer;
    }
  }

  .chooseImages {

    &.disabled {
      opacity: 0.2;

      .dropzone {
        cursor: auto;
      }
    }

    h5 {
      color: $grey-mat-focus;
    }

    .border {
      height: 2px;
      color: #C929E6;
      background-image: url("data:image/svg+xml,%3csvg width='100%' height='20px' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='20px' fill='none' stroke='%23E1E1E1' stroke-width='4' stroke-dasharray='25' stroke-dashoffset='100' stroke-linecap='square'/%3e%3c/svg%3e");
      margin: 0 -40px;
      @media all and (max-width: 768px) {
        margin: 0 -15px;
      }
    }

    .imagesHolder {
      min-height: 280px;

      .addImageButton {
        color: $white;
        font-weight: bold;

        img {
          margin-right: 15px;
        }
      }

      .imageItemHolder {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 8px;
        margin-bottom: 8px;

        &:focus {
          outline: none;
          cursor: grabbing;
        }

        &:active {
          cursor: grabbing;
        }

        .imageItem {
          width: 100px;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          position: relative;
          cursor: pointer;

          &:active, &:focus {
            cursor: grabbing;
          }

          img {
            max-width: 100%;
            max-height: 100%;
          }

          .title {
            border-radius: 4px 4px 0px 0px;
            opacity: 1;
            position: absolute;
            top: 0;
            width: 100%;
            text-align: center;
            padding: 3px 0;
            font-family: $open-sans;
            letter-spacing: 0.4px;
            font-size: 12px;
          }

          .error-uploading {
            text-align: center;
          }
        }

        .deleteImageBtn {
          font-family: $open-sans;
          letter-spacing: 0.4px;
          font-size: 12px;
          line-height: 20px;
          margin-top: 2px;

          border: none;
          background: transparent;

          &.error {
            color: $red !important;
          }

          &:disabled {
            opacity: 0.6;
            pointer-events: none;
          }
        }
      }
    }
  }

  .titleName {
    margin-top: 50px;

    .MuiFormHelperText-contained {
      margin: 0;
      text-align: end;
      font-size: 13px;
      color: $grey-mat-focus;
    }
  }

  .serialNumber {
    .MuiInputBase-input {
      text-transform: uppercase;
    }
  }

  .detailedDescription {
    margin-top: 50px;
    // select height only in multiline

    .text-editor {
      display: flex;
      height: 40px;
      border: 1px solid $grey-mat-unfocus;
      margin-bottom: 15px;
      justify-content: flex-end;
      align-items: center;
      padding: 5px;
      border-radius: 4px;
    }

    .MuiInputBase-root {
      min-height: 190px;
      height: 100%;
      overflow: auto;
    }
  }

  .status {
    margin-bottom: 45px;

    .statusItem {
      position: relative;
      padding: 10px 20px;
      border-radius: 4px;
      border: 1px solid $grey-mat-unfocus;
      cursor: pointer;
      max-width: 49.5% !important;
      margin-bottom: 10px;
      @media (max-width: 499.98px) {
        max-width: 100% !important;
      }

      .label {
        color: $dark-grey;
        line-height: 24px;
      }

      .desc {
        color: $grey;
        line-height: 24px;
      }

      .checkIcon {
        position: absolute;
        top: 17px;
        right: 20px;
        width: 14px;
        height: auto;
      }
    }

    .active {
      border: 1px solid $red-pastel;
    }
  }

  .text-grey {
    color: $grey;
  }

  .price {
    margin: 20px 0;

    @media all and (min-width: 500px) {
      &--currency {
        margin-right: 20px;
      }
    }
  }

  .pieces {
    margin-bottom: 40px;

    @media all and (max-width: 499.98px) {
      margin-top: 20px;
    }
  }

  .location {
    .MuiInputLabel-outlined {
      transform: translate(14px, 14px) scale(1);
      color: $grey;
      font-size: 14px;
    }

    .makeStyles-icon-30 {
      border: 2px solid $grey-mat-unfocus;
    }

    .MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }

    .MuiSvgIcon-root {
      width: 1em !important;
      height: 1em !important;
      right: 10px;
      color: $red-pastel;
    }

    .MuiFormHelperText-contained {
      margin-left: 0px;
    }

    .whiteSpace {
      width: 28px;
    }

    .phoneNumberField, .addressField {
      width: calc(100% - 35px) !important;
    }

    .phoneNumberField {
      @media (max-width: 499.98px) {
        width: 100% !important;
      }
    }

    .noWrap {
      flex-wrap: nowrap;
    }

    .phoneButtonHolder {
      text-align: end;

      .MuiButton-contained {
        color: $white;
        @media (max-width: 499.98px) {
          width: calc(100% - 35px) !important;
          margin-top: 10px;
        }
      }
    }

    .delete-button {
      height: 38px;
      display: flex;
      align-items: center;

      @media all and (max-width: 499.98px) {
        justify-content: center;
      }
    }

    .MuiFormControlLabel-root {
      margin-right: -3px;
    }

    h6 {
      color: $grey;
      margin-bottom: 30px;
    }

    .MuiTypography-body1, .MuiInputBase-input {
      font-size: 14px;
      font-family: OpenSans, sans-serif;
      font-weight: 400;
      line-height: 24px;
      color: $dark-grey;
    }

    .nonActive {
      opacity: 1;

      .MuiTypography-body1 {
        color: $grey-mat-focus;
      }
    }
  }

  .transport {
    h6 {
      text-align: right;
    }

    .MuiTypography-colorTextSecondary {
      color: $dark-grey;
    }

    .MuiOutlinedInput-adornedEnd {
      padding-right: 12px;

      @media (max-width: 499.98px) {
        padding-right: 10px;
      }
    }

    .MuiInputAdornment-positionEnd {
      margin-left: 0;
      @media (max-width: 499.98px) {
        padding-right: 0px;
      }
    }

    .priceInput {

      .priceInputHolder {
        max-width: 120px;

        @media all and (min-width: 550px) and (max-width: 599.98px) {
          max-width: 110px;
        }

        @media all and (min-width: 500px) and (max-width: 549.98px) {
          max-width: 100px;
        }

        @media all and (min-width: 500px) and (max-width: 599.98px) {
          &:nth-of-type(3) {
            margin-left: 15px;
          }
        }

        @media all and (max-width: 499.98px) {
          &:nth-of-type(3) {
            margin-left: 10px;
          }
        }

        .priceTextField {
          &.disabledValue {
            pointer-events: none;
          }
        }
      }
    }

    .MuiInputBase-input {
      @media (max-width: 499.98px) {
        padding-right: 5px;
      }
    }

    .MuiTypography-body1 {
      font-size: 14px;
      line-height: 24px;
      color: $dark-grey;
    }

    .nonActive {
      opacity: 1;

      .MuiTypography-body1 {
        color: $grey-mat-focus;
      }

      .MuiInputAdornment-positionEnd {
        .MuiTypography-body1 {
          color: $grey-mat-unfocus;
        }
      }

      .MuiInputBase-input {
        color: $grey-mat-unfocus;
      }
    }

    .Mui-disabled {
      color: $grey-mat-unfocus;
    }
  }

  .postAsInfo {
    color: $grey;
    text-align: justify;
    font: normal 13px/18px $open-sans;

    @media all and (max-width: 499.98px) {
      font-size: 12px;
    }

    a {
      color: $grey;
      font-weight: bold;
      text-decoration: none;
    }
  }

  .postAs {
    .postAsItem {
      position: relative;
      padding: 5px 15px 15px 15px;
      border-radius: 4px;
      border: 1px solid $grey-mat-unfocus;
      cursor: pointer;
      margin-bottom: 15px;
      max-width: 450px;

      .label {
        line-height: 24px;
        font-size: 18px;
        font-weight: 600;
        @media all and (max-width: 360px) {
          font-size: 14px;
        }
      }

      .desc {
        color: $dark-grey;
        line-height: 20px;
        white-space: pre-line;
      }

      .checkIcon {
        position: absolute;
        top: 10px;
        right: 20px;
        width: 14px;
        height: auto;
        @media all and (max-width: 400px) {
          right: 10px;
        }
      }

      .icon {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        svg {
          width: calc(100% - 10px);
          height: 40px;
        }
      }

      .holder {
        margin-bottom: 15px;
        display: flex;
        @media all and (max-width: 499.98px) {
          flex-direction: column;
        }

        .recommended {
          display: flex;
          align-items: center;
          border-radius: 4px;
          color: $white;
          padding: 2px 10px;
          letter-spacing: 0.11px;
          margin-left: 20px;
          font-weight: 700;
          @media (min-width: 960px) and (max-width: 1200px) {
            margin-left: 10px;
          }
          @media all and (max-width: 499.98px) {
            margin-left: 0px;
          }
        }
      }
    }
  }

  .highlightDesc {
    color: $dark-grey;
  }

  .highlight {
    .highlightedItem {
      position: relative;
      padding: 20px 5px;
      border-radius: 4px;
      border: 1px solid $grey-mat-unfocus;
      cursor: pointer;
      margin-bottom: 15px;
      max-width: 400px;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: calc(100% - 10px);
          max-height: 50px;
        }
      }

      .checkIcon {
        position: absolute;
        top: 10px;
        right: 20px;
        width: 14px;
        height: auto;
      }

      .label, .price, .desc {
        color: $dark-grey;
      }

      .price {
        margin: 0;
      }

      .days {
        margin-bottom: 5px;
        color: $grey-mat-focus;
      }

      &.disabled {
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }


  .rightSide {
    position: sticky;
    width: 100% !important;
    max-width: 100%;
    padding-left: 10px !important;
    padding-right: 10px;
    top: 0;

    @media all and (max-width: 959.98px) {
      position: relative;
      margin-top: 10px;
      padding: 0px !important;
      width: 100%;
    }

    .rectangle {
      width: 100%;
      height: 200px;
      margin-bottom: 20px;
    }

    .line {
      width: 100%;
      height: 44px;
    }

    .holder {
      padding: 20px 40px 30px 40px;
      background-color: $white;
      border-radius: 4px;


      h2 {
        padding: 0;
        margin: 0 0 20px 0;
      }

      .mt-10 {
        margin-top: 10px
      }

      .edit-offer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        &--auction {
          svg {
            color: $powder-blue;
          }
        }

        &--automoto {
          svg {
            color: $blue-pastel;
          }
        }

        &--buy-now {
          svg {
            color: $red-pastel;
          }
        }

        &--free-offer {
          svg {
            color: $yellow-pastel;
          }
        }

        svg {
          text {
            fill: $white;
          }
        }

        .important {
          margin: 4px 0 0 10px;
          font-weight: 600;
          vertical-align: middle;
        }
      }

      .additionalText {
        .bulb {
          padding-right: 10px;
        }

        h4 {
          width: calc(100% - 40px);
          color: $grey;
        }
      }

      ul {
        padding-inline-start: 20px;
      }

      li {
        color: $dark-grey;
        font-family: $open-sans;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 10px;
      }

      h5 {
        color: $dark-grey;
      }

      .AddOfferButton {
        width: 90%;
        padding: 10px;
        min-height: 40px !important;
        height: auto;

        .MuiButton-label {
          text-transform: uppercase;
          font-weight: bold;
          color: $white;
        }

        &.Mui-disabled {
          opacity: 0.5;
        }

        .publishButtonSpinner {
          margin-left: 10px;
          color: $white;
        }
      }
    }

    .agreement {
      margin-top: 10px;
      color: $dark-grey;

      .link {
        color: $dark-grey;
        text-decoration: underline;
      }
    }

    .draft {
      padding: 0 40px;

      .draftButton {
        background: $grey;
        border: 2px dashed $white;
        width: 90%;

        .MuiButton-label {
          text-transform: uppercase;
          font-weight: bold;
          color: $white;
        }

        @media all and (max-width: 499.98px) {
          display: none;
        }
      }

      .lastSave {
        margin-top: 5px;
      }

      @media all and (max-width: 499.98px) {
        margin-top: 10px;

        .lastSave {
          margin-top: 0;
          margin-bottom: 15px;
        }
      }
    }
    
    .paymentObligation {
      padding-top: 5px;
  
      @media all and (max-width: 499.98px) {
        font-size: 10px;
      }
    }

    .returnConditionsSelectWrapper {
      margin-top: 10px;
      width: 90%;

      &:first-of-type {
        padding-top: 15px;
      }
  
      .returnTOCSelect,
      .returnPolicySelect {

        label, select {
          width: 80%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .MuiInputLabel-outlined {
          transform: translate(14px, 10px) scale(1);

          &.MuiInputLabel-shrink {
            transform: translate(14px, -6px) scale(0.75)
          }
        }

        svg {
          top: calc(50% - 18px)
        }
      }

      .newConditionsLink {
        font-size: 12px;
        font-family: $open-sans;
        padding-top: 6px;
        padding-left: 4px;
      }

    }

    .errorLink {
      text-decoration: unset;
    }
  }
}