@import './../../../../scss/variables';

.cookies-settings {

    &__setting {
        font: normal 14px/18px $open-sans;
    }

    &__accept.MuiButton-root {
        @media all and (max-width: 399.98px) {
            width: 100%;
            margin-top: 1rem;
        }

        .MuiButton-label {
            font-weight: bold;
        }
    }

    &__save.MuiButton-root.MuiButton-outlined {
        font-weight: bold;
        color: $red-pastel !important;
        border-color: $red-pastel;

        @media all and (max-width: 399.98px) {
            width: 100%;
        }
    }

    &__more-info {
        font-size: 14px;
        align-self: center;

        @media all and (max-width: 399.98px) {
            margin-bottom: 1rem;
        }
    }
}

.setting {
    &__check-icon {

        svg {
            width: 20px;
            height: 20px;
        }

        &--basic {
            margin-left: 10px;
            margin-right: 12px;

            svg {
                color: $green;
            }
        }
    }

    &__title {
        font: bold 16px/24px $open-sans;
    }

    &__option {
        font: bold 14px/24px $open-sans;

        &--allowed {
            color: $green;
        }
    }
}