@import "src/scss/variables";

.AddToCartCounter {
  .count {
    text-align: center;
    min-width: 10px;
    margin: 0 5px;
  }

  .MuiButton-root {
    min-width: 25px;
    width: 25px;
    height: 25px;
    background-color: $grey-mat-unfocus !important;
    border-radius: 50%;

    .MuiSvgIcon-root {
      width: 15px;
      height: 15px;
      color: $dark-grey;
    }

    &.Mui-disabled {
      .MuiSvgIcon-root {
        color: $grey;
      }
    }
  }

  .maxCount {
    color: $grey;
    margin-left: 15px;
  }
}
