@import "src/scss/variables";

.NoResults {
  background-color: $white;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 100px 20px;
  border-radius: 4px;
  position: relative;

  &-container {
    width: 100%;
  }

  @media all and (max-width: 499.98px) {
    padding: 50px 10px;
    h3 {
      font-size: 14px;
      line-height: 18px;
    }
    h5 {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .dark {
    color: $dark-grey;
  }

  .mb-3 {
    margin-bottom: 3px;
  }

  .backgroundImage {
    position: absolute;
    @media all and (max-width: 499.98px) {
      img {
        height: 100px;
      }
    }
  }
}