@import "src/scss/variables";

.MyReceivedOrders {

  .styled-searchBar {
    border: 1px solid rgba(0, 0, 0, 0.23);
    height: 38px;
    width: 250px;
    margin-bottom: 10px;
    @media all and (max-width: 499.98px) {
      width: 100%;
      margin-top: 10px;
    }
  }

  .roboto {
    font-family: $roboto;
  }

  .bold {
    font-weight: bold !important;
  }

  .pr-15 {
    padding-right: 15px;
  }

  .pl-30 {
    padding-left: 30px !important;
  }

  .pr-30 {
    padding-right: 30px !important;
  }

  .w-10 {
    width: 10%;
  }
  .w-20 {
    width: 20%;
  }

  .w-30 {
    width: 30%;
  }

  .w-50 {
    width: 50%;
  }

  .mt-5 {
    margin-top: 5px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .pt-5 {
    padding-top: 5px;
  }

  .pb-10 {
    padding-bottom: 10px;
  }

  .iconHolder {
    color: $grey;

    svg {
      width: 25px;
      color: $grey;

      &:hover {
        color: $red-pastel !important;
      }
    }

    button {
      background: transparent;

      &:disabled {
        pointer-events: auto;

        svg {
          opacity: 0.4;

          &:hover {
            color: $grey !important;
          }
        }

      }
    }
  }

  .customButton {
    color: $dark-grey !important;
    background-color: $white-snow;
    border: 1px solid $grey-mat-unfocus;
    border-radius: 4px;
    font-weight: normal !important;
    font-size: 14px;
    height: 36.75px;
    margin-bottom: 10px;
    @media all and (max-width: 499.98px) {
      width: 100%;
    }
    
    &:disabled {
      color: rgba(0, 0, 0, 0.26) !important;
    }
  }

  .Mui-checked {
    color: $red-pastel !important;
  }

  .selectAllHeadCell {
    .MuiCheckbox-indeterminate {
      color: $red-pastel !important;
    }
  }

  .filterInput {
    margin-bottom: 10px;
    width: 250px;
  }

  .searchHolder {
    .styled-searchBar {
      margin-bottom: auto;
    }
  }

  .filterWrapper {
    .filters {

      h3 {
        font-weight: bold;
        padding-bottom: 5px;
  
        @media all and (max-width: 499.98px) {
          font-size: 14px;
        }
      }

      .orderCount {
        margin-left: auto;
        align-self: flex-end;
        @media all and (max-width: 499.98px) {
          margin-left: 0px;
          margin-bottom: 0px;
          padding-top: 10px;
        }
      }

      .divider {
        width: 100%;
        margin: 10px 0 20px 0;
      }
    }
  }
}