@import "./components/ChatOverview/ChatOverview";
@import "./components/ConversationItem/ConversationItem";
@import "./components/ConversationDetail/ConversationDetail";
@import "./components/MessageInput/MessageInput";
@import "./components/MessageItem/MessageItem";

.chat {
  display: flex;
  flex-direction: column;

  .options {
    padding: 20px 30px 20px 20px;

    @media all and (max-width: 499.98px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &__newMessage {
      margin: auto;

      @media all and (max-width: 499.98px) {
        justify-content: center;
      }

      &__content {
        display: inline-flex;
        align-self: flex-end;

        @media all and (max-width: 499.98px) {
          padding-top: 15px;
        }

        svg {
          width: 25px;
          color: $grey;
          margin-right: 10px;
          max-height: 25px;
        }

        a {
          color: $cyan-pastel;
          font-family: $open-sans;
          text-decoration: none;
          align-self: center;
        }
      }
    }
  }
}
