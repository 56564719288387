@import "src/scss/variables";

.labelsAutomotoHolder {
  min-height: 25px;
  align-items: center;

  @media all and (min-width: 1200px) {
    max-height: 25px;
    overflow: hidden;
  }

  .labelsAutomoto {
    font-size: 13px;
    line-height: 15px;
    color: $white;
    font-family: $open-sans;
    width: auto;
    border-radius: 24px;
    padding: 2px 10px;

    @media all and (max-width: 499.98px) {
      font-size: 10px;
    }
    @media all and (max-width: 350px) {
      &:last-child {
        display: none;
      }
    }

  }
}