@import "../../../scss/variables";

.passwordForm {
  &.MuiFormControl-root {
    margin-bottom: 20px !important;

    .MuiFormLabel-root {
      color: $grey-mat-focus !important;
    }

    .MuiInputBase-root {
      color: $grey-mat-focus !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $grey-mat-unfocus;
    }
  }
}
