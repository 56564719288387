@import "src/scss/variables";

.salesSettings {
  @media all and (max-width: 499.98px) {
    margin-bottom: 30px;
  }

  .MuiAccordion-root {
    width: 100%;
  }

  .bold {
    font-weight: bold !important;
  }

  .dark {
    color: $dark-grey;
  }

  .MuiFormGroup-root {
    padding-left: 15px;
  }

  .MuiGrid-spacing-xs-1 {
    .MuiGrid-item:last-child {
      padding-right: 0px;
    }
  }

  .TransportCalculation {
    h2 {
      display: none;
    }

    .buttonsHolder {
      margin-top: 20px;

      .MuiLink-root {
        display: none;
      }
    }
  }

  .Reclamation, .ReturnedGoods {
    .emptyRows, .filters, .categoriesStatus-container {
      display: none;
    }

    .nameInput {
      .MuiFormHelperText-root {
        text-align: right;
      }
    }

    th {
      &.MuiTableCell-root:last-child {
        text-align: right;
      }

      &.MuiTableCell-head:nth-child(2) {
        width: 25%;
      }

      &.MuiTableCell-head:nth-child(4) {
        width: 28%;
      }
    }

    .zipAndCityWrapper {
      align-items: flex-start;

      .ZIPinput {
        width: calc(100% - 15px);
        @media all and (max-width: 959.98px) {
          width: 100%;
        }
      }
    }

    .descriptionWrapper {
      &.error {
        .tox-tinymce {
          border: 1px solid $red;
        }
      }

      .tox-tinymce {
        border-radius: 4px;
      }
    }
  }

  form, .tox {
    width: 100%;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-5 {
    margin-bottom: 5px;
  }

  .mt-5 {
    margin-top: 5px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .pl-33 {
    padding-left: 33px;
  }

  .mr-15 {
    margin-right: 15px;
  }

  .Mui-expanded {
    .MuiAccordionSummary-root {
      border-bottom: 1px solid $red-pastel;
    }

    .accordionHeading {
      font-weight: bold;
    }
  }

  .wrapped-label {
    label {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 85%;
    }

    .MuiInputLabel-shrink {
      overflow: unset;
    }
  }
  
  .newWrrantyPolicyContianer,
  .newWrrantyTOCContianer {
    position: relative;
  }

  .formLoadingOverlay {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    border-radius: 5px;

    .formLoadingSpinner {
      color: $red-pastel;
      position: relative;
      top: 50%;
      left: 50%;
    }
  }
}

.deleteWarningPopUp {
  .confirm {
    text-align: start;
  }
}