@import "src/scss/variables";

.edit-delivery-type-mobile {

  &__content {
    width: 100%;

    .delivery-type {
      margin-bottom: 10px;

      &__checkbox {
        display: inline-flex;
        align-items: flex-start;

        .MuiRadio-root {
          padding: 0;
          margin-right: 8px;
        }

        .MuiTypography-root {
          font-size: 13px;
          color: $black;
        }
      }

      &__price {
        font-size: 13px;
        color: $black;
      }
    }
  }
}
