@font-face {
  font-family: 'OpenSans';
  font-weight: normal;
  src: url("/src/assets/fonts/OpenSans-Regular.ttf") format('ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: normal;
  font-style: italic;
  src: url("/src/assets/fonts/OpenSans-Italic.ttf") format('ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: 600;
  src: url("/src/assets/fonts/OpenSans-SemiBold.ttf") format('ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: bold;
  src: url("/src/assets/fonts/OpenSans-Bold.ttf") format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: url("/src/assets/fonts/Roboto-Light.ttf") format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  src: url("/src/assets/fonts/Roboto-Regular.ttf") format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: italic;
  src: url("/src/assets/fonts/Roboto-Italic.ttf") format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url("/src/assets/fonts/Roboto-Medium.ttf") format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-weight: bold;
  src: url("/src/assets/fonts/Roboto-Bold.ttf") format('ttf');
}