$red-pastel: #FF8080;
$red-pastel-dark: #EF6666;
$blue-pastel: #50AEFF;
$blue-pastel-dark: #4094e5;
$yellow-pastel: #FFD548;
$yellow-pastel-dark: #efbb2e;
$purple-pastel: #c929e6;
$purple-pastel-dark: #b90fcc;
$green-pastel: #A0E65A;
$green-pastel-dark: #70B22D;
$green-pastel-light: #89C74A;
$turquoise: #A0E2CD;
$cyan-pastel: #44A8A7;
$gold-digger: #F6CA6E;
$light-grey: #727272;
$grey-chips: #E4E4E4;
$grey-price: #3A4E58;
$grey-blue-footer: #37475A;
$light-grey-bg: #EDEFF1;
$grey: #C3C3C3;
$grey-mat-unfocus: #E1E1E1;
$grey-mat-focus: #767676;
$grey-light-focus: #A6A6A6;
$cyan-focus: #69C4C3;
$red: #FF0000;
$black: #000000;
$black-light-grey: #54545400;
$dark-grey: #222222;
$white: #FFFFFF;
$white-snow: #F8F8F8;
$green: #1BB826;
$cyan: #136D6D;
$light-blue: #50AEFF;
$golden-rod: #ffb034;
$medium-sea-green: #23c186;
$medium-aquamarine: #69c4c3;
$powder-blue: #a0e2cd;
$dim-gray: #707070;
$chinese-silver: #cccccc;
$grayish-light-blue: #DEE0E2;
$midnight-blue: #222F3E;
$iron: #C8CBCF;

$carVerticalBlackDark: #000000;
$carVerticalBlackLight: #474748;
$carVerticalBlue: #04c1e9;
$carVerticalYellow: #ffd532;

$open-sans: 'OpenSans', sans-serif;
$roboto: 'Roboto', sans-serif;

$media-xs: 499.98px;
$media-lg: 1199.98px;
