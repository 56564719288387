@import "../../../../../scss/variables";

.OrderDetail {
  @media all and (max-width: 499.98px) {
    padding: 0 0 10px 0 !important;
  }

  .verticalDivider {
    padding: 0 15px;

    &.second {
      @media all and (max-width: 1299.98px) {
        display: none;
      }
    }
  }

  .holder {
    align-items: baseline;

    @media (min-width: 500px) and (max-width: 700px) {
      flex-direction: column;
      align-items: flex-start;
    }
    @media all and (max-width: 499.98px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .sellerInfoWrapper {
    align-self: center;

    @media all and (max-width: 700px) {
      align-self: flex-start;
    }
  }

  .statusCreated {
    display: flex;
    flex-wrap: wrap;

    @media all and (max-width: 499.98px) {
      & h4, span {
        font-size: 11px;
      }      
    }

    // &.wrapped1 {
    //   .statusCreated__timestamp::after{
    //     display: none;
    //   }
    // }

    // &.wrapped2 {
    //   .statusCreated__timestamp::after,
    //   .statusCreated__status::after{
    //     display: none;
    //   }
    // }

    &__timestamp::after,
    &__status::after{
      content: '|';
      padding: 0 15px;

      @media all and (max-width: 499.98px) {
        display: none;
      }
    }

    &__timestamp::after{
      @media all and (max-width: 959.98px) {
        display: none;
      }
    }

  }

  .basicInfoHolder {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: flex-end;
    align-items: flex-start;

    @media all and (max-width: 700px) {
      align-content: flex-start;
      justify-content: flex-start;
    }

    // @media (min-width: 500px) and (max-width: 700px) {
    //   align-items: flex-start;
    //   margin-top: 5px;
    // }

    // @media all and (max-width: 420px) {
    //   align-items: flex-start;
    //   align-content: flex-start;
    // }

    .orderId {
      @media all and (max-width: 959.98px) {
          display: none;
      }
    }

    &.wrapped1,
    &.wrapped2 {
      flex-direction: column;
    }

    &.wrapped1 {
      .statusCreated__timestamp::after{
        display: none;
      }
    }

    &.wrapped2 {
      .statusCreated__timestamp::after,
      .statusCreated__status::after{
        display: none;
      }
    }
  }

  .dark {
    color: $dark-grey;
    word-break: break-word;
  }

  .light {
    color: $grey-mat-focus;
  }

  .mb-25 {
    margin-bottom: 25px;
  }

  .mb-15 {
    margin-bottom: 15px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-5 {
    margin-bottom: 5px;
  }

  .mt-5 {
    margin-top: 5px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mt-15 {
    margin-top: 15px;
  }

  .pb-20 {
    padding-bottom: 20px;
  }

  .titleWrapper {
    @media all and (max-width: 499.98px) {
      padding: 10px;
      min-height: 35px;
    }
  }

  .roboto {
    font-family: $roboto;
  }

  .noWrap {
    white-space: nowrap;
  }

  .together {
    padding: 20px 30px;

    @media all and (max-width: 499.98px) {
      padding: 10px;
    }
  }

  .MuiDivider-root {
    width: 100%;
    background-color: $grey-mat-unfocus;
  }

  .title {
    @media all and (max-width: 499.98px) {
      padding: 0 !important;
      font-size: 14px !important;
      margin: 0 10px 0 0 !important;
    }
  }

  h4 {
    .link {
      font-size: 13px;
    }
  }

  .mobileCreated {
    font-size: 12px;

    @media all and (max-width: 349.98px) {
      margin: 6px 20px 0 0;
    }
  }

  .backTo {
    padding: 10px 15px;
    color: $grey-mat-focus;
    cursor: pointer;

    .MuiSvgIcon-root {
      width: 1.2em;
      height: 1.2em;
      font-size: 1.2em;
    }
  }

  .cardTitleMobile {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 3px;

    h5 {
      font-size: 14px;
    }
  }

  .OrderItems {
    @media all and (max-width: 499.98px) {
      padding-bottom: 10px;
    }

    .imageHolder {
      max-width: 80px;
      width: 80px;

      img {
        width: 65px;
        height: auto;
      }
    }

    .infoHolder {
      width: calc(100% - 80px);

      .orderItemTitle {
        padding-right: 20px;

        @media all and (max-width: 499.98px) {
          padding-right: 10px;
        }
      }
    }
  }

  .DeliveryPaymentOptions {
    margin-bottom: 20px;
    @media all and (max-width: 499.98px) {
      margin-bottom: 0;
    }

    .change {
      display: none;
    }

    h5:not(.title) {
      font-size: 13px;
    }

    .deliveryPaymentTitle {
      background-color: $light-grey-bg;
      margin-right: -10px !important;
      margin-left: -10px !important;
      padding-left: 10px;
      width: calc(100% + 20px);
      padding-top: 10px;
      padding-bottom: 3px;

      h5 {
        font-size: 14px;
      }
    }

    .paymentOptions {
      @media all and (max-width: 959.98px) {
        margin-top: 30px;
      }
    }

    .arrowHolder {
      color: $grey-mat-focus;
    }

    .item {
      padding: 10px 0px;
      cursor: pointer;
    }

    .cardImageHolder {
      margin-right: 5px;
      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  .OrderRating {

    .MuiRating-iconEmpty, .MuiRating-root {
      color: $gold-digger;
    }

    .rating {
      width: auto;

      @media all and (max-width: 499.98px) {
        padding: 10px 0;
        a {
          font-size: 13px;
        }
      }
    }

    .iconHolder {
      padding-right: 20px;
      @media all and (max-width: 499.98px) {
        padding-right: 15px;
      }

      &.dislike {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .customLink {
      cursor: pointer;
      color: $dark-grey;
      font-size: 14px;
      font-family: $open-sans;
    }

    .yourRating {

      .rating {
        margin-bottom: 0px;

        color: $green;

        svg {
          color: $green;
        }

        @media (min-width: 500px) and (max-width: 700px) {
          max-width: 40%;
          flex-basis: 40%;
        }
        @media all and (max-width: 499.98px) {
          align-items: center;
        }
      }

      .values {
        @media (min-width: 500px) and (max-width: 700px) {
          max-width: 60%;
          flex-basis: 60%;
          .MuiGrid-grid-sm-4 {
            max-width: 100%;
            flex-basis: 100%;
            height: auto;
          }
        }
        @media all and (max-width: 499.98px) {
          .MuiGrid-grid-sm-4 {
            height: auto;
          }
        }

        .MuiTypography-root:not(:last-child) {
          padding-right: 15px;
        }
      }

      .dislike {
        color: $red;

        svg {
          color: $red;
        }
      }

      .MuiTypography-body1 {
        font-size: 14px;
        font-family: $open-sans;
        color: $dark-grey;
      }

      .MuiBox-root {
        @media all and (min-width: 700px) {
          margin-bottom: 0px !important;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        @media all and (max-width: 699.98px) {
          &.muiBox-last {
            margin-bottom: 0px !important;
          }
        }
      }
    }
  }

  .Seller {
    .sellerTitle {
      margin-bottom: 5px;
    }

    .link {
      font-size: 14px;
      line-height: 18px;
    }

    .addressContactHolder {
      @media all and (max-width: 499.98px) {
        margin-top: 15px;
      }
    }
  }

  .ReturnedGoods {
    @media all and (max-width: 499.98px) {
      padding: 20px 10px;
    }

    .link {
      font-size: 14px;
      line-height: 18px;
    }

    .timeIsGone {
      font-size: 14px;
      font-style: $open-sans;
    }
  }

  .ProblemsWithOrder {
    .secure {
      .secureShopping {
        padding-right: 10px;

        .link {
          font-size: 12px;
        }

        h4 {
          line-height: 18px;
        }
      }

      .icon {
        margin-right: 15px;
        width: auto;
        height: 33px;
      }
    }
  }
}