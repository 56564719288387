#NotificationBar {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: -5px auto 10px auto;
  padding: 5px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  .notificationsBackground {
    display: flex;
    height: auto;
    min-height: 20px;
    border-radius: 4px;
    padding: 5px 10px;
    width: 100%;
  }

  .iconHolder {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: $white;
      width: 15px;
      height: 15px;
    }
  }

  .closeIcon {
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;

    svg {
      color: $white;
      width: 10px;
      height: 10px;
    }
  }

  .link {
    color: $white !important;
    text-decoration: underline;
    font-weight: bold;
  }

  h5 {
    color: $white;
    @media all and (max-width: 499.98px) {
      font-size: 12px;
    }
  }

  &.info .notificationsBackground {
    background-color: $red-pastel;
  }

  &.error .notificationsBackground {
    background-color: $red;
  }

  &.success .notificationsBackground {
    background-color: $green-pastel-dark;
  }

  &.done .notificationsBackground {
    background-color: rgba(112, 178, 45, 0.55);

    .iconHolder {
      svg {
        color: $green-pastel-dark;
      }
    }
  }

  &.warning .notificationsBackground {
    background-color: $golden-rod;
  }
}