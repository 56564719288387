@import "src/scss/variables";

.carouselCard {
  background-color: $white;
  border-radius: 4px;
  width: 100%;
  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.hide {
    display: none;
  }

  .carouselTitle {
    margin-bottom: 0;
  }
}

.productCarouselWrapper {
  padding: 20px 35px 15px 30px;

  @media all and (max-width: 499.98px) {
    padding: 0px;
    padding-top: 10px;

    h2 {
      padding-left: 20px;
    }
  }

  .carousel {
    overflow: hidden;

    $button-size: 45px;

    .carouselContent {
      position: relative;
    }

    .changeItemButton {
      width: $button-size;
      height: $button-size;
      min-width: auto;
      position: absolute;
      background-color: $grey;
      opacity: 0.6;
      border: none;
      border-radius: 0;
      z-index: 1;
      top: calc(50% - 23px);
    }

    .buttonLeft {
      left: 10px;
    }

    .buttonRight {
      left: calc(100% - #{$button-size} - 10px);
    }

    .carouselItemsWrapper {
      .show-1 {
        min-width: 100%;
      }

      .show-2 {
        min-width: calc(85% / 2);
        max-width: calc(85% / 2);
      }

      .show-3 {
        min-width: calc(85% / 3);
        max-width: calc(85% / 3);
      }

      .show-4 {
        min-width: calc(85% / 4);
        max-width: calc(85% / 4);
      }

      .show-5 {
        min-width: calc(85% / 5);
        max-width: calc(85% / 5);
      }

      .carouselItem {
        padding: 12px;

        .imageWrapper {
          display: flex;
          align-content: center;
          justify-content: center;
          width: 100%;
          min-height: 150px;

          a {
            width: 100%;
          }

          .image {
            width: 100%;
            height: 150px;
            max-width: 230px;
            border-radius: 0.25rem;
            object-fit: cover;
          }
        }

        .descriptionWrapper {
          .itemName {
            font-family: $roboto;
            font-size: 12px;
            text-transform: uppercase;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .itemPriceWrapper {
            font-family: $roboto;
            font-weight: bold;
            display: inline-flex;
            align-items: baseline;
            flex-wrap: nowrap;
            color: $grey-price;
            white-space: nowrap;
            
            .itemPriceInteger {
              font-size: 22px;
            }

            .itemPriceDecimal {
              font-size: 18px;
            }

            .itemPriceSymbol {
              padding-left: 10px;
              font-size: 22px;
            }
          }
        }
      }

      .autoMotoItem {
        .descriptionWrapper {
          padding-top: 5px;
        }

        .descriptionWrapper .itemName {
          min-height: 30px;
        }
      }

      .freeOfferItem {
        .descriptionWrapper .itemPricefreeOffer {
          padding-top: 13px;
          padding-bottom: 3px;
        }

        .descriptionWrapper .itemName {
          text-transform: none;
          color: $grey-price;
          font-family: $open-sans;
          font-size: 13px;
          min-height: 30px;
        }

        .location {
          font-family: $open-sans;
          font-size: 13px;
          color: $grey-mat-focus;

          svg {
            fill: $grey-mat-focus;
            padding-right: 10px;
          }
        }
      }

      .buyNowItem {
        .discount{
          font-family: $roboto;
          font-size: 12px;
          padding: 7px;
          background-color: $green;
          color: $white;
          margin-right: 10px;
        }

        .oldPrice {
          font-family: $roboto;
          font-size: 13px;
          text-decoration: line-through;
        }

        .descriptionWrapper .itemPriceBuyNow {
          padding-top: 5px;
        }

        .descriptionWrapper .itemName {
          text-transform: none;
          color: $grey-price;
          font-family: $open-sans;
          font-size: 13px;
          min-height: 30px;
        }
      }

    }

    .carouselNoContent {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;

      &__text {
        position: absolute;
        font-family: $open-sans;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}
