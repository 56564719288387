@import "src/scss/variables";

.offersImporter {
  .stepperWrapper {
    width: 100%;
    display: none;

    @media all and (min-width: 500px){
      display: block;
    }

    .MuiStepper-root {
      background-color: unset;
    }
  }

  .mobileStepper {
    background-color: unset;
    width: 100%;
    position: sticky;
    top: 0px;
    background-color: $light-grey-bg;
    z-index: 2;

    @media all and (min-width: 500px){
      display: none;
    }

    .MuiButton-root {
      color: unset;
      font-weight: unset;
      padding: 0px;
    }
  }

  .stepContent {
    display: flex;
    width: 100%;
    flex-flow: column wrap;

    padding: 30px 20px;

    .stepTitle {
      font-family: $open-sans;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 30px;

      @media all and (max-width: 499.98px) {
        font-size: 18px;
      }
    }

    .stepDescription {
      font-family: $open-sans;
      font-size: 14px;
      color: $grey-mat-focus;
      text-align: justify;

      margin: 0px 5px 20px 5px;
    }

    .nextStep-button {
      &__wrapper {
        display: flex;
        justify-content: flex-end;
      }
  
      &__button {
        @media all and (max-width: 499.98px) {
          width: 100%;
        }
      }
    }
  }
}
