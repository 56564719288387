@import "src/scss/variables";

.returnRequestPage {

  .content {
    padding: 20px 30px 20px 20px;
    font-family: $open-sans;

    &__title {
      font-family: $open-sans;
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  .productsSelection {
    &__continueButton {
      min-height: 36px;
      min-width: 200px;

      @media all and (max-width: 959.98px) {
        width: 100%;
      }
    }

    &__instructions {
      font-family: $open-sans;
      font-size: 14px;
      text-align: justify;
      

      @media all and (max-width: 959.98px) {
        padding-top: 20px;
      }

      &__title {
        font-weight: bold;
        font-size: 14px;
      }

      &__text {
        text-align: justify;
        color: $dim-gray;
      }
    }

    &__noItemsToReturn {
      justify-content: center;
      align-content: center;
      min-height: 150px;
      font-family: $open-sans;
      font-size: 14px;
    }
  }


  .productsSummary {
    &__submitButton {
      min-height: 36px;
      min-width: 200px;

      @media all and (max-width: 959.98px) {
        width: 100%;
      }

      .loadingSpinner {
        margin-left: 10px;
        color: $white;
      }
    }

    &__conditions {
      font-family: $open-sans;
      font-size: 11px;
      text-align: center;
      
      @media all and (max-width: 959.98px) {
        padding-top: 10px;
      }

      &__text {
        text-align: justify;
        color: $dim-gray;
      }
    }

    &__refundPrice {

      &__title {
        font-family: $open-sans;
        font-size: 14px;
        font-weight: bold;
      }

      &__price {
        margin-left: auto;
        font-family: $roboto;
        font-size: 14px;

        @media all and (max-width: 399.98px) {
          display: none;
        }

        &.mobile {
          @media all and (max-width: 399.98px) {
            display: contents;
          }

          @media all and (min-width: 400px) {
            display: none;
          }
        }
      
      }

      &__warningText {
        font-family: $open-sans;
        font-size: 11px;
        padding-bottom: 10px;
        color: $dim-gray;
      }

    }
  }

  .productItemDetail {
    $image-size: 64px;

    &__checkbox {
      display: flex;
      align-items: center;
      max-height: $image-size;
      padding-right: 10px;
    }

    &__image {
      display: flex;
      align-items: center;
      justify-items: center;
      height: $image-size;
      width: $image-size;

      img {
        margin: auto;
        max-width: $image-size;
        max-height: $image-size;
      }
    }

    &__info {
      font-family: $open-sans;
      flex: 1;
      padding-left: 20px;

      .name {
        font-size: 14px;
        font-weight: bold;
        color: $dark-grey;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
      }

      .state {
        font-size: 13px;
        color: $dark-grey;
        padding-top: 10px;
      }

      .returnTOCLink {
        font-size: 12px;
        padding-top: 10px;
      }

      &__options {
        padding-top: 10px;

        @media all and (max-width: 959.98px) {
          display: none;
        }

        &.mobile {
          @media all and (min-width: 960px) {
            display: none;
          }

          @media all and (max-width: 959.98px) {
            display: contents;
          }
        }
        
        .counterTitle {
          font-size: 13px;
          font-weight: bold;
          color: $dark-grey;
          width: 100%;

          @media all and (max-width: 959.98px) {
            padding-top: 15px;
          }
        }

        .requestReasonInput {
          font-size: 12px;

          .MuiOutlinedInput-root {
            min-height: 120px;
            white-space: pre-wrap; 
            text-align: justify;
            overflow-wrap: break-word;
            word-break: break-word;
            max-width: 100%;

            .MuiOutlinedInput-input {
              height: 100% !important;
            }
          }

          .MuiFormHelperText-root {
            margin-left: auto;
          }
        }

        .requestReasonTitle {
          font-size: 13px;
          font-weight: bold;
          color: $dark-grey;
          width: 100%;

          @media all and (max-width: 959.98px) {
            padding-top: 15px;
          }
        }

        .requestReason {
          display: flex;
          margin: 0;
          font-size: 12px;
          white-space: pre-wrap; 
          text-align: justify;
          overflow-wrap: break-word;
          word-break: break-word;
          max-width: 100%;
        }

      }

      .price {
        font-family: $roboto;
        font-size: 14px;

        &__mobile {
          padding-top: 10px;

          @media all and (min-width: 500px) {
            display: none;
          }
        }

        &__desktop {
          @media all and (max-width: 499.98px) {
            display: none;
          }
        }
      }
    }

    .divider {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }

  .skeleton {
    margin: 5px;
    margin-bottom: 0px;

    .rectangle {
      width: 64px;
      height: 42px !important;
    }

    .lines-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 20px;
      flex: 1;

      &__line {
        width: 20%;
        height: 16px !important;
        margin-bottom: 10px !important;

        &:first-child {
          width: 100%;
        }

        @media all and  (max-width: 959.98px) {
            margin-bottom: 5px !important;
            width: 30%;
        }

        @media all and  (max-width: 499.98px) {
          width: 60%;
        }

        &.mobile {
          @media all and (min-width: 960px) {
            display: none;
          }
        }
      }
    }

    .divider {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }

  .noOrder {
    background-color: $white;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 100px 20px;
    border-radius: 4px;
    position: relative;
  
    &-container {
      width: 100%;
      padding-top: 0px;
    }
  
    @media all and (max-width: 499.98px) {
      padding: 50px 10px;
      h3 {
        font-size: 14px;
        line-height: 18px;
      }
      h5 {
        font-size: 12px;
        line-height: 14px;
      }
    }
  
    .dark {
      color: $dark-grey;
    }
  
    .mb-3 {
      margin-bottom: 3px;
    }
  
    .backgroundImage {
      position: absolute;
      @media all and (max-width: 499.98px) {
        img {
          height: 100px;
        }
      }
    }
  }
}