@import "../../../scss/_variables";

.watchListPage {
  max-width: 1400px;
  margin: 0px auto !important;
  padding: 0px 10px 30px 10px;

  @media all and (max-width: 499.98px) {
    padding-right: 0px;
    padding-left: 0px;
  }

  .title {
    align-items: baseline;

    h2 {
      margin-bottom: 20px;
    }

    &__count {
      font-family: $open-sans;
      font-size: 14px;
      margin-left: 5px;
    }

    @media all and (max-width: 499.98px) {
      h2 {
        margin-bottom: 10px;
        padding-left: 10px;
      }
    }

    .skeleton {
      width: 300px;
      height: 42px;
      margin-bottom: 20px;

      @media all and (max-width: 799.98px) {
        margin-bottom: 10px;
        height: 36px;
      }
      
      @media all and (max-width: 499.98px) {
        margin-left: 10px;
        height: 32px;
        width: 70%;
      }    
    }    
  }

  .MuiGrid-spacing-xs-1 > .MuiGrid-item {
    @media all and (max-width: 499.98px) {
      padding-right: 0px;
      padding-left: 0px;
    }
  }

  .emptyList {
    background-color: $white;
    min-height: 300px;
    border-radius: 4px;
    justify-content: center;
    align-content: center;

    h3 {
      font-family: $open-sans;
      font-size: 14px;
      text-align: center;
    }

  }

  .listItems {
    background-color: $white;
    border-radius: 4px;
    padding: 4px;

    @media all and (max-width: 499.98px) {
      padding: 0px;
      background-color: transparent;
    }
  }

}
