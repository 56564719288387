.conversation-item {
  display: flex;
  align-items: flex-start;
  padding: 10px 10px 10px 15px;
  cursor: pointer;

  &--selected {
    background-color: $white-snow;
    border-left: 3px solid $red-pastel;
    padding-left: 12px;
    cursor: default;
  }

  &--not-seen {
    background-color: rgba($red-pastel, 0.15);
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background-color: $white;
    border-radius: 50%;
    border: 1px solid $grey;
    width: 44px;
    height: 44px;

    svg {
      max-width: 22px;
      max-height: 25px;
      color: $grey;
    }
  }

  &__description {
    flex-grow: 1;
    padding: 5px 0 5px 10px;
    min-width: 0;
  }

  &__sender {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-family: $open-sans;
  }

  &__time {
    margin-left: 5px;
    color: $light-grey;
    font-size: 12px;
  }

  &__message {
    padding-top: 5px;
    color: $light-grey;
    font-family: $open-sans;
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: '...';
  }
}
