@import "../../../../../scss/variables";


.roboto {
  font-family: $roboto;
}

.bold {
  font-weight: bold !important;
}

.pr-15 {
  padding-right: 15px;
}

.pl-30 {
  padding-left: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-50 {
  width: 50%;
}

.mt-5 {
  margin-top: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.pt-5 {
  padding-top: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.titleWrapper {
  @media all and (max-width: 499.98px) {
    padding: 10px;
    min-height: 35px;
  }
}

.settings {
    padding: 20px 30px 20px 20px;

    @media all and (min-width: 500px) and (max-width: 599.98px) {
      .MuiGrid-root {
        max-width: 100%;
        flex-basis: 100%;
      }
    }

    @media all and (max-width: 499.98px) {
      align-items: flex-start;
    }

    .MuiInputBase-input {
      padding: 10.5px 30px 10.5px 14px;
    }

    .MuiFormControl-root {
      padding-right: 10px !important;
      //min-width: 320px;

      @media all and (max-width: 499.98px) {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 10px;
        padding-right: 0px !important;

        .MuiInputLabel-shrink {
          transform: translate(14px, 1px) scale(0.75) !important;
        }
      }
    }

  .orderStatusSelect {
    min-width: 160px;

    option:disabled {
      color: rgba(0, 0, 0, 0.26) !important;
    }
  }
    
  .confirmButton {
    color: $dark-grey !important;
    background-color: $white-snow;
    border: 1px solid $grey-mat-unfocus;
    border-radius: 4px;
    font-weight: normal !important;
    font-size: 14px;
    height: 36.75px;
    @media all and (max-width: 499.98px) {
      width: 100%;
    }
    
    &:disabled {
      color: rgba(0, 0, 0, 0.26) !important;
    }
  }

  .contactBuyerWrapper{
    margin: auto;

    @media all and (max-width: 599.98px) {
      justify-content: center;
    }

    .contactBuyer {
        display: inline-flex;
        align-self: flex-end;

        @media all and (max-width: 599.98px) {
          padding-top: 15px;
        }
    
        svg {
          width: 25px;
          color: $grey;
          margin-right: 10px;
          max-height: 25px;
        }

        a {
          color: $cyan-pastel;
          font-family: $open-sans;
          text-decoration: none;
          align-self: center;
        }
      }
  }
}
  
.buyerInfo {
  display: flex;

  h4 {
    font-family: $open-sans;
    padding-right: 4px;

    @media all and (max-width: 499.98px) {
      font-size: 11px;
    }
  }
}