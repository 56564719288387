@import "src/scss/variables";

.AddedToCart {
  max-width: 1400px;
  margin: 0 auto !important;
  flex-grow: 1;
  @media all and (max-width: 499.98px) {
    display: none !important;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .background {
    background-color: $white;
  }

  .ItemAddedToCart {
    padding: 10px 30px;
    @media all and (max-width: 790px) {
      flex-direction: column;
      width: auto;
      margin: 0 auto;
    }

    .itemInfoHolder {
      padding-right: 20px;
      @media all and (max-width: 790px) {
        padding-right: 0px;
        justify-content: center;
        width: auto !important;
      }

      .checkIconHolder {
        width: 27px;
        height: 27px;
        background-color: $green-pastel-dark;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 25px;

        svg {
          color: $white
        }
      }

      .notAvailableIconHolder {
        margin-right: 25px;

        path {
          fill: $red;
        }

        text {
          fill: $white;
        }
      }

      .imageHolder {
        border: 1px solid $green-pastel-dark;
        margin-right: 15px;
        @media all and (max-width: 499.98px) {
          margin-top: 10px;
          margin-bottom: 10px;
        }

        img {
          max-height: 100px;
          height: auto;
          width: auto;
        }
      }

      .addToCartHolder {
        font-size: 20px;
        line-height: 24px;
        font-family: $roboto;
        color: $green-pastel-dark;
        font-weight: 600;
      }
    }

    .buttonsHolder {
      @media all and (max-width: 790px) {
        justify-content: space-between;
        margin-top: 10px;
      }

      .cartBtn {
        background: $white-snow !important;
        border: 1px solid $grey-mat-unfocus;
        font-size: 15px;
        color: $dark-grey;
        margin-right: 20px;
      }

      .finishOrderBtn {
        font-size: 15px;
        color: $white;
      }
    }
  }

  .ItemNotAdded {
    .addToCartHolder {
      color: $red !important;
    }

    .imageHolder {
      border-color: $red !important;
    }
  }

  .SnackbarContent {
    padding: 20px 30px;
    min-height: 80px;
    flex-wrap: nowrap;

    a {
      text-transform: unset;
      text-decoration: none;
      color: $cyan-pastel;

      &:hover {
        color: $cyan-focus;
      }
    }

    .ImageContent {
      path {
        fill: $gold-digger;
      }

      text {
        fill: $white;
      }
    }

    .TextContent {
      margin-left: 30px;

      p {
        font: normal 14px/16px "OpenSans", sans-serif;
        margin: 0
      }

      .title {
        font-size: 20px;
        margin-bottom: 3px;
        color: $gold-digger;
      }
    }
  }
}