// Styles
// Import Main styles for this application
@import "./scss/style.scss";

.loader {
  height: calc(100vh - 324px);
  position: relative;
  padding: 12px;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;

  &__progress-bar {
    height: 2px;
    background-color: $red-pastel;
    animation: expandWidth 2s ease-in-out infinite;
    animation-fill-mode: both;
    -webkit-animation: expandWidth 2s ease-in-out infinite;
    -webkit-animation-fill-mode: both;
    -moz-animation: expandWidth 2s ease-in-out infinite;
    -moz-animation-fill-mode: both;
  }

  @keyframes expandWidth {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  @-webkit-keyframes expandWidth {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  @-moz-keyframes expandWidth {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
}

.MuiCollapse-wrapper .MuiCollapse-wrapperInner {
  @media all and (max-width: 499.98px) {
    [class*="SnackbarItem-contentRoot-"] {
      padding-right: 24px;
    }
  }
}

.MuiCollapse-wrapper[class*="jss"], .MuiCollapse-wrapper[class*="SnackbarItem-collapseWrapper-"] {
  @media all and (max-width: 499.98px) {
    width: 100%;

    .MuiCollapse-wrapperInner {
      width: 100%;
    }
  }
}

.Toastify__toast {
  border-radius: 4px !important;

  &--success {
    background-color: $green !important;
  }

  &--warn {
    background-color: $yellow-pastel !important;
  }

  &--error {
    background-color: $red !important;
  }

  &-body {
    text-align: center;
  }

  .Toastify__close-button {
    margin-left: 10px;
  }
}

.close-snack {
  width: 20px;
  height: 20px;

  .MuiIconButton-root {
    top: 0;
    right: 0;
    padding: 6px;
    color: $white;
    position: absolute;

    @media all and (max-width: 499.98px) {
      padding: 6px 4px;
    }

    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }
  }  
}

.register__card,
.swal2-content,
.passwordChange,
.filters {
  .MuiInputLabel-formControl:not(.Mui-focused) {
    top: -2px;
  }
}

.swal2-close:focus {
  box-shadow: none;
}
