@import "src/scss/variables";

.message-item {
  position: relative;
  align-items: center;
  display: flex;
  width: 100%;
  padding-top: 2px;

  &--with-time {
    padding-top: 5px;
  }

  &--with-date {
    padding-top: 15px;
  }

  &--current {
    justify-content: flex-end;

    .message-item__time {
      align-self: flex-end;
    }

    .message-item__seen {
      align-self: flex-start;
    }

    .message-item__card {
      background-color: $white-snow;
      border-color: $light-grey-bg;
      align-self: flex-end;
    }
  }

  &--other {
    justify-content: flex-start;

    .message-item__time {
      align-self: flex-start;
    }

    .message-item__seen {
      align-self: flex-end;
    }

    .message-item__card {
      background-color: $grey-mat-unfocus;
      border-color: $grey;
      align-self: flex-start;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 60%;

    @media all and (max-width: 499.98px) {
      max-width: 90%;
    }
  }

  &__date {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    color: $dark-grey;
    font-family: $open-sans;
    font-size: 12px;
    font-weight: bold;
  }

  &__time,
  &__seen {
    display: flex;
    color: $dark-grey;
    font-family: $open-sans;
    font-size: 12px;
  }

  &__card {
    padding: 10px;
    margin: 0;
    font-family: $open-sans;
    font-size: 14px;
    text-align: justify;
    border-radius: 8px;
  }
}

.offer-message {
  display: flex;

  &__image {
    width: 100px;
    max-width: 35%;
    max-height: 60px;
    object-fit: cover;
    object-position: top;
    margin-right: 8px;
  }

  &__name {
    margin-bottom: 5px;
  }

  &__link {
    text-decoration: none;
    color: $cyan-pastel;

    &:hover {
      color: $medium-aquamarine;
    }
  }
}

.message-image {
  width: 120px;

  &__image {
    width: 100%;
  }

  &__download {
    display: flex;
    align-items: center;
    margin-top: 4px !important;
    text-transform: none !important;
    text-decoration: underline !important;
    color: $black !important;
    font-size: 14px !important;

    &-icon {
      margin-right: 4px;
    }
  }
}
