@import "src/scss/variables";


.invoices {

    .roboto {
      font-family: $roboto;
    }
  
    .bold {
      font-weight: bold !important;
    }
  
    .pr-15 {
      padding-right: 15px;
    }
  
    .pl-30 {
      padding-left: 30px !important;
    }
  
    .pr-20 {
      padding-right: 20px;
    }

    .pr-30 {
      padding-right: 30px !important;
    }
  
    .w-10 {
      width: 10%;
    }
    .w-20 {
      width: 20%;
    }
  
    .w-30 {
      width: 30%;
    }
  
    .w-50 {
      width: 50%;
    }
  
    .mt-5 {
      margin-top: 5px;
    }
  
    .mb-10 {
      margin-bottom: 10px;
    }
  
    .invoicesFilters {
      
      .filters {
        padding: 20px 30px 20px 20px;
    
        @media all and (max-width: 499.98px) {
          flex-direction: column;
          align-items: flex-start;
        }
    
        .MuiInputBase-input {
          padding: 10.5px 30px 10.5px 14px;
        }
    
        .MuiFormControl-root {
          padding-right: 10px !important;
          //min-width: 320px;
    
          @media all and (max-width: 499.98px) {
            width: 100%;
            margin-top: 15px;
            margin-bottom: 10px;
            padding-right: 0px !important;
    
            .MuiInputLabel-shrink {
              transform: translate(14px, 1px) scale(0.75) !important;
            }
          }
        }

        .filterInput {
          margin-bottom: 10px;
          width: 250px;
        }
      }
    }

    .invoicesTable {
      padding-top: 7px;
    
      .dateCell {
        white-space: pre;
      }
    }

    .invoicesOverview {
      padding: 20px 30px 20px 20px;
      font-family: $open-sans;
      font-size: 15px;

      .invoicesOverviewItem {
        display: inline-flex;
        padding-top: 10px;
        padding-bottom: 10px;

        h6 {
          font-weight: bold;
          font-size: 14px;
        }

        .price {
          font-family: $roboto;
          font-size: 14px;
        }
      }
    }
  }