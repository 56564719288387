@import "src/scss/variables";

.OrderAddReview {
  max-width: 960px;
  margin: 0 auto;
  @media all and (max-width: 499.98px) {
    padding-bottom: 5px;
  }

  .h-100 {
    height: 100%;
    @media all and (max-width: 499.98px) {
      height: auto;
    }
  }

  .background {
    background-color: $white;
    padding: 20px 30px;
    @media all and (max-width: 499.98px) {
      padding: 10px;
    }
  }

  .mt-10 {
    margin-top: 10px;
    @media all and (max-width: 499.98px) {
      margin-top: 5px;
    }
  }

  .title {
    margin-bottom: 5px;
    padding-left: 30px;
    @media all and (max-width: 499.98px) {
      font-size: 14px !important;
      padding-left: 10px;
      font-weight: 600;
    }
  }

  h5, h3, .MuiTypography-root, .link {
    @media all and (max-width: 499.98px) {
      font-size: 13px !important;
    }
  }

  .MuiTypography-root {
    font-size: 14px;
    color: $dark-grey;
    padding-right: 10px;
  }

  .MuiInputBase-input {
    @media all and (max-width: 499.98px) {
      padding: 4px !important;
    }
  }

  .dark {
    color: $dark-grey;
  }

  .MuiDivider-root {
    width: 100%;
    background-color: $grey-mat-unfocus;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .MuiRating-root {
    color: $gold-digger;
  }

  .MuiRating-iconEmpty {
    color: $gold-digger;
  }

  .ratingHolder {
    margin-top: 15px;
    margin-bottom: 30px;
    @media all and (max-width: 350px) {

      &.safari {
        flex-direction: initial;
      }
      .rating {
        max-width: 100%;
        flex-basis: 100%;
      }
      .rating:first-child {
        margin-bottom: 10px;
      }
    }
  }

  .rating {
    cursor: pointer;
    font-size: 14px;
    font-family: $open-sans;
    color: $dark-grey;
    @media all and (max-width: 499.98px) {
      font-size: 13px;
    }

    .iconHolder {
      padding-right: 20px;

      &.notRecommend {
        svg {
          transform: rotate(180deg);
        }
      }

      @media all and (max-width: 499.98px) {
        padding-right: 15px;
      }
    }

    &.active {
      color: $green !important;

      &.dislike {
        color: $red !important;
      }
    }

    &.active &.dislike {
      color: $red !important;
    }

  }

  .starRatingHolder {
    margin-top: 15px;
    margin-bottom: 25px;

    .MuiBox-root {
      padding-left: 0;
      margin-left: 0;
      margin-bottom: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media all and (max-width: 499.98px) {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
      }
    }
  }

  .noteHolder {
    .MuiInputBase-root {
      height: auto;
      min-height: 65px;
      color: #707070;
      font-size: 14px;
      padding-top: 10px;
      padding-bottom: 10px;
      @media all and (max-width: 499.98px) {
        font-size: 13px;
      }

      .MuiInputBase-input {
        padding: 0;
      }
    }

    .MuiFormHelperText-root {
      text-align: right;
      margin-right: 0 !important;
      font-size: 13px;
      color: $grey-mat-focus;
    }

    .MuiFormLabel-root {
      padding-right: 5px;
      background-color: $white;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $grey-mat-unfocus;
    }

    .Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: $grey-light-focus;
      }
    }

    &.headline {
      .MuiInputBase-root {
        min-height: 36px;
        padding: 10px;
      }

      .MuiInputBase-input {
        padding: 0px 4px;
      }
    }
  }

  .AddReviewItems {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    .infoHolder {
      padding-right: 30px;
      justify-content: center;
      align-content: flex-start;

      @media all and (min-width: 500px) {
        max-width: 190px;
        flex-basis: 190px;
      }

      .photo {
        max-height: 125px;

        &__wrapper{
          display: flex;
          justify-content: center;
          flex: 1;

          @media all and (min-width: 500px) {
            flex: 1 1 100%;
          }
        }
      }

      @media all and (max-width: 499.98px) {
        padding-right: 0px;
        justify-content: flex-start;
        margin-bottom: 10px;
      }

      img {
        max-width: 120px;
        max-height: 120px;
        @media all and (max-width: 499.98px) {
          max-width: 80px;
          padding-right: 15px;
        }
      }

      .mobileViewHolder {
        margin-top: 20px;

        @media all and (max-width: 499.98px) {
          margin-top: 0px;
          flex: 3 1;
        }
        
        .offerName {
          min-height: 20px;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &__wrapper {
            display: grid;

            @media all and (max-width: 499.98px) {
              justify-content: flex-start;
            }
          }
        }
      }
    }

    .customReviewHolder {
      max-width: calc(100% - 190px);
      flex-basis: calc(100% - 190px);
      @media all and (max-width: 499.98px) {
        max-width: 100%;
        flex-basis: 100%;
      }

      .ratingProperties{

        .MuiInputBase-root {
          height: auto;
          min-height: 65px;
          color: #707070;
          font-size: 14px;
          padding-top: 10px;
          padding-bottom: 10px;
          @media all and (max-width: 499.98px) {
            font-size: 13px;
          }
    
          .MuiInputBase-input {
            padding: 0;
          }
        }

        @media all and (max-width: 959.98px) {
          padding: 20px 0px 0px 0px;
        }

        &.negative {
          @media all and (min-width: 960px) {
            padding-left: 17px;
          }
        }

        &.positive {
          @media all and (min-width: 960px) {
            svg {
              margin-left: 0px;
            }
          }
        }

        &__wrapper {
          padding: 10px 0px;

          @media all and (max-width: 959.98px) {
            padding-top: 0px;
          }
        }

        &__line {
          padding: 10px 0px 0px 0px;
        }

        &__input {
          flex: 1;
        
          .MuiInputBase-root {
            min-height: 36px;
            height: unset;
          }

          .MuiOutlinedInput-multiline{
            padding: 0px 10px;
          }
        }

        svg {
          margin-right: 10px;
        }
      }
    }

    .MuiRating-root {
      margin-bottom: 10px;
    }
  }

  .ratingSendButton {
    .MuiButton-root {
      color: $white;
      margin: 5px 0;
      font-weight: bold;
      min-width: 240px;
    }

    &__spinner {
      color: $white;
      margin-left: 10px;
    }
  }

  .noOffer {
    font-family: $open-sans;
    font-size: 14px;

    &__wrapper {
      background-color: $white;
      border-radius: 4px;
      min-height: 250px;

      @media all and (max-width: 499.98px) {
        border-radius: 0px;
      }
    }
  }
}