@import "src/scss/variables";

.OrderReviewThank {
  max-width: 960px;
  margin: 0 auto;

  .mt-5 {
    margin-top: 5px
  }

  .mb-15 {
    margin-bottom: 15px;
  }

  .dark {
    color: $dark-grey;
  }

  h3 {
    @media all and (max-width: 499.98px) {
      font-size: 14px;
    }
  }

  h5, .link {
    @media all and (max-width: 499.98px) {
      font-size: 13px;
    }
  }

  .title {
    margin-bottom: 5px;
    padding-left: 30px;
    @media all and (max-width: 499.98px) {
      font-size: 14px !important;
      padding-left: 10px;
      font-weight: 600;
    }
  }

  .background {
    background-color: $white;
    padding: 85px 10px;
    @media all and (max-width: 499.98px) {
      padding: 40px 10px;
    }
  }
}