@import "src/scss/variables";

.summary {
  width: 370px;
  padding: 30px;
  margin: 5px;
  background-color: $white;
  border-radius: 4px;
  box-sizing: border-box;

  @media all and (max-width: $media-lg) {
    width: 100%;
    order: 1;
  }

  @media all and (max-width: $media-xs) {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }

  &__checkedout-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: transparent;
    z-index: 1;
  }

  &__details {
    @media all and (max-width: $media-xs) {
      background-color: $white;
      padding: 10px;
    }
  }

  &__action {
    @media all and (max-width: $media-xs) {
      box-shadow: 0px -1px 5px 0px rgba(0,0,0,0.1);
      position: fixed;
      bottom: 0;
      background-color: $white;
      padding: 10px 50px;
      z-index: 300;
    }
  }

  &__name {
    font-weight: bold;
    margin-bottom: 5px;

    @media all and (max-width: $media-xs) {
      margin: 0 0 3px;
      padding-left: 10px !important;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;

    &--loading {
      display: block;
      height: 13px;
      width: 60px;

      &-sm {
        display: block;
        height: 13px;
        width: 40px;
      }
    }

    .text {
      font-size: 13px;
    }
  }

  &__item-divider {
    width: 100%;
    margin: 10px 0 5px;

    @media all and (max-width: $media-xs) {
      margin: 5px 0;
    }
  }

  &__total {
    display: flex;
    justify-content: space-between;

    &--loading {
      height: 17px;
      width: 75px;
      margin: 1px 0;

      @media all and (max-width: $media-xs) {
        height: 13px;
        margin: 2px 0;
      }
    }

    .text {
      font-size: 17px;
      font-weight: bold;

      @media all and (max-width: $media-xs) {
        font-size: 13px;
      }
    }
  }

  &__confirm {
    width: 100%;
    margin: 30px 0 20px;
    color: $white;

    &:disabled {
      color: rgba($white, 0.7);
    }

    @media all and (max-width: $media-xs) {
      margin-top: 0;
    }
  }

  &__toc {
    text-align: center;
    font-size: 12px;
    line-height: 16px;
  }

  &__info {
    font-size: 12px;
    line-height: 16px;
    margin-top: 40px;

    @media all and (max-width: $media-xs) {
      display: none;
    }
  }
}
