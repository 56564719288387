@import "src/scss/variables";

.OrderCreated {
  max-width: 960px;
  margin: 0 auto;

  .mb-20 {
    margin-bottom: 20px;
  }

  .dark {
    color: $dark-grey;
  }

  h3 {
    @media all and (max-width: 499.98px) {
      font-size: 14px;
    }
  }

  h5, .link {
    @media all and (max-width: 499.98px) {
      font-size: 13px;
    }
  }

  .title {
    margin-bottom: 5px;
    padding-left: 30px;
    @media all and (max-width: 499.98px) {
      font-size: 14px !important;
      padding-left: 10px;
      font-weight: 600;
    }
  }

  .backgroundIcon {
    position: absolute;
    opacity: 0.10;

    svg {
      color: #70B22D;
      width: 180px;
      height: 180px;
      @media all and (max-width: 499.98px) {
        width: 90px;
        height: 90px;
      }
    }
  }

  .background {
    background-color: $white;
    padding: 85px 10px;
    position: relative;
    @media all and (max-width: 499.98px) {
      padding: 40px 10px;
    }
  }

  .backgroundCredits {
    margin-top: 10px;
    background-color: $white;
    padding: 30px 10px;

    h5{
      @media all and (max-width: 499.98px) {
       text-align: center;
      }
    }
  }

  .giftIconHolder {
    margin-right: 20px;
    @media all and (max-width: 499.98px) {
      margin-right: 0px;
    }

    svg {
      width: 35px;
      height: auto;
      color: $red-pastel;
    }
  }
}