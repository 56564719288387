@import "../../../../scss/variables";

.filters {

  .customPriceRange {
    margin-top: 6px;
    justify-content: flex-start;

    @media all and (min-width: 960px) {
      justify-content: space-between;
    }

    .priceInput {
      width: calc(40% - 2px);
      margin-right: 8px;

      .MuiInputBase-root {
        .MuiInputBase-input {
          padding: 6px 0 6px 10px;
        }
      }

      @media all and (max-width: 499.98px) {
        width: calc(50% - 40px);
      }

      @media all and (min-width: 960px) {
        max-width: 90px;
        width: calc(40% - 2px);
        margin-right: 0;
      }
    }

    .searchButton {
      @media all and (min-width: 960px) {
        margin-left: 4px;
        padding: 6px 11px;
        min-width: unset;
      }
    }
  }
}