@import "../../../../scss/variables";
@import "Labels/LabelsAutomoto";
.searchResultsHolder {
    .mr-5 {
        margin-right: 5px;
    }
    .mb-5 {
        margin-bottom: 5px;
    }
    .mb-10 {
        margin-bottom: 10px;
    }
    .mt-5 {
        margin-top: 5px;
    }
    .mt-10 {
        margin-top: 10px;
    }
    .mt-20 {
        margin-top: 20px;
    }
    .pr-10 {
        padding-right: 10px;
    }
    .pr-40 {
        padding-right: 40px;
    }
    .dark {
        color: $dark-grey;
    }
    .primary {
        color: $red-pastel;
    }
    .white {
        color: $white;
    }
    .auto {
        width: auto;
    }
    .ml-20 {
        margin-left: 20px;
    }
    .searchHeader {
        align-items: flex-end;
        padding-right: 10px;
        margin-bottom: 10px;
        justify-content: space-between;
        @media (min-width: 960px) and (max-width: 1300px) {
            justify-content: flex-end;
        }
        @media all and (max-width: 960px) {
            .resultsNum {
                width: auto;
                margin-top: 10px;
            }
            .MuiButtonBase-root,
            .MuiInputBase-root {
                height: 30px;
            }
        }
        @media (min-width: 500px) and (max-width: 649.98px) {
            justify-content: flex-end;
        }
        @media all and (max-width: 499.98px) {
            display: none;
        }
        .resultsNum {
            .primary {
                width: calc(100% - 224px);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: inline-block;
                vertical-align: bottom;
            }
            @media (min-width: 960px) and (max-width: 1300px) {
                display: none;
            }
            @media all and (max-width: 649.98px) {
                display: none;
            }
            h5 {
                letter-spacing: 0.13px;
                color: $dark-grey;
            }
        }
        .sellerInfoHolder {
            padding-left: 2px;
            align-self: center;
        }
        .searchChangeViewHolder {
            justify-content: flex-end;
            min-height: 36px;
            @media all and (max-width: 499.98px) {
                justify-content: flex-start;
            }
            .search {
                width: 200px;
                margin-right: 100px;
                @media all and (max-width: 1065px) {
                    margin-right: 20px;
                }
                @media all and (max-width: 1199px) {
                    margin-right: 0;
                }
                .MuiFormControl-root {
                    width: 200px;
                    @media all and (max-width: 499.98px) {
                        margin: 15px 0;
                    }
                }
                .MuiInputLabel-outlined {
                    color: $light-grey;
                }
                .MuiInputBase-input {
                    color: $light-grey;
                }
                .MuiSvgIcon-root {
                    color: $grey-mat-focus;
                    width: 1.2em;
                    height: 1.2em;
                    right: 0;
                    position: absolute;
                    pointer-events: none;
                }
                .MuiSelect-select:focus {
                    background-color: transparent;
                }
            }
            .changeView {
                width: auto;
                align-items: center;
                display: flex;
                @media all and (max-width: 1199px) {
                    display: none;
                }
                .cardLayoutImage {
                    margin-right: 20px;
                    cursor: pointer;
                }
                .listLayoutImage {
                    cursor: pointer;
                }
            }
        }
    }
    .resultsHolder {
        align-items: flex-start;
        @media all and (max-width: 499.98px) {
            margin: 2px 0 0;
        }
        &.cardView {
            align-items: stretch;

            .imageHolder {
                height: 150px;
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: flex-start;

                .image {
                    width: 230px;
                    height: 150px;
                    max-width: calc(100% - 20px);
                    object-fit: cover;
                    border-radius: 0.25rem;
                }
            }
        }
        // Odlišnosti oproti cardView
        &.listView {
            .resultsItems {
                flex-direction: row;
                height: 100%;
                .imageHolder {
                    flex-grow: 0;
                    max-width: 20%;
                    flex-basis: 20%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media all and (max-width: 1150px) {
                        max-width: 30%;
                        flex-basis: 30%;
                    }
                    @media all and (max-width: 499.98px) {
                        max-width: 143px;
                        flex-basis: 143px;
                    }

                    &.automoto {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    .image {
                        width: 230px;
                        height: 150px;
                        max-width: calc(100% - 30px);
                        object-fit: cover;
                        border-radius: 0.25rem;

                        @media all and (max-width: 960px) {
                            max-width: calc(100% - 15px);
                        }
                    }
                }
                .holder {
                    flex-grow: 0;
                    max-width: 80%;
                    flex-basis: 80%;
                    @media all and (max-width: 1150px) {
                        max-width: 70%;
                        flex-basis: 70%;
                    }
                    @media all and (max-width: 499.98px) {
                        max-width: calc(100% - 143px);
                        flex-basis: calc(100% - 143px);
                        .option {
                            margin-bottom: 0;
                        }
                    }
                    .title {
                        margin-top: 0;
                    }
                    .numOfBuy {
                        @media all and (max-width: 499.98px) {
                            display: none;
                        }
                    }
                    .seller {
                        @media all and (max-width: 499.98px) {
                            flex-direction: column;
                            align-items: flex-start;
                        }
                        .ratings {
                            @media all and (max-width: 499.98px) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .resultsItems {
            background-color: $white;
            padding: 20px;
            border-radius: 4px;
            position: relative;
            @media all and (max-width: 1199px) {
                flex-direction: row !important;
                .imageHolder {
                    max-width: 30% !important;
                    flex-basis: 30% !important;
                    width: 30% !important;
                    align-self: flex-start;

                    a {
                        width: 100%;
                    }

                    .image {
                        width: 230px;
                        max-width: calc(100% - 30px) !important;
                        object-fit: cover;
                        border-radius: 0.25rem;
                        object-position: center;
                    }
                }
                .holder {
                    max-width: 70% !important;
                    flex-basis: 70% !important;
                }
            }
            @media all and (max-width: 930px) {
                .imageHolder {
                    .image {
                        max-width: calc(100% - 10px) !important;
                    }
                }
            }
            @media all and (max-width: 499.98px) {
                padding: 10px;
                .imageHolder {
                    .image {
                        min-width: unset !important;
                    }
                }
            }
            &.marketplaceItem {
                @media all and (max-width: 1199px) {
                    justify-content: space-between;
                    align-items: center;
                    .imageHolder {
                        flex-direction: column-reverse;
                        width: unset;
                        max-width: 30%;
                    }
                }
                .holder {
                    @media all and (max-width: 1199px) and (min-width: 967px) {
                        flex: 0 0 calc(100% - 250px);
                        max-width: calc(100% - 250px);
                    }
                }
            }
            .advertisement {
                cursor: pointer;
                margin-bottom: 2px;
                align-self: flex-start;
                @media all and (max-width: 499.98px) {
                    margin-bottom: 0;
                }

                @media all and (max-width: 1199.98px) {
                    display: none;
                }

                &.automoto {
                    h6 {
                        @media all and (max-width: 499.98px) {
                            font-size: 10px;
                            margin-bottom: 0;
                        }
                    }
                }
                .infoCircleIcon {
                    margin-left: 5px;
                    svg {
                        width: 11px;
                        height: 11px;
                        color: $grey;
                    }
                }
                h6 {
                    color: $grey;
                    display: flex;
                    align-items: center;
                    max-width: fit-content;
                }

                &.mobile {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    min-height: 23px;
                    border: 0px;

                    @media all and (max-width: 499.98px) {
                        h6 {
                            font-size: 10px;
                        }
                    }

                    @media all and (min-width: 1200px) {
                        display: none;
                    }

                }
            }
            .image {
                width: auto;
            }
            .holder-name {
                min-height: 36px;
                display: flex;
            }
            .title {
                height: 32px;
                font-size: 15px;
                line-height: 16px;
                font-family: $open-sans;
                margin-top: 10px;
                margin-bottom: 5px;
                text-decoration: none;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                flex: 1;

                @media all and (max-width: 499.98px) {
                    max-width: 30ch;
                }

                &:hover {
                    color: $cyan-focus !important;
                }
            }
            .seller {
                color: $light-grey;
                font-size: 13px;
                line-height: 18px;
                font-family: $open-sans;
                display: flex;
                align-items: flex-start;
                span {
                    height: 18px;
                }
                .ratingHolder {
                    display: flex;
                    align-items: center;
                    height: 18px;
                    a {
                        text-decoration: none;
                        color: $cyan-pastel;
                        &:hover {
                            color: $cyan-focus;
                        }
                    }
                }
                .handShakeIcon {
                    margin: 0 5px;
                    svg {
                        width: 22px;
                        height: auto;
                        color: $red-pastel;
                    }
                }
                .userIcon {
                    margin: 0 5px;
                    svg {
                        width: 12px;
                        height: auto;
                        color: $light-grey;
                    }
                }
                .nickname {
                    margin-right: 15px;
                    a {
                        text-decoration: none;
                        color: $light-grey;
                    }
                }
                .MuiRating-root {
                    font-size: 18px;
                    color: #F6CA6E;
                    margin-right: 15px;
                }
                .MuiRating-iconEmpty {
                    color: #F6CA6E;
                }
                .ratings {
                    color: $cyan-pastel;
                }
            }
            .category {
                margin: 5px 0;
                @media all and (max-width: 499.98px) {
                    display: none;
                }
                a {
                    text-decoration: none;
                    color: $cyan;
                }
                h3 {
                    color: $cyan;
                    cursor: pointer;
                    a {
                        text-decoration: none;
                        color: $cyan;
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .status {
                margin-bottom: 5px;
                h5 {
                    color: $dark-grey;
                }
            }
            .price {
                font-family: $roboto;
                font-weight: bold;
                font-size: 22px;
                color: $dark-grey;
                @media all and (max-width: 499.98px) {
                    font-size: 18px;
                }
                .coins {
                    font-size: 16px;
                    @media all and (max-width: 499.98px) {
                        font-size: 14px;
                    }
                }
            }
            .transport {
                h5 {
                    color: $light-grey;
                }
                .free {
                    color: $green;
                }
            }
            .numOfBuy {
                h5 {
                    color: $grey;
                }
            }
            ///// AUTOMOTO
            &.automoto {
                .noWrap {
                    white-space: nowrap;
                }
                .advertisement {
                    margin-bottom: 0;
                }
                h6 {
                    color: $dark-grey;
                    &.advertisement {
                        color: $grey;
                        margin-bottom: 0;
                        .infoCircleIcon {
                            display: flex;
                        }
                    }
                }
                h5 {
                    @media all and (max-width: 499.98px) {
                        font-size: 12px;
                    }
                }
                h4 {
                    @media all and (max-width: 499.98px) {
                        font-size: 11px;
                    }
                }
                .MuiRating-sizeSmall {
                    font-size: 23px;
                }
                .MuiRating-root {
                    color: $blue-pastel;
                }
                .MuiRating-iconEmpty {
                    color: $blue-pastel;
                }
                @media all and (max-width: 1199px) {
                    &.mobile {
                        display: flex;
                    }
                    &.desktop {
                        display: none;
                    }
                }
                .imageHolder {
                    &.cardView {
                        max-width: 50% !important;
                        flex-basis: 50% !important;
                    }
                    @media all and (max-width: 1200px) {
                        max-width: 180px;
                        flex-basis: 180px;
                        width: 180px;
                        .image {
                            max-width: calc(100% - 20px);
                        }
                    }
                    @media all and (max-width: 499.98px) {
                        max-width: 145px;
                        flex-basis: 145px;

                        .image {
                            height: 135px;
                            max-width: calc(100% - 5px);
                        }
                    }
                }
                .holder {
                    @media all and (max-width: 1200px) {
                        max-width: calc(100% - 180px);
                        flex-basis: calc(100% - 180px);
                    }
                    @media all and (max-width: 499.98px) {
                        max-width: calc(100% - 145px);
                        flex-basis: calc(100% - 145px);
                    }
                    &.cardView {
                        max-width: 100% !important;
                        flex-basis: 100% !important;
                        width: 100%;
                    }
                }
                .new {
                    background-color: $grey;
                    font-size: 13px;
                    padding: 1px 8px;
                    color: $white;
                    text-transform: uppercase;
                    font-family: $open-sans;
                    margin-right: 10px;
                    border-radius: 4px;
                    display: inline-block;

                    @media all and (max-width: 499.98px) {
                        font-size: 11px;
                    }
                }
                .itemName, .nameContent {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    flex-wrap: nowrap;
                }
                .title {
                    font-size: 15px;
                    line-height: 20px;
                    margin-bottom: 0;
                    margin-top: 0;
                    display: inline;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    @media all and (max-width: 499.98px) {
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
                .options {
                    .option {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        &.compare {
                            border: 1px solid $blue-pastel;
                            border-radius: 4px;
                            padding: 0 10px;
                            height: 20px;
                            width: 100px;
                            display: flex;
                            justify-content: center;
                            h5,
                            svg {
                                color: $blue-pastel;
                            }
                        }
                        &.isCompared {
                            background-color: $blue-pastel;
                            h5,
                            svg {
                                color: $white;
                            }
                        }
                        h6 {
                            @media all and (max-width: 499.98px) {
                                font-size: 10px;
                                font-weight: 400;
                            }
                        }
                    }
                }
                .basicInfo {
                    margin-top: 10px;
                    @media all and (max-width: 500px) {
                        margin-top: 0;
                    }
                    .basicInfoItem {
                        &:last-child {
                            margin-right: 0;
                        }
                        .iconHolder {
                            height: 30px;
                            @media all and (max-width: 499.98px) {
                                display: none;
                            }
                            img {
                                height: 24px;
                                width: auto;
                            }
                        }
                    }
                }
                .priceHolder {
                    .checkIconHolder {
                        width: 13px;
                        height: 13px;
                        background-color: $green-pastel-dark;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 5px;
                        svg {
                            color: $white;
                            width: 8px;
                            height: 8px;
                        }
                    }
                    .price {
                        margin-top: 3px;
                        font-size: 22px;
                        line-height: 25px;
                        @media all and (max-width: 499.98px) {
                            font-size: 18px;
                        }
                    }
                }
                .blueInfo {
                    .option {
                        font-size: 14px;
                        line-height: 18px;
                        font-family: $open-sans;
                        color: $blue-pastel;
                        @media all and (max-width: 499.98px) {
                            font-size: 11px;
                        }
                        &.bullet {
                            font-size: 18px;
                            padding: 0 5px;
                        }
                    }
                }
            }
            .comparePriceHolder {
                @media all and (max-width: 389.98px) {
                    display: none;
                }
            }
            .lessThan380 {
                @media all and (min-width: 390px) {
                    display: none;
                }
            }
            .cardView {
                .imageHolder {
                    max-width: 50% !important;
                    flex-basis: 50% !important;
                }
                .basicInfo {
                    .basicInfoItem {
                        @media (min-width: 1350px) and (max-width: 1450px) {
                            margin-right: 15px;
                        }
                        @media (min-width: 1280px) and (max-width: 1349.98px) {
                            margin-right: 10px;
                        }
                        @media (min-width: 960px) and (max-width: 1060px) {
                            margin-right: 10px;
                        }
                    }
                }
                .blueInfo {
                    margin-top: 15px
                }

                .itemName .title {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

.scaletonItem {
    margin: 5px 4px !important;
    
    &:first-child {
        margin-top: 5px !important;
    }
    .rectangle {
        width: 100%;
        height: 100px !important;
        margin: 20px;
    }
    .lines-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
        &__line {
            width: 50%;
            height: 20px !important;
            margin-bottom: 10px !important;
            margin-left: 40px;

            &:first-child {
                width: 70%
            }
            @media all and (min-width: 600px) and (max-width: 799.98px) {
                width: 90%;
            }
            @media all and (min-width: 500px) and (max-width: 599.98px) {
                width: 80%;
            }
        }
    }

    .holder {
        width: 100%;
    
        .nameContent {
            display: flex;
            align-items: center;
        }
    }
}

.basicInfoItem {
    &:not(:last-child) {
        margin-right: 10px;
        @media all and (max-width: 500px) {
            margin-right: 0;
        }
    }
}

.detailInfoItem {
    &:not(:last-child) {
        margin-right: 20px;
    }
}

.detailInfoItem,
.basicInfoItem {
    .iconHolder {
        height: 30px;
        width: auto !important;
        min-width: auto !important;
        max-width: none;
        img {
            height: 24px;
            width: auto;
        }
    }
}

.resultItemAutocardView {
    .imageHolder {
        img {
            max-width: 100% !important;
            max-height: 120px !important;
        }
    }
    .itemName {
        margin-top: 15px;
    }
    @media all and (min-width: 1490px) {
        min-height: 345px;
        max-height: 345px;
    }
    @media all and (max-width: 1199px) {
        min-height: unset;
    }
}

.imageHolder {
    align-self: center;
    align-items: center !important;

    .image {
        cursor: pointer;
    }
}

.watchStar {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $blue-pastel;

    svg {
      font-size: 24px;
    }

    .hoverVisible {
      display: none;
    }

    &:hover, &.alreadyWatching {
      .hoverVisible {
        display: block;
      }

      .hoverHidden {
        display: none;
      }
    }
  }

.cardView {
    .searchResultItem {
        display: flex;

        .itemName {
            margin-top: 1rem;
        }
        
        @media all and (max-width: 1590px) and (min-width: 1200px) {
            flex-basis: 50% !important;
            max-width: 50% !important;
        }
        .imageHolder {
            @media all and (max-width: 1199px) {
                height: unset !important;
                min-height: 110px;
                align-items: flex-start !important;
                img {
                    min-height: 170px;
                }
            }
        }
    }
}