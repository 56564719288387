.chat-overview {
  flex-grow: 1;
  min-height: 0;
  background-color: $white;
  border-radius: 4px;

  @media all and (min-width: 960px) {
    flex-wrap: nowrap !important;
  }

  @media all and (max-width: 959.98px) {
    .divider__vertical {
      height: 2px;
      width: 100%;
    }
  }

  .chat-conversations {
    display: flex;
    flex-direction: column;
    height: 100%;

    @media all and (max-width: 959.98px) {
      max-height: 270px;
    }

    &__list {
      flex-grow: 1;
      min-height: 0;
      overflow: hidden auto;
    }

    &__title {
      padding: 10px 10px 10px 20px;
      font-size: 14px;
      font-family: $open-sans;
      font-weight: bold;
    }
  }
}
