@import 'src/scss/variables';

.delivery-payment-options {
  padding: 30px 30px 0;
  background-color: $white;
  border-radius: 4px;

  @import './components/AddressSelector/AddressSelector';
  @import './components/PaymentMethodSelector/PaymentMethodSelector';

  @media (max-width: $media-xs) {
    padding: 0;
    background-color: transparent;
  }
}
