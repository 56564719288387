@import "src/scss/variables";

.delivery-type-data {
  position: relative;

  @media (max-width: $media-xs) {
    padding-right: 50px;
  }

  &__name {
    color: $black;
    font-weight: bold;
    margin-bottom: 10px;

    @media (max-width: $media-xs) {
      margin-bottom: 0;
    }
  }

  &__show-all {
    font-size: 13px;
    text-transform: uppercase;

    @media (max-width: $media-xs) {
      display: none;
    }
  }

  &__arrow {
    position: absolute;
    display: none;
    height: 20px;
    top: calc(50% - 10px);
    right: 10px;
    color: $grey-mat-focus;
    font-size: 1.2rem;

    @media (max-width: $media-xs) {
      display: block;
    }
  }

  &__choose {
    position: absolute;
    display: none;
    top: 0;
    right: 35px;
    font-size: 12px;
    text-transform: uppercase;

    @media (max-width: $media-xs) {
      display: block;
    }
  }

  .delivery-type {
    margin-bottom: 10px;

    @media (max-width: $media-xs) {
      pointer-events: none;

      &:not(.delivery-type--selected) {
        display: none;
      }

      &:first-of-type {
        margin-bottom: 0;
      }
    }

    &__checkbox {
      display: inline-flex;
      align-items: flex-start;

      .MuiRadio-root {
        padding: 0;
        margin-right: 8px;

        @media (max-width: $media-xs) {
          display: none;
        }
      }

      .MuiTypography-root {
        font-size: 13px;
        color: $black;
      }
    }

    &__price {
      font-size: 13px;
      color: $black;

      @media (max-width: $media-xs) {
        font-weight: bold;
      }
    }
  }
}
