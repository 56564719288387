@import "../../../scss/variables";

.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  position: relative;
  padding: 30px 0 18px 0;
  background-color: $grey-blue-footer;

  @media all and (min-width: 500px) {
    z-index: 20;
  }

  @media all and (max-width: 499.98px) {
    z-index: 200;
    padding: 0;
    position: fixed;
    bottom: 0;
    background-color: $white;
    height: 70px;
    max-height: 70px;
    .container   {
      height: 100%;
      display: flex;
    }
  }

  @media all and (max-width: 319.98px) {
    min-width: 320px;
    overflow: auto;
  }

  &__wrapper-desktop {
    margin: auto;
    max-width: 1200px;
    justify-content: space-between;
  
    @media all and (max-width: 959.98px) {
      &.MuiGrid-container {
        flex-direction: column-reverse;
      }
    }
  
    @media all and (max-width: 499.98px) {
      &.MuiGrid-container {
        display: none;
      }
    }
  }

  &__container {
    @media all and (max-width: 1199.98px) {
      margin: 0 1rem;
    }
  }

  &__sections {
    @media all and (max-width: 599.98px) {
      &.MuiGrid-item {
        margin-top: 10px;
      }
    }
  }

  &__section {
    color: $white;
    width: calc(25% - 2rem);
    font: bold 13px/16px $open-sans;

    @media all and (max-width: 959.98px) {
      &.MuiBox-root {
        width: calc(33.33% - 2rem);
      }
    }

    @media all and (max-width: 599.98px) {
      &.MuiBox-root {
        margin: 1rem;
        width: calc(50% - 2rem);
      }
    }

    &--empty {
      @media all and (max-width: 959.98px) {
        display: none;
      }
    }
  }

  &__section-title {
    font-size: 14px;
    font-weight: bold;

    @media all and (max-width: 959.98px) {
      text-align: center;
    }
  }

  &__link-list {
    padding: 0;
    list-style: none;

    @media all and (max-width: 959.98px) {
      text-align: center;
    }
  }

  &__link {
    font-weight: 400;
    margin: 0.5rem 0;

    .pop-up {
      cursor: pointer;
    }

    .redirect {
      color: $cyan-pastel;
      text-decoration: none;      
    }
  }

  &__mobile-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $black;
    font: normal 11px/14px $open-sans;
    position: relative;
    text-align: center;

    @media all and (max-width: 499.98px) {
      &.MuiBox-root {
        margin: 8px 0 !important;
        min-height: 44px;
      }
    }

    .icon {
      margin-bottom: 5px;
    }

    .watchedNumber {
      position: absolute;
      right: 8px;
      top: 0;
      width: 15px;
      height: 15px;
      background-color: $red-pastel;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-family: $open-sans;
    }

    &__mobile-filter {
      background-color: $blue-pastel;
      border-radius: 20px;
      padding: 5px;
      display: flex;
      align-items: center;
      color: $white;
      font: normal 11px/14px $open-sans;
      margin-bottom: 3px;
      height: 10px;

      .spinner {
        color: $white;
      }

      .filter-icon {
        padding-right: 5px;
        margin-bottom: 0;
        width: 10px;
        height: auto;
      }
    }

    &__holder {
      position: relative;
      margin-bottom: 2px;

      &__compare {
        background-color: $blue-pastel;
        color: $white;
        font: normal 11px/14px $open-sans;
        padding: 5px;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: -20px;
      }
    }
  }


  &__redPastel {
    color: $red-pastel;

    .icon {
      filter: invert(84%) sepia(30%) saturate(5506%) hue-rotate(309deg) brightness(104%) contrast(119%);
    }

    .countInCart {
      position: absolute;
      right: 43%;
      top: -5px;
      font-weight: bold;
      color: $red-pastel;
      font-family: $open-sans;
      background-color: transparent;

      @media all and (max-width: 420px) {
        right: 42%;
      }

      @media all and (max-width: 350px) {
        right: 41%;
      }

      &.two-digits {
        right: 34%;

        @media all and (max-width: 499.98px) {
          right: 42%;
          font-size: 9px;
        }

        @media all and (max-width: 440px) {
          right: 41%;
        }

        @media all and (max-width: 400px) {
          right: 40%;
        }

        @media all and (max-width: 350px) {
          right: 39%;
        }

        @media all and (max-width: 320px) {
          right: 38%;
        }
      }

      &.three-digits {
        right: 26%;

        @media all and (max-width: 499.98px) {
          right: 35%;
          font-size: 9px;
        }
      }
    }
  }

  &__link-to {
    width: 20%;
    text-decoration: none;
  }  

  &__cart-view {
    border-top: 1px solid $light-grey-bg;

    @media all and (min-width: 500px) {
      &.MuiGrid-container {
        display: none;
      }
    }
  
    button:disabled {
      opacity: 0.4;
    }    
  }

  &__copyright {
    margin-top: 50px;
    margin-bottom: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font: normal 13px/16px $open-sans;

    .link {
      padding: 0 0.4375rem;
    }

    @media all and (max-width: 959.98px) {
      margin-top: 30px;
    }

    @media all and (max-width: 499.98px) {
      display: none;
    }
  }
}