@import "src/scss/variables";

.Cart {
  max-width: 1400px;
  margin: 0 auto !important;
  flex-grow: 1;

  &.body {
    background-color: #44A8A7;
  }

  &__title {
    margin-bottom: 10px;
    padding-left: 30px;
    @media all and (max-width: 499.98px) {
      display: none;
    }
  }

  .dark {
    color: $dark-grey;
  }

  .mt-3 {
    margin-top: 3px;
  }

  .mt-5 {
    margin-top: 5px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mt-15 {
    margin-top: 15px;
  }

  .widthAuto {
    width: auto;
  }

  .noWrap {
    white-space: nowrap;
  }

  .holder {
    @media all and (max-width: 899.98px) {
      margin: 0;
    }

    .background {
      background-color: $white;
    }

    .leftSide {
      @media all and (min-width: 960px) {
        &:not(.fullCartWidth) {
          max-width: calc(100% - 370px);
          flex-basis: calc(100% - 370px);
        }
      }
      @media all and (min-width: 900px) {
        &:not(.fullCartWidth) {
          max-width: 70%;
          flex-basis: 70%;
        }
      }

      a {
        text-transform: unset;
        color: $cyan-pastel;
      }
    }

    .rightSide {
      @media all and (min-width: 960px) {
        max-width: 370px;
        flex-basis: 370px;
      }
      @media all and (min-width: 900px) {
        max-width: 30%;
        flex-basis: 30%;
      }
      @media all and (max-width: 899.98px) {
        position: sticky;
        width: 100%;
        bottom: 0px;
        background-color: $light-grey-bg;
        z-index: 3;
      }
      @media all and (max-width: 499.98px) {
        //bottom: 63.19px;
        //bottom: 79.19px;
      }
      @media all and (max-width: 319.98px) {
        bottom: 0 !important;
      }
    }

    .divideUsers {
      margin-bottom: 10px;
      @media all and (max-width: 499.98px) {
        margin-bottom: 5px;
      }
    }

    .divideUsersMobile {
      @media all and (min-width: 500px) {
        display: none;
      }
      @media all and (max-width: 499.98px) {
        border-bottom: 1px solid $grey-mat-unfocus;
        width: 90%;
        margin-bottom: 20px;
        margin-right: 0;
        margin-left: auto;
      }
    }

    .emptyCart {
      flex-direction: column;
      padding: 25px 30px;
      @media all and (max-width: 499.98px) {
        padding: 30px 10px;
      }

      .title {
        margin-bottom: 10px;
      }
    }

    .itemsInCart {
      padding: 25px 30px;
      position: relative;

      @media all and (max-width: 499.98px) {
        padding: 10px;
      }

      &:last-child {
        margin-bottom: 0 !important;
      }

      &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $white;
        opacity: 0.6;
        z-index: 1;

        &.firstItem {
          margin-top: 70px;
          height: calc(100% - 70px);

          @media all and (max-width: 499.98px) {
            margin-top: 50px;
            height: calc(100% - 50px);
          }
        }
      }

      .user {
        border-bottom: 1px solid $grey-mat-unfocus;
        margin-bottom: 15px;
        @media all and (max-width: 499.98px) {
          border: none;
          margin-bottom: 5px;
        }

        h4 {
          color: $light-grey;
        }

        .checkbox {
          @media all and (max-width: 499.98px) {
            width: 35px;
            margin-right: 10px;
          }
        }

        .handShakeIcon {
          margin: 0 5px;

          svg {
            height: auto;
            width: 22px;
            color: $red-pastel;
          }
        }

        .userIcon {
          margin: 0 5px;

          svg {
            height: auto;
            width: 12px;
            color: $light-grey;
          }
        }

        .sellerLink {
          font-size: 13px;
        }

        .price {
          @media all and (max-width: 499.98px) {
            display: none;
          }
        }
      }

      .itemInfoHolder {
        flex-direction: row;
        @media all and (max-width: 499.98px) {
          align-items: flex-start;
        }

        .checkbox {
          flex-grow: 0;
          max-width: 45px;
          flex-basis: 45px;
          width: 45px;

          .PrivateSwitchBase-root-28 {
            padding: 0 9px;
          }

          @media all and (max-width: 700px) {
            max-width: 35px;
            flex-basis: 35px;
            width: 35px;
          }
        }

        .image {
          width: 115px;
          flex-grow: 0;
          max-width: 115px;
          flex-basis: 115px;
          display: flex;
          align-items: center;
          justify-content: center;

          @media all and (max-width: 700px) {
            max-width: 100px;
            flex-basis: 100px;
            width: 100px;
          }
          @media all and (max-width: 499.98px) {
            flex-direction: column;
            align-items: flex-start;
          }

          img {
            width: 100%;
            height: auto;
          }
        }

        .info {
          flex-grow: 0;
          max-width: calc(100% - 160px);
          flex-basis: calc(100% - 160px);
          @media all and (max-width: 700px) {
            max-width: calc(100% - 135px);
            flex-basis: calc(100% - 135px);
          }

          .padding {
            padding-left: 35px;
            @media all and (max-width: 960px) {
              padding-left: 20px;
            }
            @media all and (max-width: 700px) {
              padding-left: 15px;
            }
            @media all and (max-width: 499.98px) {
              padding-left: 10px;
            }
          }

          .title {
            padding-right: 10px;

            .link {
              font-size: 16px;
              @media all and (max-width: 499.98px) {
                font-size: 13px;
              }
            }
          }

          .category {
            @media all and (max-width: 499.98px) {
              display: none;
            }

            h4 {
              color: $cyan;

              &:hover {
                color: $cyan-focus !important;
              }
            }
          }
        }

        .optionsHolder {
          @media all and (max-width: 499.98px) {
            justify-content: flex-start;
          }

          .divider {
            margin: 0 15px;
            border-right: 1px solid $grey-mat-unfocus;
            height: 14px;
            @media all and (max-width: 499.98px) {
              display: none;
            }
          }

          .AddToCartCounter {
            padding-right: 15px;
            @media all and (max-width: 499.98px) {
              padding-right: 10px;
            }
          }

          .cta {
            @media all and (max-width: 499.98px) {
              font-size: 12px !important;
              &.first {
                padding-right: 10px;
              }
            }
          }

          &.options {
            @media all and (max-width: 499.98px) {
              justify-content: flex-end;
            }
          }

          .removeButton {
            z-index: 2;
          }
        }

        .price {
          font: bold 18px/16px $roboto;
          color: $grey-price;
          text-align: right;
          @media all and (max-width: 499.98px) {
            font: bold 13px/16px $roboto;
            color: $dark-grey;
            text-align: left;
          }
        }
      }

      .updatedMessage {
        margin-top: 10px;
        font-size: 12px;
        font-family: $open-sans;
        color: $red-pastel;

        z-index: 2;

        @media all and (max-width: 499.98px) {
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .floatingPanel {
      padding: 30px;
      @media all and (max-width: 499.98px) {
        padding: 10px 30px 5px 30px;
      }

      .MuiButton-contained {
        color: $white !important;
        font-size: 15px;
        margin-top: 30px;
        width: 100%;
        @media all and (max-width: 499.98px) {
          margin-top: 10px;
          font-size: 14px;
        }
      }

      .title {
        font: normal 18px/26px $open-sans;
        @media all and (max-width: 499.98px) {
          width: 100%;
          font: normal 14px/20px $open-sans;
        }
      }
    }

    .similarProductsHolder {

      .container {
        width: 100%;
      }

      .watchListPage {
        padding: 20px 0px 30px 0px;

        .title {
          h2 {
            padding-left: 30px;

            @media all and (max-width: 499.98px) {
              padding-left: 10px;
            }            
          }
        }
      }
    }
  }

  .Mui-disabled, #disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}