@import "src/scss/variables";
@import "./ProductDetailAutomoto/ProductDetailAutomoto";

.productDetailTransportMenu {
  font-family: $open-sans;

  h5 {
    color: $dark-grey;
  }

  img {
    margin-right: 15px;
    @media all and (max-width: 499.98px) {
      width: 20px;
    }
  }
}

.ProductDetailMain {
  max-width: 1600px;
  margin: 0 auto !important;
  flex-grow: 1;

  .background {
    background-color: $white;
  }

  .noBackground {
    background-color: $light-grey-bg;
    height: 10px;
    width: 150%;
    margin-right: -20px;
    margin-left: -20px;
    @media all and (min-width: 500px) {
      display: none;
    }
  }

  .mb-15 {
    margin-bottom: 15px;
  }

  .mt-15 {
    margin-top: 15px;
  }

  .mr-xs-5 {
    @media all and (max-width: 499.98px) {
      margin-right: 5px;
    }
  }

  .mr-10 {
    margin-right: 10px;
  }

  .mt-5 {
    margin-top: 5px;
  }

  .mb-2 {
    margin-bottom: 2px;
  }

  .d-flex {
    display: flex;
  }

  .relative {
    position: relative;
  }

  .items-center {
    align-items: center;
  }

  .text-left {
    text-align: left !important;
  }

  .h-100 {
    height: 100%;
  }

  .link {
    &:hover {
      color: $cyan-focus !important;
    }
  }

  .noMargin {
    margin-right: 0 !important;
  }

  .onlyDesktop {
    @media all and (max-width: 499.98px) {
      display: none;
    }
  }

  .onlyMobile {
    @media all and (min-width: 500px) {
      display: none;
    }
  }

  .displayNone {
    display: none;
  }

  .leftSide {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .rightSide {
    display: flex;
    align-items: center;
    width: auto;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .breadcrumbs {
    margin-bottom: 2px;
    @media all and (max-width: 499.98px) {
      display: none;
    }

    .category:last-child {
      .symbols {
        display: none;
      }
    }

    &__link:hover {
      text-decoration: underline;
      text-decoration-color: $light-grey;
    }
  }

  .productDetailHolder {
    @media all and (max-width: 499.98px) {
      padding-top: 0px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .productDetail {
      padding: 20px 20px 20px 30px;

      @media all and (max-width: 499.98px) {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 0;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }

      .imagesHolder {
        margin-top: 30px;
        // Pro tuto screenu má být breakpoint už na 800px místo 960px
        @media all and (min-width: 800px) {
          flex-grow: 0;
          max-width: 50%;
          flex-basis: 50%;
          padding-right: 35px;
        }

        @media all and (max-width: 499.98px) {
          margin-top: 0px;
        }
      }

      .skeleton {
        &__container {

          &--left {
            height: 480px;
            padding-right: 35px;
            
            .rectangle {
              height: 100%;
            }

            @media all and (max-width: 799.98px) {
              height: 300px;
              padding-right: 0;
              padding-bottom: 20px;
            }
          }

          &--left, &--right {
            @media all and (min-width: 800px) {
              &.MuiGrid-root {
                flex-grow: 0;
                max-width: 50%;
                flex-basis: 50%;
              }
            }
          }
        }

        &__rect {
          width: 100%;
          height: 48px;
          margin: 20px 0;

          &.MuiSkeleton-text {
            transform: none;
          }

          &--first {
            height: 180px;
            margin-top: 0;
          }

          &--last {
            height: 120px;
          }
        }
      }

      .mobileButtonsHolder {
        margin-top: -28px;
        margin-bottom: 10px;

        .favouriteButton, .shareButton, .compareButton {
          min-width: 50px;
          width: 50px;
          height: 50px;
          background-color: $white;
          border-radius: 50%;

          -webkit-box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.15);

          img {
            width: 80%;
          }
        }

        .compareButton {
          svg {
            transform: scale(2);

            @media all and (max-width: 360px) {
              transform: scale(1.5);
            }
          }

          &.isCompared {
            color: $white;
            background-color: $blue-pastel;
          }
        }

        .favouriteButton, .messageButton, .shareButton {
          margin-right: 10px;
        }

        .messageButton, .callButton {
          min-width: 56px;
          width: 56px;
          height: 56px;
          border-radius: 50%;
        }

        .messageButton {
          background: transparent linear-gradient(180deg, #FF8080 0%, #EF6666 100%) 0% 0% no-repeat padding-box;
        }

        .callButton {
          background: transparent linear-gradient(180deg, #89C74A 0%, #70B22D 100%) 0% 0% no-repeat padding-box;
        }
      }

      .infoHolder {
        // Pro tuto screenu má být breakpoint už na 800px místo 960px
        @media all and (min-width: 800px) {
          flex-grow: 0;
          max-width: 50%;
          flex-basis: 50%;
        }

        h5, h6 {
          color: $dark-grey;
        }

        .title {
          font-size: 24px;
          font-weight: normal;
          margin-top: 0;
          margin-bottom: 10px;
          overflow-wrap: break-word;
          word-wrap: break-word;
          word-break: break-word;
          hyphens: auto;
          
          @media (max-width: 499.98px) {
            font-size: 18px;
          }
        }

        .metroIcon {
          margin-right: 10px;
          @media all and (max-width: 499.98px) {
            margin-right: 5px;
          }
        }

        .newOffer {
          color: $gold-digger;
          padding-right: 15px;
        }

        .placeIcon, .calendarIcon {
          margin-right: 5px;
        }

        .priceWithDPH {
          color: $grey !important;
        }

        .price {
          font-family: $roboto;
          font-weight: bold;
          font-size: 24px;
          color: $dark-grey;
          @media all and (max-width: 499.98px) {
            font-size: 20px;
          }

          .coins {
            font-size: 18px;
            @media all and (max-width: 499.98px) {
              font-size: 14px;
            }
          }
        }

        .iconHolder {
          width: 50px;
          @media all and (max-width: 499.98px) {
            min-width: 40px;
            max-width: 40px;
            img {
              max-width: 30px;
            }
          }
        }

        .transportPrice {
          font-weight: bold;
        }

        .transport {
          width: 100%;
          margin-bottom: 5px;

          .MuiButton-label {
            color: $dark-grey;
            font-size: 14px;
            text-align: left;
            @media all and (max-width: 499.98px) {
              font-size: 12px;
              padding-right: 22px;
            }
          }

          .MuiButton-endIcon {
            margin-left: 0px;
          }

          .MuiButton-text {
            padding: 0px 0px;
            @media all and (max-width: 499.98px) {
              width: 100%;
              justify-content: flex-start;
              .MuiButton-endIcon {
                position: absolute;
                right: 0;
              }
            }
          }

          .MuiButton-root:hover {
            text-decoration: none;
            background-color: transparent;

            .MuiButton-label {
              color: $cyan-pastel !important;
            }

            img {
              // obarví ikonku (svg) na zelenou
              // https://codepen.io/sosuke/pen/Pjoqqp
              filter: invert(57%) sepia(63%) saturate(352%) hue-rotate(130deg) brightness(90%) contrast(90%);
            }
          }
        }

        .handShakeIcon {
          margin: 0 5px;

          svg {
            width: 22px;
            height: auto;
            color: $red-pastel;
          }
        }

        .userIcon {
          margin: 0 5px;

          svg {
            width: 12px;
            height: auto;
            color: $light-grey;
          }
        }

        .nickname {
          margin-right: 15px;

          a {
            text-decoration: none;
            color: $light-grey;
          }
        }

        .seller {
          font-family: $open-sans;
          font-size: 13px;
          @media all and (max-width: 499.98px) {
            font-size: 12px;
          }

          a {
            color: $cyan-pastel;
            text-decoration: none;

            &:hover {
              color: $cyan-focus;
            }
          }

          .MuiRating-root {
            font-size: 18px;
            color: #F6CA6E;
            margin-right: 15px;
            @media all and (max-width: 499.98px) {
              font-size: 13px;
            }
          }

          .MuiRating-iconEmpty {
            color: #F6CA6E;
          }

          .ratingHolder {
            @media all and (min-width: 500px) {
              align-items: center;
            }
          }

          .ratings {
            margin-right: 20px;
            @media all and (max-width: 499.98px) {
              margin-right: 0;
            }
          }

          .watchOffer {
            cursor: pointer;

            @media all and (max-width: 499.98px) {
              display: none;
            }

            .watch {
              margin-top: 3px;
              margin-right: 4px;

              @media (min-width: 800px) and (max-width: 900px) {
                display: none;
              }
            }

            .watchStar {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              font: normal 14px/19px $open-sans;
              color: $cyan-pastel !important;
        
              svg {
                font-size: 24px;
              }
        
              .hoverVisible {
                display: none;
              }
        
              &:hover, &.alreadyWatching {
                .hoverVisible {
                  display: block;
                }
        
                .hoverHidden {
                  display: none;
                }
              }
            }
          }
        }

        .border {
          border-bottom: 1px solid $grey-mat-unfocus;
          margin: 5px 0;
        }

        .returnGoods {
          margin-bottom: 5px;
          position: relative;
          flex-wrap: nowrap;

          h6 {
            padding-right: 20px;
          }

          .infoCircleIcon {
            margin-left: 5px;
            cursor: pointer;

            svg {
              width: 11px;
              height: 11px;
              color: $grey;
            }
          }

          .rightIcon {
            position: absolute;
            right: 0;
            display: inherit;
            margin-left: 8px;
            margin-right: -4px;
          }

          &:hover {
            cursor: pointer;

            h5, h6, .rightIcon {
              color: $cyan-pastel;
            }

            img {
              // obarví ikonku (svg) na zelenou
              // https://codepen.io/sosuke/pen/Pjoqqp
              filter: invert(57%) sepia(63%) saturate(352%) hue-rotate(130deg) brightness(90%) contrast(90%);
            }

            .infoCircleIcon {
              svg {
                color: $cyan-pastel;
              }
            }
          }
        }

        .dph {
          margin-bottom: 5px;

          .infoCircleIcon {
            svg {
              color: $grey;
              width: 21px;
            }
          }
        }

        .infoDesktop {
          @media all and (max-width: 499.98px) {
            display: none;
          }

          .mobileLG2 {
            max-width: 80px;
            flex-basis: 80px;
          }

          .mobileLG10 {
            max-width: calc(100% - 80px);
            flex-basis: calc(100% - 80px);
          }
        }

        .infoMobile {
          @media all and (min-width: 500px) {
            display: none;
          }

          .mobileXS2 {
            max-width: 70px;
            flex-basis: 70px;
          }

          .mobileXS10 {
            max-width: calc(100% - 70px);
            flex-basis: calc(100% - 70px);
          }
        }

        .countButtons {
          margin: 15px 0;

          .count {
            margin: 0 10px;
          }

          .MuiButton-root {
            min-width: 25px;
            width: 25px;
            height: 25px;
            background-color: $grey-mat-unfocus;
            border-radius: 50%;

            .MuiSvgIcon-root {
              width: 15px;
              height: 15px;
              color: $dark-grey;
            }

            &.Mui-disabled {
              .MuiSvgIcon-root {
                color: $grey;
              }
            }
          }

          .maxCount {
            color: $grey;
            margin-left: 15px;
          }

          .sold-out {
            color: $red;
            font-weight: bold;
          }
        }

        .expired {
          color: $red;
          margin-bottom: 15px;

          &--auto {
            margin-top: 10px;
          }
        }

        .buyButtonsMobile {
          margin-bottom: 15px;

          .addToCartButton, .fastForwardButton, .viewNumberButton {
            height: 40px;
            width: 100%;
            border-radius: 4px;
            color: $white;
            font-size: 15px;
            font-family: $open-sans;
            padding-top: 10px;
            padding-bottom: 10px;

            .MuiButton-label {
              img {
                margin-right: 20px;
              }
            }

            .progressSpinner {
              margin-left: 10px;
              color: $white;
            }
          }

          .addToCartButton {
            margin-bottom: 10px;
            background: transparent linear-gradient(180deg, #FF8080 0%, #EF6666 100%) 0% 0% no-repeat padding-box;
          }

          .fastForwardButton {
            background: transparent linear-gradient(180deg, #89C74A 0%, #70B22D 100%) 0% 0% no-repeat padding-box;
          }
        }

        .buyButtons {
          margin-top: 15px;
          margin-bottom: 20px;
          justify-content: space-between;

          @media all and (max-width: 499.98px) {
            display: none;
            margin-bottom: 0px;
          }

          .addToCartButton, .fastForwardButton, .viewNumberButton {
            height: 40px;
            width: 48%;
            border-radius: 4px;
            color: $white;
            font-size: 15px;
            font-family: $open-sans;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-bottom: 10px;

           &:disabled {
              opacity: 0.7;
            }

            .MuiButton-label {
              img {
                margin-right: 20px;
                @media (min-width: 800px) and (max-width: 900px) {
                  margin-right: 10px;
                  height: 15px;
                }
              }
            }

            .progressSpinner {
              margin-left: 10px;
              color: $white;
            }
          }

          .addToCartButton {
            background: transparent linear-gradient(180deg, #FF8080 0%, #EF6666 100%) 0% 0% no-repeat padding-box;
          }

          .fastForwardButton {
            background: transparent linear-gradient(180deg, #89C74A 0%, #70B22D 100%) 0% 0% no-repeat padding-box;
          }

          .viewNumberButton {
            border: 1px solid #70B22D;

            .MuiButton-label {
              color: #70B22D;
            }
          }
        }

        .secureAndQuestions {
          margin-top: 20px;

          @media all and (max-width: 499.98px) {
            margin-top: 0;
          }

          .secureShopping, .question {
            margin-bottom: 15px;
            @media all and (max-width: 499.98px) {
              position: relative;
              width: 100%;
            }
          }

          .secureShopping {
            padding-right: 10px;
            @media all and (max-width: 499.98px) {
              margin-top: 20px;
              width: 100%;
              h4, h6 {
                text-align: center;
              }
            }
          }

          .icon {
            margin-right: 15px;
            width: auto;
            height: 33px;
          }
        }

        .infoMobile {
          display: none;
          @media all and (max-width: 499.98px) {
            display: flex;
          }

          h6 {
            color: $dark-grey;
          }
        }
      }
    }

    .productDetailDescription {
      margin-top: 10px;
      @media all and (max-width: 499.98px) {
        border-radius: 0 !important;
      }

      .descTitle {
        font-size: 21px;
        font-family: $open-sans;
        font-weight: bold;
        color: $black;
        padding-bottom: 30px;
      }

      .partNumber {
        width: 100%;
        color: $black;
        font-size: 14px;
        padding-bottom: 12px;    
        font-family: $open-sans;

        .label {
          font-weight: bold;
        }
      } 

      .desc {
        width: 100%;
      }
    }
  }

  .otherOffersHolder {
    padding: 0px !important;
    margin: 0px;
  
    .carouselHolder {
      display: contents;

      @media all and (max-width: 499.98px) {
        padding-left: 0px;
        padding-right: 0px;
      }

      & .carouselCard {
        height: unset;
        margin: 4px;

        @media all and (max-width: 499.98px) {
          border-radius: 0px;
          margin: 4px 0px;
        }
      }

    }
  }

  .ratings {
    padding: 0px !important;
    margin: 0px;

    .offerRatingsHolder {

      @media all and (max-width: 499.98px) {
        padding-left: 0px;
        padding-right: 0px;
      }

      .offerRatings {
        @media all and (max-width: 499.98px) {
          padding: 20px 20px 20px 20px;
          border-radius: 0px;
        }
      }
    }

  }
  
}