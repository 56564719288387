@import "src/scss/variables";
@import "../../components/ProductCarousel/ProductCarousel";

.LandingPage {
  max-width: 1600px;
  margin: 0 auto !important;
  flex-grow: 1;

  .card {
    background-color: $white;
    border-radius: 4px;
    width: 100%;
    height: 100%;

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .cardMobile {
    background-color: $white;
    border-radius: 20px;
    min-width: 130px;
    min-height: 110px;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
  }

  .favCategoryButton {
    background-color: $grey-chips;
    border-radius: 16px;
    margin-right: 5px;
    margin-top: 6px;
    padding: 6px 12px 7px 12px;
    letter-spacing: 0.25px;
    font-size: 14px;
    font-family: $open-sans;
    line-height: 1.75;
    color: $black;
    text-decoration: unset;

    &__skeleton {
      height: 37px;
      margin-right: 5px;
      margin-top: 6px;
      border-radius: 16px;
      width: 110px;
    }
  }

  .marketplaceFavButton:hover {
    background-color: $yellow-pastel;
    color: $black;
  }

  .automotoFavButton:hover {
    background-color: $blue-pastel;
    color: $white;
  }

  .onlyMobile {
    display: none;
  }

  .onlyDesktop {
    display: flex;
  }

  h2, h5 {
    margin-bottom: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mw-49 {
    max-width: 49%;
  }

  .relative {
    position: relative;
  }

  // LANDING PAGE COMPONENTS:
  .Marketplace {
    padding: 20px 35px 15px 30px;

    h5 {
      font-size: 14px;
      line-height: 22px;
      font-weight: bold;
      letter-spacing: 0.13px;
      margin-bottom: 3px;
      color: $dark-grey;
    }

    .buttonsHolder {
      margin-top: 30px;

      .button {
        font-weight: bold;
        padding: 9px 40px;
        border-radius: 5px;
        height: 40px;
      }

      .addOffer {
        color: $white;
        margin-right: 10px;
      }

      .searchCategories {
        color: $yellow-pastel;
        border: 1px solid $yellow-pastel;
      }
    }

    .buttonsHolderSmallScreen {
      display: none;
    }

    .marketPlaceImage {
      max-width: 230px;
    }

    .imageSkeleton {
      width: 100%;
      height: 100%;
      max-width: 230px;
      border-radius: 10px;
    }
  }

  .marketplaceWrapper {
    flex: 1 1 70%;
    order: 2;

    @media all and (max-width: 1355px) {
      order: 1;
    }
  }

  .benefitsWrapper {
    flex: 1 1 30%;
    min-width: 395px;
    min-height: 250px;
    order: 1;

    @media all and (max-width: 1355px) {
      order: 2;
      flex-basis: 31%;
    }
  }

  .benefits {
    height: 100%;
    position: relative;
    
    &__contentWrapper {
      padding: 20px 30px;
      align-content: flex-start;
    }

    &__titleWrapper {
      display: inline-flex;
      align-items: flex-start;
      margin-bottom: 40px;

      .icon {
        margin-right: 20px;
        width: 32;
        height: 32;
      }

      .title {
        font-family: $open-sans;
        font-size: 28px;
        font-weight: bold;
        margin: 0px;
      }

      .skeleton {
        position: relative;
        margin-right: 20px;
        width: 32px;
        height: 32px;
        border-radius: 10px;
      }
    }

    &__store,
    &__noFees {
      font-family: $open-sans;
      font-size: 14px;
    }

    &__store {
      font-weight: bold;
      margin-bottom: 30px;
    }

    &__noFees {
      max-width: 70%;
    }

    &__findOutMore {
      background-color: $red-pastel;
      background: transparent linear-gradient(180deg, $red-pastel 0%, $red-pastel-dark 100%) 0% 0% no-repeat;
      color: $white;
      border: unset;
      min-width: 124px;
      min-height: 40px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      margin-top: 40px;

      &:hover {
        background: transparent linear-gradient(180deg, $red-pastel 0%, $red-pastel-dark 100%) 0% 0% no-repeat;
        opacity: 0.6;
      }
    }

    &__backgroundImage {
      position: absolute;
      bottom: 0px;
      right: 0px;
    }

    &__imageSkeleton {
      position: absolute;
      right: 10px;
      bottom: 10px;
      width: 200px;
      height: 100px;
      border-radius: 10px;
    }
  }

  .autoMotoSearchHomepageWrapper {
    flex: 1 1 70%;
    min-width: 70%;
    order: 3;

    @media all and (max-width: 1355px) {
      order: 4;
    }
  }

  .autoMotoInfoPanelWrapper {
    flex: 1 1 30%;
    min-width: 400px;
    order: 4;

    @media all and (max-width: 1355px) {
      order: 3;
      flex-basis: 50%;
      min-height: 390px;
      min-width: 475px;
    }
  }

  .autoMotoInfoPanel {
    height: 100%;
    position: relative;
    
    &__contentWrapper {
      padding: 20px 30px;
      align-content: flex-start;
    }

    &__titleWrapper {
      display: inline-flex;
      align-items: flex-start;
      margin-bottom: 40px;

      .icon {
        margin-right: 20px;
        width: 32;
        height: 32;
      }

      .title {
        font-family: $open-sans;
        font-size: 28px;
        font-weight: bold;
        margin: 0px;
      }

      .skeleton {
        position: relative;
        margin-right: 20px;
        width: 32px;
        height: 32px;
        border-radius: 10px;
      }
    }

    &__sellFasterAddOffer,
    &__modernPages,
    &__productsComparison {
      font-family: $open-sans;
      font-size: 14px;
    }

    &__modernPages,
    &__productsComparison {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 30px;

        @media all and (max-width: 1355px) {
          max-width: 50%;
          margin-bottom: 20px;
        }
      }
    }

    &__sellFasterAddOffer {
      font-weight: bold;

      &:last-of-type {
        margin-bottom: 30px;
      }
    }

    &__findOutMore {
      background: transparent linear-gradient(180deg, $blue-pastel 0%, $blue-pastel-dark 100%) 0% 0% no-repeat;
      color: $white;
      border: unset;
      min-width: 124px;
      min-height: 40px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;

      margin-top: 20px;

      &:hover {
        background: transparent linear-gradient(180deg, $blue-pastel 0%, $blue-pastel-dark 100%) 0% 0% no-repeat;
        opacity: 0.6;
      }
    }

    &__backgroundImage {
      position: absolute;
      bottom: 0px;
      right: 0px;
    }

    &__imageSkeleton {
      position: absolute;
      right: 10px;
      bottom: 10px;
      width: 200px;
      height: 150px;
      border-radius: 10px;
    }
  }

  .AutoMotoSearchHomepage {
    padding: 15px 30px 15px 30px;

    h5 {
      font-size: 14px;
      line-height: 22px;
      font-weight: bold;
      letter-spacing: 0.13px;
      margin-bottom: 3px;
      color: $dark-grey;
    }

    .autoImage {
      padding-top: 45px;
      padding-bottom: 15px;

      &.loaded {
        width: 50%;
      }
    }

    .imageSkeleton {
      width: 50%;
      height: 138px;
      margin: auto;
      border-radius: 10px;
    }

    .favModelsHolder {
      padding-right: 20px;
    }

    .buttonsHolder {
      display: flex;

      .button {
        font-weight: bold;
        // padding: 9px 40px;
        border-radius: 5px;
        height: 40px;
        flex: 1 1 auto;
        max-width: 160px;

        &:last-child {
          max-width: 200px;
        }
      }

      .addOffer {
        color: $white;
        margin-right: 10px;
      }

      .compare {
        color: $blue-pastel;
        border: 1px solid $blue-pastel;
      }
    }

    .buttonsHolderSmallScreen {
      display: none;
    }

    .searchButton {
      color: $white;
      padding: 10px 25px;
      height: 40px;
      font-size: 14px;
      font-weight: bold;
      background: transparent linear-gradient(180deg, $blue-pastel 0%, $blue-pastel-dark 100%) 0% 0% no-repeat;
    }

    .Mui-selected {
      .MuiTab-wrapper {
        color: $blue-pastel;
      }
    }

    .MuiTabs-root .MuiTabs-indicator {
      background-color: $blue-pastel;
    }
  }

  .InfoPanelHomepage {
    padding: 20px;
  }

  .main {
    height: 100%;
    justify-content: space-between;
  }
}

@media all and (max-width: 1345px) {
  .LandingPage {
    .AutoMotoSearchHomepage, .Marketplace {
      .buttonsHolder {
        .button {
          padding: 9px 25px;
        }
      }
    }

    .Marketplace {
      .marketPlaceImage {
        width: 100%;
      }
    }
  }
}

@media all and (max-width: 1172px) {
  .LandingPage {
    .AutoMotoSearchHomepage, .Marketplace {
      .buttonsHolder {
        position: relative;

        .button {
          padding: 9px 15px;
        }
      }
    }

    .AutoMotoSearchHomepage {
      .autoImage {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }
}

@media all and (max-width: 1024px) {
  .LandingPage {
    .AutoMotoSearchHomepage, .Marketplace {
      .buttonsHolder {
        .button {
          padding: 8px;
        }
      }
    }
  }
}

@media (min-width: 960.1px) and (max-width: 999px) {
  .LandingPage {
    .AutoMotoSearchHomepage {
      .holder {
        max-width: 40% !important;
        flex-basis: 40% !important;
      }

      .filters {
        max-width: 60% !important;
        flex-basis: 60% !important;
      }
    }
  }
}

@media all and (max-width: 959.98px) {
  .LandingPage {
    .AutoMotoSearchHomepage {
      .searchButton {
        width: 100%
      }
    }
  }
}

@media all and (max-width: 959.98px) {
  .LandingPage {
    .AutoMotoSearchHomepage, .Marketplace {
      .buttonsHolderSmallScreen {
        display: flex !important;
        margin-top: 20px;
        justify-content: space-between;

        .button {
          padding: 9px;
          height: 40px;
          width: 49%;
          font-weight: bold;
        }
      }

      .buttonsHolder {
        display: none !important;
      }
    }

    .AutoMotoSearchHomepage {

      .addOffer {
        background: transparent linear-gradient(180deg, $blue-pastel 0%, $blue-pastel-dark 100%) 0% 0% no-repeat;
        color: $white;
      }

      .compare {
        color: $blue-pastel;
        border: 1px solid $blue-pastel;
      }

      .autoImage.loaded, .imageSkeleton {
        width: 90%;
        padding: 0 !important;
      }

      .imageSkeleton {
        height: 80px;
      }

      .filters {
        margin-top: 30px;
      }
    }

    .Marketplace {

      .addOffer {
        background: transparent linear-gradient(180deg, $yellow-pastel 0%, $yellow-pastel-dark 100%) 0% 0% no-repeat;
        color: $white;
      }

      .searchCategories {
        color: $yellow-pastel;
        border: 1px solid $yellow-pastel;
      }
    }
  }
}


@media all and (max-width: 499.98px) {
  .LandingPage {
    padding: 0;

    &.MuiGrid-spacing-xs-1 > .MuiGrid-item {
      padding: 0;
    }

    .card {
      background-color: transparent;
      border-radius: 0px;

      .onlyMobile {
        display: flex;
      }

      .onlyDesktop {
        display: none;
      }

      p {
        font-size: 11px;
        line-height: 16px;
        color: $light-grey;
        font-family: $open-sans;
        margin: 0;
      }

      h2 {
        display: flex;
        align-items: center;
        font-size: 20px;
      }

      h2:before {
        display: inline-flex;
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 6px;
        margin-right: 8px;
      }

      .Marketplace {
        padding: 0;

        h2:before {
          background-color: $yellow-pastel;
        }
      }

      .Benefits {
        padding: 0;

        h2:before {
          background-color: $yellow-pastel;
        }
      }

      .AutoMotoSearchHomepage {
        padding: 0;

        h2:before {
          background-color: $blue-pastel;
        }
      }

      .InfoPanelHomepage {
        padding: 0;

        h2:before {
          background-color: $blue-pastel;
        }
      }
    }
  }
}

.skeletons {

  &.homepage {
    width: 100%;
  }

  .skeleton {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: $white;

    &:first-child {
      margin-bottom: 10px;
    }

    &.loadingCategories {
      padding: 8px;
      margin-bottom: 0;
    }    

    .rectangle {
      width: 70px;
      height: 70px;
      margin: auto;
      border-radius: 4px;
    }

    .border {
      width: 70px;
      height: 8px;
      margin: auto;
      margin-top: 5px;

      &.categories {
        height: 17px;
        width: 160px;
        margin-top: 10px;
      }
    }

  }  
}

.carousel-wrapper {
  @media all and (max-width: 499.98px) {
    &.MuiGrid-item {
      margin-bottom: 10px;
    }
  }

  &.first {
    order: 5;
  }

  &.second {
    order: 6;
  }

  &.last {
    order: 7;
  }
}

.carousel {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  background-color: $white;

  .loadingWrapper {
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    position: absolute;
    background-color: $white;

    &.items {
      min-height: 100px;
    }

    .loadingSpinner {
      position: absolute;
      color: $red-pastel;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
    }    
  }

  &__all-categories {
    height: 46px;

    .MuiButton-label {
      cursor: pointer;
      max-width: 50%;
      color: $cyan-pastel;
      text-transform: uppercase;
      font: 13px/17px $open-sans;
    }
  }

  &__back-button {
    height: 30px;

    &.MuiButton-root {
      color: $white;
      border-radius: 0;
      background-color: $grey-mat-focus;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:hover {
        background-color: $grey-mat-focus;
      }
    }

    .MuiButton-label {
      cursor: pointer;
      font: 12px/17px $open-sans;
    }

    .icon-button {
      margin-right: 1rem;
      margin-left: 0px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 28px;
        height: 28px;
      }

      svg {
        width: 14px;
        height: 14px;
        -ms-transform: rotate(180deg); /* IE 9 */
        transform: rotate(180deg);
      }
    }
  }

  &__parent-title {
    height: 36px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    color: $dark-grey;
    background-color: $white-snow;
    font: 16px/24px $open-sans;
  }

  &__title {
    margin: 10px 0 5px;
    text-align: center;
    text-transform: uppercase;
    font: bold 14px/19px $open-sans;
  }

  &__subtitle {
    margin: 0 0 10px;
    text-align: center;
    text-transform: uppercase;
    font: normal 12px/17px $open-sans;
  }

  &__businesses {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }

  &__business {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__business-name {
    text-align: center;
    margin-top: 10px;
    text-transform: uppercase;
    font: 600 13px/17px $open-sans;
  }

  .results {
    display: flex;
    flex-direction: row;
    justify-content: center;

    &__button {
      width: 100px;
      margin: 4px 0;
      padding: 4px 16px;
      border-radius: 0;
      border-bottom: 1px solid transparent;

      &.underline {
        border-color: $cyan-pastel;
      }
  
      .MuiButton-label {
          color: $cyan-pastel;
          text-transform: uppercase;
          font: 13px/17px $open-sans;
      }
    }
  }

  .categories {
    cursor: grab;
    display: flex;
    overflow: hidden;
    flex-direction: row;
    position: relative;
    background-color: $white;

    &__divider {
      min-width: 2px;
      background-color: white;
    }

    &__item {
      flex-grow: 1;
      padding: 14px;
      color: $light-grey;
      width: calc(33% - 30px);
      min-width: calc(33% - 30px);
      display: flex;
      text-align: center;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      font: 12px/14px $open-sans;
      border-bottom: 6px solid transparent;

      &--thin {
        width: 100px;
        min-width: 100px;
      }

      svg {
        margin-bottom: 6px;
        filter: brightness(0) saturate(100%) invert(48%) sepia(0%) saturate(999%) hue-rotate(176deg) brightness(90%) contrast(83%);;
      }
    }
  }

  .all-categories {
    position: relative;

    &__list-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 10px;
      height: 35px;
      border-bottom: 1px solid $light-grey-bg;
      border-left: 6px solid transparent;
      font: bold 13px/17px $open-sans;

      &:first-child {
        border-top: 1px solid $light-grey-bg;
      }

      &.transparent {
        border-left-width: 0px;
        padding-left: 24px;
      }

      svg {
        max-height: 48px;
        padding: 0 20px 0 10px;
        filter: brightness(0) saturate(100%);
      }
    }
  }
}
