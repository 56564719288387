@import "../../../scss/variables";

.body {
  padding-top: 10px;
  padding-bottom: 30px;
  max-width: unset !important;
  background-color: $light-grey-bg;
  font-family: $open-sans;

  @media all and (max-width: 499.98px) {
    padding-bottom: 70px;
  }

  .MuiInputBase-root{
    height: 36px;

    .MuiInputBase-input {
      padding: 6px 10px;

      @media all and (max-width: 499.98px) {
        padding: 4px 8px;
      }
    }
  }

  .MuiTabs-root .MuiTabs-indicator {
    background-color: $red-pastel;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid $grey-mat-focus;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  width: 100%;
  flex-grow: 1;

  .MuiGrid-spacing-xs-1 {
    width: 100%;
  }

  &.full-height {
    display: flex;
    flex-direction: column;

    @media all and (min-width: 960px) {
      flex-grow: 1;

      .height-grow-item {
        height: 100%;
      }
    }
  }
}

@media all and (max-width: 319.98px) {
  .container {
    min-width: 320px;
    overflow: auto;
  }
}
