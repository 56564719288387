@import "src/scss/variables";

.checkout {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1400px;
  padding: 0 5px;
  margin: 0 auto !important;

  @media all and (max-width: $media-xs) {
    padding: 0;
  }

  &__title {
    width: 100%;
    margin: 5px 5px 5px 35px;
    order: 0;
    font-weight: 600;

    @media all and (max-width: $media-xs) {
      display: none;
    }
  }

  &__main {
    width: calc(100% - 390px);
    margin: 5px;

    @media all and (max-width: $media-lg) {
      width: 100%;
      order: 2;
    }

    @media all and (max-width: $media-xs) {
      margin: 0;
    }
  }

  @media all and (max-width: 499.98px) {
    padding-bottom: 70px;
  }

  @import './components/LoadingSkeleton/LoadingSkeleton';
  @import './components/DeliveryPaymentOptions/DeliveryPaymentOptions';
  @import './components/CheckoutItems/CheckoutItems';
  @import './components/Summary/Summary';
}
