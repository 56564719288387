@import "src/scss/variables";

.multilevel-dropdown {
  width: 100%;
  position: relative;

  &--enabled {
    cursor: pointer;
    pointer-events: auto;
  }  

  &--disabled {
    opacity: 0.2;
    cursor: not-allowed;
    pointer-events: none;
  }

  &__label {
    top: -6px;
    left: 15px;
    z-index: 8;    
    position: absolute;
    background-color: $white;
    color: rgba(0, 0, 0, 0.54);
    padding: 0 5px;
    font: normal 12px/14px $open-sans;
  }

  .navbar {
    &__list {
      list-style: none;
      margin: 0;
      padding: 0;      
    }

    /* Icon Button */
    &__list-item {
      z-index: 0;
      width: 100%;
      min-height: 40px;
      background-color: $white;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transition: filter 300ms;
      border: 1px solid rgba(0, 0, 0, 0.23);
      position: relative;

      &--open {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }

  .chosen-item {

    &__label {
      display: flex;
      align-items: center;
      color: $dark-grey;
      padding: 5px 30px 5px 15px;
      letter-spacing: 0.13px;
      font: normal 14px/24px $open-sans;
  
      @media all and (max-width: 499.98px) {
        padding: 5px 30px 5px 10px;
      }

      &--withInput {
        width: calc(100% - 60px);
  
        .searchInput {
          .MuiInputBase-root {
            height: 24px;
            
            &::before {
              display: none;
            }
    
            .MuiInputBase-input {
              height: 24px;
              padding: 0;
            }
          }
        }
      }
    }

    &--with-icon, &--without-icon, .icon-wrapper {
      margin-right: 1rem;
      min-width: 24px;
      height: 24px;
      border-radius: 50%;

      img {
        width: 18px;
        height: 18px;
        padding: 0 4px;
      }

      .icon {
        width: 20px;
        height: 20px;
      }
    }
  
    &--with-icon, .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
  
      img {
        filter: brightness(0%);
      }
    }
  
    &--without-icon {
      box-shadow: 2px 1px 2px 0.1px $grey-mat-unfocus;
  
      img, svg {
        visibility: hidden;
      }
    }
  
    &__breadcrumbs {
      z-index: 0;
      display: flex;
      align-items: center;
  
      .icon-wrapper {
        margin-right: 0px;
        color: $grey;
      }
    }
  
    &__arrow {
      position: absolute;
      right: 5px;
      width: 1.5em;
      height: 1.5em;
      color: $grey-mat-focus;
    }
  }

  .icon-button, .icon-wrapper {
    &:hover {
      filter: brightness(1.2);
    }    
  }

  /* Dropdown Menu */
  .dropdown {
    position: relative;
    top: 0;
    width: 100%;
    background-color: $white;
    overflow: hidden;
    transition: height 500ms ease;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-top: none;
    border-radius: 0 0 4px 4px;

    &__menu {
      width: 100%;
      padding-top: 0;
      position: relative;
    }

    &__circular-progress {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $white;
      opacity: 0.5;
      z-index: 300;
      display: flex;
      align-items: center;
      justify-content: center;      
    }

    .menu {
      &__items-wrapper {
        max-height: 358px;
        overflow-y: auto;
      }

      &__item {
        height: 50px;
        padding: 0 1rem;
        color: $dark-grey;
        cursor: pointer;        
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: background 500ms;
        letter-spacing: 0.43px;
        font: normal 14px/16px $open-sans;
        border-bottom: 1px solid $grey-mat-unfocus;

        &--button {
          &:hover {
            background-color: rgba(0, 0, 0, 0.04);
          }

          .menu__icon-wrapper--without-icon {
            width: 12px;
            height: 12px;
            margin-left: 35px;
          }
        }

        &--back {
          background-color: $grey-mat-focus;
          color: $white;
          height: 30px;
          font-size: 12px;
          border-bottom: 0px;

          .menu__icon-wrapper svg {
            width: 14px;
            height: 14px;
            transform: rotate(180deg);
            -ms-transform: rotate(180deg); /* IE 9 */
          }
        }

        &--parent {
          cursor: default;
          background-color: $white-snow;
          box-shadow: 0px 6px 8px 0px $grey-mat-unfocus;
        }

        &-expand {
          margin-left: auto;
          color: $grey;
          width: 12px;
          height: 12px;

          svg {
            width: 12px !important;
            height: 12px !important;
          }
        }
      }

      &__icon-wrapper {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
        margin-left: 0px;
        border-radius: 50%;

        &--with-icon {
          width: 36px;
          height: 36px;

          svg {
            width: 26px;
            height: 26px;
          }
        }

        &--without-icon {
          box-shadow: 2px 1px 2px 0.1px $grey-mat-unfocus;
        }
      }
    }

    /* CSSTransition classes  */
    .menu-primary-enter {
      position: absolute;
      transform: translateX(-110%);
    }

    .menu-primary-enter-active {
      transform: translateX(0%);
      transition: all 500ms ease;
    }

    .menu-primary-exit {
      position: absolute;
    }

    .menu-primary-exit-active {
      transform: translateX(-110%);
      transition: all 500ms ease;
    }
  }
}
