@import "src/scss/variables";

.productReturns {
  
  .myOffers {
    border-radius: 4px;
  }

  .tabs {
    width: 100%;
    border-radius: 4px;
    margin-bottom: 1rem;

    .MuiTab-root {
      max-width: unset;

      .MuiTab-wrapper {
        display: inline;
      }
    }

    &__title {
      &__count {
        font-weight: bold;
        color: $red-pastel;
      }
    }
  }

  .table {
    .productName {
      
      &__name {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;

        &.extendedCell {
          font-family: $open-sans;
          font-size: 14px;
          font-weight: bold;
          color: $dark-grey;
        }

      }

      &__extendedInfo {
        padding-top: 5px;
        font-size: 11px;
        color: $dim-gray;
        text-decoration: underline;

        .link {
          color: $dim-gray !important;
        }
      }
    }

    .imageWrapper {
      $image-size: 64px;

      display: flex;
      align-items: center;
      justify-items: center;

      img {
        margin: auto;
        max-width: $image-size;
        max-height: $image-size;
        object-fit: contain;
      }
    }

    .MuiTableCell-root:empty {
      border-bottom: 0;
    }
  
    .MuiCollapse-wrapper {
      .MuiTableCell-root {
        border-bottom: 0;
      }
    }
  }
}

.refundStatus {
  .status {
    padding: 5px 10px;
    border-radius: 16px;
    letter-spacing: 0.25px;
    white-space: pre;

    &.requested {
      background-color: $grey-mat-unfocus;
    }
    
    &.rejected {
      background-color: $gold-digger;
    }
    
    &.partiallyAccepted {
      background-color: $green-pastel;
    }
     
    &.accepted {
      background-color: $green;
    }
  }
}