@import "src/scss/variables";
@import "components/PaymentMethod/PaymentMethod";

.CreditTransactionCard {

  @media (min-width: 960px) and (max-width: 1150px) {
    .balanceHolder, .cardsHolder {
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  @media all and (max-width: 799.98px) {
    .balanceHolder, .cardsHolder {
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  .creditCards {
    height: calc(100% - 52px);
  }

  .mt-15 {
    margin-top: 15px;
  }

  .mb-5 {
    margin-bottom: 5px;
  }

  .mb-15 {
    margin-bottom: 15px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .ml-15 {
    margin-left: 15px;
  }


  .roboto {
    font-family: $roboto;
  }

  .dark {
    color: $dark-grey;
  }

  .MuiDivider-root {
    width: 100%;
    background-color: $grey-mat-unfocus;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .MuiTabs-root {
    height: 40px;
    width: 100%;
  }

  .tabs {
    .MuiButtonBase-root {
      &:not(.Mui-selected) {
        background-color: $white-snow;
      }
    }
  }

  .mobileJustify {
    @media all and (max-width: 499.98px) {
      text-align: justify;
    }
  }

  .accountBalance {
    padding: 20px;
    @media all and (max-width: 499.98px) {
      padding: 10px;
    }

    .infoCircleIcon {
      padding-left: 10px;

      svg {
        color: $grey;
        width: 11px;
        height: 11px;
      }
    }

    .tip {
      color: $grey;
    }

    .bulbIcon {
      margin-right: 20px;
    }
  }

  .CreditCards {
    box-sizing: border-box;
    position: relative;
    padding: 20px;
    width: 100%;

    .loadingContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 12;
      height: calc(100% - 40px);
      width: calc(100% - 40px);
      background-color: rgba(255, 255, 255, 0.75);
    }

    .addCardWrapper {
      font: normal 14px/18px $open-sans;
    }

    .accordionSummary {
      &:not(.Mui-expanded) {
        padding: 0;
      }

      &.Mui-expanded {
        background-color: $grey-mat-unfocus;
        @media all and (max-width: 499.98px) {
          padding: 0 5px;
        }
      }

      .cardImage {
        height: 22px;
        padding-right: 10px;
      }

      .textTransformed {
        text-transform: capitalize;
      }
    }

    .MuiAccordionDetails-root {
      .MuiGrid-root {
        width: 100%;
      }

      @media all and (max-width: 499.98px) {
        padding: 8px 5px 5px;
      }
    }

    .MuiTypography-root {
      width: 100%;
    }

    .expired {
      color: red;
    }

    .Mui-expanded:not(.MuiButtonBase-root) {
      border: 1px solid $grey-mat-unfocus;
    }
  }

  .TransactionsOverview {
    .infoCircleIcon {
      padding-left: 10px;

      svg {
        color: $grey;
        width: 11px;
        height: 11px;
      }
    }

    .bold {
      font-weight: bold !important;
    }

    .positive {
      color: $green;
    }

    .negative {
      color: $red;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $grey-mat-unfocus !important;
    }

    .MuiTableRow-hover:hover {
      cursor: default;
    }

    .filters {
      @media all and (max-width: 499.98px) {
        .MuiGrid-spacing-xs-1 {
          margin: 0;
        }
      }
    }

    .categoriesStatus {
      position: unset;
      right: 0;
      width: 100%;
    }

    .filterHolder {
      margin-bottom: 10px;
      width: 275px;
      @media all and (max-width: 499.98px) {
        width: 100%;
        max-width: 100%;
        padding: 0;
      }

      .customSelect {
        width: 100%;
  
          .MuiSelect-root {
            z-index: 2;
            padding-right: 20px;
          }
  
          .MuiSvgIcon-root {
            position: absolute;
            right: 0;
          }
        }

      .typeSelect {
        option{
          &.transactionTypeGroup {
            font-weight: bold;
            font-style: italic;
          }
        }
      }
    }

    .seller-payout {
      color: $dark-grey;
      align-items: center;
      margin-bottom: 10px;
      font: normal 12px/14px $open-sans;

      &__icon {
        display: flex;
        color: $grey;
        padding: 10px;

        svg {
          width: 12px;
          height: 12px;
        }
      }

      &__description {
        width: calc(100% - 33px);
      }
    }

    .styled-searchBar {
      margin: 0;
      width: 250px;
      max-width: 250px;
      @media all and (max-width: 499.98px) {
        width: 100%;
        max-width: 100%;
        margin-top: 5px;
      }
    }

    .filterContainer .categoriesStatus-container {
      min-height: unset;
    }
  }

  .balance {
    &__loading {
      width: 60px;
      height: 14px;
    }
  }
}

.TopUpCredit {
  .loadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  .dark {
    color: $dark-grey;
  }

  .creditOptions {
    margin-top: 20px;
    margin-bottom: 30px;

    .MuiButtonBase-root {
      margin-bottom: 5px;
    }
  }

  .secondary {
    border: 1px solid $red-pastel;
    color: $red-pastel !important;
    height: 40px;
  }

  .MuiButtonBase-root {
    font-weight: bold;
  }

  .MuiFormControl-root {
    width: auto !important;
    margin-bottom: 20px;
  }

  .buttonsHolder {
    margin-bottom: 15px;
    margin-top: 25px;
    @media all and (max-width: 499.98px) {
      margin-bottom: 30px;
    }

    @media all and (max-width: 499.98px) {
      align-items: center;
    }

    .MuiButtonBase-root {
      margin-left: 40px;
      @media all and (max-width: 499.98px) {
        margin-left: 0px;
      }
    }

    button:disabled {
      opacity: 0.6;
    }
  
    .topUpButton {
      .loadingSpinner {
        color: $white;
        margin-left: 10px;
      }
    }

  }
}
