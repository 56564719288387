@import "../../../scss/variables";

.passwordReset {
  background-color: $white;
  margin: 0 auto;
  padding: 15px 30px 30px 30px;
  border-radius: 4px;

  form {
    width: 100%;
  }

  h2, h5 {
    color: $dark-grey;
  }

  h5 {
    margin-bottom: 1.5rem !important;
  }

  .MuiFormControl-root {

    .MuiFormLabel-root {
      margin-top: -8px;
    }

    .MuiInputBase-root {
      height: 36px;
    }

    .MuiInputLabel-shrink {
      transform: translate(14px, 1px) scale(0.75);
    }
  }

  .buttonsHolder {
    .text-left {
      text-align: left;
    }

    .text-right {
      text-align: right;
    }

    .button {
      .keyIcon {
        display: none;
      }
    }

    .MuiButton-root {
      background-color: $red-pastel;
      color: $white;
      font-size: 14px;
      font-weight: bold;
      height: 40px;

      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.26);
      }
    }
  }

  .customLink {
    font: normal 14px $open-sans;
    letter-spacing: 0.47px;
    color: $cyan-pastel;
    text-decoration: none;
    text-align: start;

    &:hover {
      color: $cyan-focus;
    }
  }

  .uppercase {
    text-transform: uppercase;
  }

  .icon {
    width: 100%;
    max-width: 3.4375rem;
  }

  .validation {
    font-family: $open-sans;
    margin-top: 2px;
    margin-bottom: 10px;
    text-align: left;
    color: $red;
    font-size: 12px;
    line-height: 24px;
    min-height: 24px;
    letter-spacing: 0.11px;
  }

  .onlyMobile {
    display: none;
  }

  .success {
    .successHolder {
      .holder {
        margin-right: 10px;
      }
    }
  }
}

@media all and (max-width: 499.98px) {
  .passwordReset {
    padding: 15px 10px;

    .MuiInputBase-root {
      height: auto;
    }

    label + .MuiInput-formControl {
      margin-top: 8px;
    }

    .buttonsHolder {
      .link {
        display: none !important;
      }

      .button {
        max-width: 100% !important;
        flex-basis: 100%;

        .MuiButton-root {
          background-color: $golden-rod;
          width: 100%;
          height: 45px;
        }

        .keyIcon {
          display: block;
          margin-left: 10px;
        }

        .Mui-disabled {
          .keyIcon {
            filter: invert(100%) opacity(0.25);
          }
        }
      }
    }

    .success {
      display: flex;
      margin-top: 24px;
      border-radius: 4px;
      padding: 28px 20px 24px 20px;
      background-color: $medium-sea-green;

      .icon {
        width: 24px;
      }

      .customLink {
        display: none;
      }

      h5 {
        color: $white;
        font-size: 14px;
        margin-top: 24px;
      }

      .successHolder {
        max-width: 100%;
        flex-basis: 100%;

        .holder {
          display: flex;
          align-items: center;

          h2 {
            color: $white;
            margin: 0 16px 0 0;
          }
        }
      }
    }

    .mobileButtons {
      margin-top: 39px;

      .MuiButton-outlined {
        border: 1px solid $black;
        width: 100%;
      }

      .MuiButton-outlined:first-child {
        margin-bottom: 24px;
      }

      .MuiButton-label {
        font-size: 16px;
        font-weight: 500;
        justify-content: flex-start;
        align-items: center;
      }

      .MuiButton-startIcon {
        .MuiIcon-root {
          width: auto;
          height: auto;
          display: flex;
        }
      }

      .MuiButton-endIcon {
        position: absolute;
        right: 20px;
      }
    }

    .onlyMobile {
      display: block;
    }
  }
  .paddingBottomMobile {
    padding-bottom: 10px;
  }
}