@import "../../../scss/variables";

.oauth {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
  
  &__button {
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.25rem;

    &--google {
      margin-bottom: 0.5rem;
      border: 1px solid #939393;

      @media all and (max-width: 499.98px) {
        margin-bottom: 1rem;
      }

      &:hover {
        box-shadow: 0 3px 4px 0 rgb(0 0 0 / 20%);
      }

      &.mobile {
        margin-bottom: 0;
      }
    }

    &--facebook {
      background-color: #1B74E4;

      &:hover {
        box-shadow: 0 3px 4px 0 rgb(27 116 228 / 20%);
      }
    }
  }

  &__wrapper {
    &--facebook.MuiButtonBase-root {
      margin-bottom: 1rem;
    }
  }

  &__link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    font-family: $open-sans;
    font-weight: bold;
    font-size: 0.875rem;

    &--google {
      color: #757575;
    }

    &--facebook {
      color: white;
    }
  }

  &__logo {
    margin-right: 0.5rem;
  }
}
