@import "src/scss/variables";

.multilevel-dropdown.automotoDropdown {

  .navbar__list-item {
    min-height: 34px;
    border-color: $grey-mat-unfocus;

    .chosen-item__search-input {

      .MuiInputBase-root {
        height: 24px;
        
        &::before {
          display: none;
        }

        .MuiInputBase-input {
          height: 24px;
          padding: 0;
        }
      }
    }
  }

  .dropdown {
    .menu {
      &__items-wrapper {
        max-height: 356px;
        overflow-y: auto;
      }

      &__item {
        &:focus {
          background-color: rgba(0, 0, 0, 0.04);
        }

        &--no-results {
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }
}
