@import "src/scss/variables";

.pb-20 {
  @media all and (max-width: 499.98px) {
    padding-bottom: 20px;
  }
}

.loginHolder {
  background-color: $white;
  margin: 0 auto;
  padding: 15px 30px 30px 30px;
  border-radius: 4px;

  form {
    width: 100%;
  }

  .onlyDesktop {
    display: block;
  }

  &.verified {
    padding: 15px 30px;
    margin-bottom: 10px;

    font: normal 14px/20px $open-sans;
    letter-spacing: 0.47px;
    color: $dark-grey;

    .icon {
      width: 100%;
      max-width: 3.4375rem;
      margin-right: 10px;
    }

    @media all and (max-width: 499.98px) {
      color: $white;
      background-color: $medium-sea-green;
      margin: 15px 10px;
      width: calc(100% - 20px);

      .icon {
        width: 24px;
        margin: 0 0 20px 0;
      }

      .validation-message {
        text-align: justify;
      }
    }
  }

  &.error {
    padding: 30px;
    margin-bottom: 10px;

    font: normal 14px/20px $open-sans;
    letter-spacing: 0.47px;
    color: $dark-grey;
    border: 1px solid $red;

    .icon {
      width: 100%;
      max-width: 40px;
      margin-right: 30px;
    }

    .title {
      font: normal 600 16px/20px $open-sans;
      color: $red;
    }

    @media all and (max-width: 499.98px) {
      margin: 15px 10px;
      width: calc(100% - 20px);
      background-color: $white;
    }

    .error__container {
      width: auto;
      max-width: calc(100% - 70px);
    }
  }

  .validation {
    font-family: $open-sans;
    margin-top: 2px;
    margin-bottom: 10px;
    text-align: left;
    color: $red;
    font-size: 12px;
    line-height: 24px;
    min-height: 24px;
    letter-spacing: 0.11px;
  }

  .MuiFormControl-root {
    width: 100%;

    .MuiInputLabel-formControl {
      margin-top: -9px;
    }

    .MuiInputBase-root {
      color: $grey-mat-focus;
      height: 36px;
    }

    .MuiInputLabel-shrink {
      transform: translate(14px, 2px) scale(0.75);
    }
  }

  .buttonsHolder {
    .link {
      .customLink {
        font: normal 14px $open-sans;
        letter-spacing: 0.47px;
        color: $cyan-pastel;
        text-decoration: none;
        text-align: start;

        &:hover {
          color: $cyan-focus;
        }
      }

      .savePassword {
        display: none;
      }
    }

    .button {
      .keyIcon {
        display: none;
      }
    }

    .MuiButton-root {
      background-color: $red-pastel;
      color: $white;
      font-size: 14px;
      font-weight: bold;
      height: 40px;

      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.26);
      }
    }
  }

  .lineHolder {
    margin: 50px auto 15px auto;

    .border {
      border-bottom: 1px solid #707070;
      padding: 0;
      align-items: center;
    }
  }

  h5 {
    cursor: pointer;
    letter-spacing: 0.47px;
    color: $cyan-pastel;
    text-transform: uppercase;
    text-align: center;

    &:hover {
      color: $cyan-focus;
    }
  }
}

.textHolder {
  text-align: center;
  margin-top: 10px;
  letter-spacing: 0.4px;
  padding: 5px 10px;

  .link {
    color: $cyan-pastel;
    text-decoration: none;
    font-size: 12px;
  }
}


@media all and (max-width: 499.98px) {
  .loginHolder {
    padding: 15px 10px;

    .onlyDesktop {
      display: none;
    }

    .lineHolder {
      display: none;
    }

    .MuiInputBase-root {
      color: $dark-grey !important;

      .MuiIconButton-edgeEnd {
        margin-right: 0px;
      }
    }

    .buttonsHolder {
      flex-direction: column;

      .link {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;
        flex-basis: 100%;

        .savePassword {
          display: none;
        }
      }

      .button {
        width: 100%;
        flex-basis: 100%;
        max-width: 100%;
        margin-top: 24px;

        .keyIcon {
          display: block;
          margin-left: 10px;
          color: white;
        }
      }

      .MuiButton-root {
        width: 100%;
        height: 45px;
      }

      .makeStyles-icon-30 {
        border: 2px solid $grey-mat-focus;
      }

      .MuiTypography-body1 {
        font-size: 14px;
      }
    }
  }

  .textHolder {
    h6, .link {
      font-size: 11px;
    }
  }
  .registerButtonMobile {
    background-color: $white;
    padding: 30px 0px 50px 0px;
    width: 100%;

    .MuiButton-root {
      background-color: $white;
      color: $dark-grey;
      border: 1px solid $dark-grey;
      width: 100%;
      font-weight: bold;

      .MuiIcon-root {
        width: auto;
        height: auto;
      }
    }

    .lineHolder {
      display: flex;
      margin: 0px auto 10px auto;

      .border {
        border-bottom: 1px solid #707070;
        padding: 0;
        align-items: center;
      }
    }
  }
}

.company-verified {
  width: 480px;
  margin-left: -80px;

  .Toastify__toast-body {
    text-align: justify;
  }
}
