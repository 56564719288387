@import 'src/scss/variables';

.address-selector {
  margin-bottom: 30px;

  @media (max-width: $media-xs) {
    margin-bottom: 0;
  }

  &__title {
    margin-bottom: 5px;
    align-items: flex-end;

    @media (max-width: $media-xs) {
      margin: 10px 0 3px;
      padding-left: 10px !important;
    }
  }

  &__content {
    position: relative;
    pointer-events: none;

    @media (max-width: $media-xs) {
      padding: 10px 60px 10px 10px !important;
      background-color: $white;
      pointer-events: all;
    }

    &--allow-click {
      pointer-events: all;
    }

    &--lines {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      > span {
        display: block;

        @media (max-width: $media-xs) {
          display: inline;

          &:not(:last-child)::after {
            content: ', ';
          }
        }
      }
    }

    &--name {
      display: block;

      @media (max-width: $media-xs) {
        font-weight: bold;
      }
    }

    &--arrow {
      display: none;
      position: absolute;
      height: 20px;
      top: calc(50% - 10px);
      right: 20px;
      color: $grey-mat-focus;
      font-size: 1.2rem;

      @media (max-width: $media-xs) {
        display: block;
      }
    }
  }

  &__change {
    padding-left: 20px;

    @media (max-width: $media-xs) {
      display: none;
    }
  }

  &__add {
    padding: 10px 0;

    @media (min-width: $media-xs) {
      width: 80%;
      margin-top: 10px;
      border: 2px dashed $grey;
      border-radius: 4px;
      padding: 20px 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $open-sans;
    }
  }
}
