@import "src/scss/variables";

.checkout-items {
  margin-top: 10px;
  padding: 30px;
  background-color: $white;
  border-radius: 4px;

  @media (max-width: $media-xs) {
    padding: 0;
    background-color: transparent;
  }

  @import './components/ItemOfferData/ItemOfferData';
  @import './components/DeliveryTypeData/DeliveryTypeData';

  &__title {
    display: none;
    margin: 10px 0 3px;
    padding-left: 10px !important;
    font-weight: bold;

    h5 {
      font-weight: bold;
    }

    @media all and (max-width: $media-xs) {
      display: block;
    }
  }

  &__divider {
    width: 100%;
    background-color: $grey-mat-unfocus;
    margin: 30px 0;

    &:last-of-type {
      display: none;
    }

    @media (max-width: $media-xs) {
      display: none;
    }
  }

  .seller {
    width: calc(100% - 20px);

    @media (max-width: $media-xs) {
      margin-bottom: 10px;
      padding: 10px;
      background-color: $white;
    }

    &__name {
      margin-bottom: 15px;

      @media (max-width: $media-xs) {
        margin-bottom: 5px;
      }
    }

    &__group-divider {
      width: 100%;
      background-color: $grey-mat-unfocus;
      margin: 20px 0 30px;

      &:last-of-type {
        display: none;
      }

      @media (max-width: $media-xs) {
        margin: 10px 0;
      }
    }

    &__note {
      margin: 25px 0 0;
      width: calc(100% - 30px);

      @media (max-width: $media-xs) {
        display: none;
      }
    }
  }

  .mobile-note {
    position: relative;
    display: none;
    width: 100%;
    border-top: 1px solid $grey-mat-unfocus;
    padding-top: 10px;
    margin-top: 10px;

    @media (max-width: $media-xs) {
      display: block;
    }

    &__add {
      padding: 2px;
      text-align: center;

      &--text {
        text-transform: uppercase;
        margin-left: 10px;
      }
    }

    &__title {
      color: $black;
      font-weight: bold;

      & > span {
        font-size: 10px;
        line-height: 10px;
        font-weight: normal;
      }
    }

    &__value {
      color: $black;
      margin-right: 50px;
      white-space: pre-wrap; 
      text-align: justify;
      overflow-wrap: break-word;
      word-break: break-word;
      max-width: 100%;
    }

    &__arrow {
      position: absolute;
      height: 20px;
      top: calc(50% - 5px);
      right: 10px;
      color: $grey-mat-focus;
      font-size: 1.2rem;
    }

    &__popup-input {
      white-space: pre-wrap; 
      text-align: justify;
      overflow-wrap: break-word;
      word-break: break-word;
      max-width: 100%;
    }
  }
}
