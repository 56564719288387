@import "../../../scss/variables";

.content {
  width: 100vw;
  height: 100%;
  display: flex;
  background-color: $light-grey-bg;
  padding-top: 10px;
  padding-bottom: 30px;

  .MuiInputLabel-outlined {
    transform: translate(12px, 15px) scale(1);

  }
  
  .MuiInputBase-root{
    height: 36px;
  }
}

@media all and (max-width: 319.98px) {
  .content {
    min-width: 320px;
    overflow: auto;
  }
}