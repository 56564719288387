@import "../../../scss/variables";

.dropdown {

  &__content {
    z-index: 1000;
    cursor: pointer;
    display: none;
    position: absolute;
    padding: 9px 18px;
    border-radius: 2px;
    background-color: $white;
    border: 1px solid $grey-mat-unfocus;
    opacity: 0;
    -webkit-transition:  250ms ease-in-out;
    -moz-transition: opacity 250ms ease-in-out;
    -o-transition: opacity 250ms ease-in-out;
    transition: opacity 250ms ease-in-out;
  }

  &__overlay {
    opacity: 0;
    -webkit-transition: opacity 250ms ease-in-out;
    -moz-transition: opacity 250ms ease-in-out;
    -o-transition: opacity 250ms ease-in-out;
    transition: opacity 250ms ease-in-out;
  }

   &.visible{
    .dropdown__content {
      display: flex;
      flex-direction: column;
      opacity: 1;
    }

    .dropdown__overlay {
      position: absolute;
      top: 172px;
      left: 0;
      width: 100%;
      background-color: #000000;
      opacity: 0.45;
      z-index: 999;
      height: var(--overlayHeight);

      @media all and (min-width: 1280px) {
        top: 144px;
      }
    }
  }

  &--buttonWrapper {
    &.disbaled {
      opacity: 0.6;
    }
  }
}
