@import "src/scss/variables";

.table-button.MuiButton-root.MuiButton-contained {
  color: $grey-mat-focus;
  background: $white;
}

.toolbar-hidden.MuiToolbar-regular {
  min-height: unset;
}

.confirm {
  margin: 0;
  font: 16px $open-sans;
}

.filterContainer {
  overflow-x: auto;

  .categoriesStatus {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px;
    flex-direction: row;

    @media all and (min-width: 960px) and (max-width: 1279.98px) {
      position: absolute;

      &-container {
        width: 100%;
        min-height: 142px;
      }
    }

    @media all and (min-width: 320px) and (max-width: 959.98px) {
      position: absolute;
      right: 14px;
      width: calc(100% - 20px);

      &-container {
        width: 100%;
        min-height: 112px;
      }
    }

    @media all and (min-width: 500px) and (max-width: 599.98px) {
      &-container {
        width: 100%;
        min-height: 154px;
      }
    }
    
    @media all and (min-width: 350px) and (max-width: 499.98px) {
      &-container {
        width: 100%;
        min-height: 209px;
      }
    }

    @media all and (min-width: 320px) and (max-width: 349.98px) {
      &-container {
        width: 100%;
        min-height: 125px;
      }
    }
  }

  .searchBar-container {
    order: 2;

    .styled-searchBar {
      width: 100%;
      align-items: center;
      margin: 15px 8px 15px 15px;
      border: 1px solid $grey-mat-unfocus;

      @media all and (min-width: 500px) and (max-width: 959.98px) {
        width: calc(100% - 15px);
        margin: 0 8px 15px 15px;
      }

      @media all and (max-width: 499.98px) {
        margin: 0 0 15px 0;
      }

      .MuiInputBase-root {
        height: 36px;

        .MuiInputBase-input {
          padding: 4.5px 0px;
        }
      }

      &.MuiPaper-root {
        height: 36px;
      }
    }
  }
}

.MuiTable-root {
  position: relative;
}

.MuiTableHead-root {
  .MuiTableCell-head {
    &:not(.MuiTableCell-paddingCheckbox):not(.MuiTableCell-paddingNone) {
      padding: 12px;
    }
    
    color: $grey-mat-focus;
  }
}

.MuiTableBody-root {
  .MuiTableCell-root.MuiTableCell-body {

    .zero-results {

      @media all and (min-width: 320px) and (max-width: 719.98px) {
        left: calc(50% - 75px);
        width: 150px;
        text-align: center;
        position: absolute;
      }

      @media all and (max-width: 319.98px) {
        width: 150px;
        margin: 0 auto;
        text-align: center;
        position: relative;
      }
    }

    &:not(.MuiTableCell-paddingNone):not(.MuiTableCell-paddingCheckbox) {
      padding: 12px;
    }

    &.offerCategory {
      min-width: 110px;
    }

    &.price {
      white-space: nowrap;
    }

    &.offerItemName {
      min-width: 120px;
      word-break: break-word;

      .link {
        word-break: break-word;
      }
    }
  }
}

.MuiTablePagination-root {
  width: 100%;

  .MuiTablePagination-caption:first-of-type {
    @media all and (max-width: 499.98px) {
      display: none;
    }
  }

  .MuiTablePagination-selectRoot.MuiInputBase-root {

    @media all and (max-width: 499.98px) {
      display: none;
    }

    .MuiSelect-select.MuiTablePagination-select {
      padding-right: 24px;
    }  
  }  
}

.offerStatus {

    .draft {
      color: $grey-mat-focus;
      background-color: $white;
      border: 1px solid $grey !important;
    }

    .published {
      color: $white;
      background-color: $green;
      border: 1px solid $green !important;
    }

    .closed, .deactivated, .paused {
      color: $white;
      background-color: $grey;
      border: 1px solid $grey !important;
    }

    .denied {
      color: $white;
      background-color: $red;
      border: 1px solid $red !important;
    }

    .draft, .published, .closed, .deactivated, .denied, .paused {
      min-width: 94px;
      max-width: 94px;
      padding: 8px 14px 8px 8px;
      text-align: center; 
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-radius: 4px;
      font: 400 16px/19px $open-sans;
    }

    svg {
      width: 12px;
      height: 12px;
      cursor: pointer;
      margin-right: 6px;
    }
}

.absoluteLoading {
  position: absolute;
  width: 100%;
  height: calc(100% - 205px);
  display: flex;
  justify-content: center;
  background: #fff;
  opacity: .5;
  z-index: 2;

  .loadingSpinner {
    position: sticky;
    top: 50px;
    margin-top: 50px;
  }
}