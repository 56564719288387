@import "../../../scss/_variables";

$tableBorderWidth: 4px;
$tableBorderColor: $light-grey-bg;
$minOfferWidth: 200px;
$maxOfferWidth: 250px;

@mixin tableEnumTitle {
  font-family: $open-sans;
  font-size: 14px;
  color: $dark-grey;
}

@mixin tableHeaderCell {
  background: $white;
}

.vehicleComparisonPage {
  max-width: 1400px;
  margin: 0 auto !important;
  padding: 0 10px;

  .title {
    align-items: baseline;

    &__count {
      font-family: $open-sans;
      font-size: 14px;
      margin-left: 5px;
    }

    @media all and (max-width: 499.98px) {
      h2 {
        margin-bottom: 20px;
      }
    }

    .skeleton {
      min-width: 300px;
      min-height: 42px;
      margin-bottom: 40px;

      @media all and (max-width: 799.98px) {
        margin-bottom: 20px;
      }      
    }    
  }

  .settingsWrapper {

    &.outOfTable {
      padding: 10px 0px 20px 0px;

      &.hidden {
        display: none;
      }
    }

    height: 100%;
    width: 100%;
    align-content: flex-start;

    & > label {
      margin-top: 10px;

      &:first-child {
        margin: 5px;
      }
    }


    .MuiFormControlLabel-root {
      @include tableEnumTitle;
      display: flex;
      margin: 0px;

      .MuiFormControlLabel-label {
        flex: 1 1 100%;
        font-size: 13px;
      }
    }

    .showAllToggle {

      .MuiSwitch-colorPrimary.Mui-checked {
        color: $blue-pastel;
      }
      
      .MuiSwitch-track {
        background: $blue-pastel;
      }

      &__skeleton {
        width: 100%;
        height: 40px;
      }
    }

    .shareByLink {

      .MuiFormControlLabel-root {
        margin: 0px;
      }

      &__button {
        svg {
          margin-left: 10px;
          margin-right: 7px;
        }
      }

      &__iconSkeleton {
        width: 35px;
        height: 35px;
        margin-right: 10px;
      }

      &__textSkeleton {
        width: 70%;
        height: 40px;
      }
    }
  }

  .comparator {
    position: relative;

    &__tableContainer {
      overflow-x: auto;
      overflow-y: hidden;
      margin-bottom: 20px;

      &.notScrollable {
        overflow-x: unset;
        overflow-y: unset;
      }
    }

    &__table {
      margin-bottom: 20px;
      height: 100%;
      width: 100%;
      table-layout: fixed;
      border-collapse: separate;
      
      &.offers_1 {
        min-width: 300px;
        max-width: $maxOfferWidth * 2;
      }

      &.offers_2 {
        min-width: $minOfferWidth * 2;
        max-width: $maxOfferWidth * 3;
      }

      &.offers_3 {
        min-width: $minOfferWidth * 3;
        max-width: $maxOfferWidth * 4;
      }

      &.offers_4 {
        min-width: $minOfferWidth * 4;
        max-width: $maxOfferWidth * 5;
      }

      &.offers_5 {
        min-width: $minOfferWidth * 5;
        max-width: $maxOfferWidth * 6;
      }

      .MuiTableHead-root .MuiTableCell-head {
        padding: 15px 0px;
        border: solid $tableBorderColor $tableBorderWidth;
        border-width: $tableBorderWidth * 2 $tableBorderWidth;
        height: 100%;

        &:first-of-type {
          width: 250px;
        }
      }

      .MuiTableBody-root .MuiTableCell-body {
        border: solid $tableBorderColor $tableBorderWidth;
        border-top-width: 0px;
        border-bottom-width: 0px;
        background-color: $white;
        padding: 0px;

        &:first-of-type {
          border-right-width: 0px;
          background-color: $white-snow;
        }

        &:nth-of-type(2) {
          border-left-width: 0px;
        }

        &.comparator__propertyValue {
          padding: 5px 10px;
        }
      } 
            
      .MuiTableBody-root {
        .MuiTableRow-root:nth-of-type(even) {
          .MuiTableCell-root {
            background-color: $white-snow;
          }
        }
      }

      .sectionTitleRow {
        &__skeleton {
          width: 100%;
          height: 25px;
        }
      }

      &.scrollable {
        .firstColumn {
          display: none;
        }
      }
    }

    &__propertyValue {

      .cellTitle {
        font-size: 12px;
        text-align: start;
        padding: 3px 5px 0px 5px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.hidden {
          display: none
        }
      }
    }

    &__offerHeader {
      @include tableHeaderCell;

      .removeOfferButtonWrapper {
        position: relative;

        .removeButton {
          position: absolute;
          top: -25px;
          right: -5px;
          transition: all .2s ease-in-out;
          padding: 0px;
          border: 0px;

          &:hover {
            background: transparent;
            cursor: pointer;
            transform: scale(1.1);
          }

          svg {
            fill: $dark-grey;
          }
        }
      }
    }

    &__enumSectionTitle {
      @include tableEnumTitle;
      font-weight: bold;

      .cellTitle {
        font-size: 14px;
        font-weight: bold;
        color: $dark-grey;
        text-align: center;
        padding: 3px 5px 0px 5px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__enumTitle {
      @include tableEnumTitle;
      padding-left: 10px;
    }

    .noOffers {

      &__wrapper {
        min-height: 300px;
        background: $white;
        border-radius: 4px;
      }

      &__text {
        align-self: flex-end;

        @include tableEnumTitle;
        text-align: center;
        font-weight: bold;
      }

      &__redirectButton {
        align-self: flex-start;
        margin-top: 20px;

        font-family: $open-sans;
        font-weight: bold;
        font-size: 14px;

        background: $blue-pastel;
        color: $white;
      }
    }

  }

}
