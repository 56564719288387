.confirmation-popup {
  font-family: $open-sans;

  .confirmation-popup {
    &__content {
      font-size: 1rem;
      line-height: 1.5;
    }

    &__actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 40px;

      @media all and (max-width: 499.98px) {
        flex-direction: column-reverse;
        justify-content: center;
      }
    }

    &__cancel {
      margin-right: 40px;
      color: $cyan-pastel;

      &:hover {
        color: $medium-aquamarine;
      }

      @media all and (max-width: 499.98px) {
        margin-top: 20px;
        margin-right: 0;
      }
    }

    &__confirm {
      @media all and (max-width: 499.98px) {
        width: 100%;
      }
    }
  }
}
