@import "../../../../../scss/variables";

.roboto {
  font-family: $roboto;
}

.bold {
  font-weight: bold !important;
}

.pr-15 {
  padding-right: 15px;
}

.pl-30 {
  padding-left: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-50 {
  width: 50%;
}

.mt-5 {
  margin-top: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.pt-5 {
  padding-top: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.returnRequestDetail {

  .sellerOptions {
      padding: 20px 30px 20px 20px;

      & > * {
        max-width: 100%;
      }

      @media all and (max-width: 600px) {
        align-items: flex-start;
      }

      .MuiInputBase-input {
        padding: 10.5px 30px 10.5px 14px;
      }

      .MuiFormControl-root {
        padding-right: 10px !important;
        //min-width: 320px;

        @media all and (max-width: 600px) {
          width: 100%;
          margin-top: 15px;
          margin-bottom: 10px;
          padding-right: 0px !important;

          .MuiInputLabel-shrink {
            transform: translate(14px, 1px) scale(0.75) !important;
          }
        }
      }

    .statusSelect {
      min-width: 220px;

      option:disabled {
        color: rgba(0, 0, 0, 0.26) !important;
      }
    }
      
    .confirmButton {
      @media all and (max-width: 600px) {
        width: 100%;
      }
      
      &:disabled {
        color: rgba(0, 0, 0, 0.26) !important;
      }
    }

    .contactBuyerWrapper{
      margin: auto;

      @media all and (max-width: 600px) {
        justify-content: center;
      }

      .contactBuyer {
          display: inline-flex;
          align-self: flex-end;

          @media all and (max-width: 600px) {
            padding-top: 15px;
          }
      
          svg {
            width: 25px;
            color: $grey;
            margin-right: 10px;
            max-height: 25px;
          }

          a {
            color: $cyan-pastel;
            font-family: $open-sans;
            text-decoration: none;
            align-self: center;
          }
        }
    }
  }

  .requestDetailContent {
    padding: 20px 30px 20px 20px;
    position: relative;
    height: 100%;
    width: 100%;

    &__loading {
      &Wrapper {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.7);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        border-radius: 5px;

        .loadingSpinner {
          color: $red-pastel;
          position: relative;
          top: 50%;
          left: 50%;
        }
      }
    }

    .notFoundRequest {
      justify-content: center;
      align-items: center;
      min-height: 400px;
      width: 100%;
      font-family: $open-sans;
      font-size: 14px;
    }

    .statusCreated{
      &__status::after {
        content: ' ';
        padding: 0px 3px;
      }

      @media all and (max-width: 499.98px) {
        span {
          padding-right: 3px;
        }
      }
    } 

    .buyerInfo {
      display: flex;
    
      h4 {
        font-family: $open-sans;
        padding-right: 4px;
      }
    }

    .productItemDetail {
      $image-size: 64px;
  
      &__checkbox {
        display: flex;
        align-items: center;
        max-height: $image-size;
        padding-right: 10px;
      }
  
      &__image {
        display: flex;
        align-items: center;
        justify-items: center;
        height: $image-size;
        width: $image-size;
  
        img {
          margin: auto;
          max-width: $image-size;
          max-height: $image-size;
        }
      }
  
      &__info {
        font-family: $open-sans;
        flex: 1;
        padding-left: 20px;
  
        .name {
          font-size: 14px;
          font-weight: bold;
          color: $dark-grey;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;  
          overflow: hidden;
        }
  
        .state {
          font-size: 13px;
          color: $dark-grey;
          padding-top: 10px;
        }
  
        &__options {
          padding-top: 10px;
  
          @media all and (max-width: 959.98px) {
            display: none;
          }
  
          &.mobile {
            @media all and (min-width: 960px) {
              display: none;
            }
  
            @media all and (max-width: 959.98px) {
              display: contents;
            }
          }
          
          .counterTitle {
            font-size: 13px;
            font-weight: bold;
            color: $dark-grey;
            width: 100%;
  
            @media all and (max-width: 959.98px) {
              padding-top: 15px;
            }
          }
  
          .requestReasonInput {
            font-size: 12px;
  
            .MuiOutlinedInput-root {
              min-height: 120px;
              white-space: pre-wrap; 
              text-align: justify;
              overflow-wrap: break-word;
              word-break: break-word;
              max-width: 100%;
  
              .MuiOutlinedInput-input {
                height: 100% !important;
              }
            }
  
            .MuiFormHelperText-root {
              margin-left: auto;
            }
          }
  
          .requestReasonTitle {
            font-size: 13px;
            font-weight: bold;
            color: $dark-grey;
            width: 100%;
  
            @media all and (max-width: 959.98px) {
              padding-top: 15px;
            }
          }
  
          .requestReason {
            display: flex;
            margin: 0;
            font-size: 12px;
            white-space: pre-wrap; 
            text-align: justify;
            overflow-wrap: break-word;
            word-break: break-word;
            max-width: 100%;
          }
  
        }
  
        .price {
          font-family: $roboto;
          font-size: 14px;
  
          &__mobile {
            padding-top: 10px;
  
            @media all and (min-width: 500px) {
              display: none;
            }
          }
  
          &__desktop {
            @media all and (max-width: 499.98px) {
              display: none;
            }
          }
        }
      }
  
      .divider {
        display: none
      }
    }

    .divider {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    &__refundPrice {

      &__title {
        font-family: $open-sans;
        font-size: 14px;
        font-weight: bold;
      }

      &__price {
        margin-left: auto;
        font-family: $roboto;
        font-size: 14px;

        @media all and (max-width: 399.98px) {
          display: none;
        }

        &.mobile {
          @media all and (max-width: 399.98px) {
            display: contents;
          }

          @media all and (min-width: 400px) {
            display: none;
          }
        }
      
      }

      &__warningText {
        font-family: $open-sans;
        font-size: 11px;
        padding-bottom: 10px;
        color: $dim-gray;
      }

    }

    &__instructions {
      font-family: $open-sans;
      font-size: 14px;
      text-align: justify;

      &__title {
        font-weight: bold;
        font-size: 14px;
      }

      &__text {
        width: 100%;
        text-align: justify;
        color: $dark-grey;
      }

      &__notice {
        width: 100%;
        text-align: justify;
        font-size: 11px;
        color: $dim-gray;

        padding-top: 45px;

        @media all and (max-width: 499.98px) {
          padding-top: 20px;
        }
      }

    }

    .skeleton {
      margin: 5px;
      margin-bottom: 0px;

      .rectangle {
        width: 64px;
        height: 42px !important;
      }

      .lines-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 20px;
        flex: 1;

        &__line {
          width: 20%;
          height: 16px !important;
          margin-bottom: 10px !important;

          &:first-child {
            width: 100%;
          }

          @media all and  (max-width: 959.98px) {
              margin-bottom: 5px !important;
              width: 30%;
          }

          @media all and  (max-width: 499.98px) {
            width: 60%;
          }

          &.mobile {
            @media all and (min-width: 960px) {
              display: none;
            }
          }
        }
      }
    }

  }

  .mobileCreated {
    &__timestamp {
      padding-left: 10px;
    }
  }

}