@import "../../../scss/variables";
@import "./components/Categories";
@import "./components/AutomotoFilters";
@import "./components/CommonFilters";
@import "./components/Results";
@import "../../components/SellerInfo/SellerInfo";
@import "./NoResults/NoResults";
@import "./components/ResultsPageHeader";

.SearchResultPage {
  max-width: 1600px;
  margin: 0 auto !important;

  .MuiGrid-spacing-xs-1 > .MuiGrid-item {
    @media all and (max-width: 499.98px) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .resultPageHeader {
    margin-right: 8px;
    @media all and (max-width: 499.98px) {
      margin-right: 0px;
      padding-bottom: 0px !important;
    }
  }

  .resultsFiltersHolder {
    width: 300px;
    
    @media all and (max-width: 959.98px) {
      display: none;
    }

    .resultsFloatingWrapper {
      width: 100%;
      top: 10px;
      z-index: 100;
      position: sticky;
      position: -webkit-sticky;
      margin: 18px 0 10px 0;
      background-color: $white;

      .resultsFloatingButton {
        color: $white;
        width: 100%;
        padding: 26px 36px;
        display: flex;
        justify-content: space-between;
        font: normal 14px/16px $open-sans;

        svg {
          font-size: 40px;
        }
      }      
    }
  }

  .pagination {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 8px 8px 20px 0;
    border-radius: 4px;
    background-color: #fff;
    padding: 10px 30px 10px 20px;

    @media all and (max-width: 499.98px) {
      margin: 8px 0 20px 0;
      padding: 10px;
    }

    @media all and (max-width: 818px) {
      margin-top: 15px;
      justify-content: center;
      flex-direction: column;
    }

    @media (min-width: 960px) and (max-width: 1080px) {
      justify-content: center;
      flex-direction: column;
    }

    .MuiButton-root {

      &.Mui-disabled {
        opacity: 0.4;
      }

      &.MuiButton-text {
        padding: 2px 6px;
      }

      .MuiButton-label {
        color: $red-pastel !important;
        text-transform: uppercase;

        .MuiSvgIcon-root {
          margin: 0;
          font-size: 1.8rem;
          color: $grey-mat-focus;
        }

        @media all and (max-width: 599.98px) {
          font-size: 0;
        }
      }

      &:last-child {
        margin-left: 30px;
        @media (min-width: 1100px) and (max-width: 1150px) {
          margin-left: 5px;
        }
        @media (min-width: 1038px) and (max-width: 1099px) {
          margin-left: 0px;
        }
        @media all and (max-width: 1000px) {
          margin-left: 15px;
        }
        @media all and (max-width: 599.98px) {
          margin-left: 0px;
          min-width: 50px;
        }
      }

      &:first-child {
        margin-right: 30px;
        @media (min-width: 1100px) and (max-width: 1150px) {
          margin-right: 5px;
        }
        @media (min-width: 1038px) and (max-width: 1099px) {
          margin-right: 0px;
        }
        @media all and (max-width: 1000px) {
          margin-right: 15px;
        }
        @media all and (max-width: 599.98px) {
          margin-right: 0px;
          min-width: 50px;
        }
      }
    }

    .MuiFab-sizeSmall {
      width: 26px;
      height: 26px;
      margin: 0 1px;
      padding: 0 4px;
      border-radius: 4px;
      color: rgba(0, 0, 0, 0.87);
      background-color: transparent;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      &.MuiFab-primary {
        color: $red-pastel;
      }
    }
  }
}
