@import "../../../../scss/variables";

.searchCategoriesHolder {
  
  .searchCategories {
    background-color: $white;
    padding-bottom: 30px;

    &:before{
      display: none;
    }

    &.automoto {
      border-top: 4px solid $blue-pastel;
    }

    &.spare-parts {
      border-top: 4px solid $purple-pastel;
    }

    &.marketplace {
      border-top: 4px solid $yellow-pastel;
    }

    &.nehnutelnosti {
      border-top: 4px solid $green-pastel;
    }

    &.all {
      border-top: 4px solid $red-pastel;

      .iconHolder {
        svg {
          width: 30px;
          margin-right: 10px;
          vertical-align: middle;
          filter: brightness(0) saturate(100%);
        }

        &:before{
          display: none;
        }

        &.automoto {
          color: $blue-pastel;
        }

        &.spare-parts {
          color: $purple-pastel;
        }

        &.marketplace {
          color: $yellow-pastel;
        }

        &.nehnutelnosti {
          color: $green-pastel;
        }
      }

      .parents {
        height: 50px;
      }
    }

    .categoryText {
      width: 100%;
      text-align: left;
      font-size: 22px;
      line-height: 30px;
      font-weight: 600;
      color: $dark-grey;
      font-family: $open-sans;
      padding: 10px 0px 20px 40px;
    }

    .loadingSkeleton {
      width: 80%;
      padding: 6px 40px;

      .MuiSkeleton-text {
        height: 30px;
      }

      .MuiSkeleton-root {
        background-color: #f1f1f1;
      }
    }

    .ButtonGroup {

      .results-count {
        font-size: 12px;
      }
      
      .parents {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: none;
        padding: 0px 8px 0px 40px;

        .icon-button {
          display: flex;
          width: 12px;
          margin-right: 5px;
          transform: rotate(180deg);
        }

        &.lastClicked {
          .MuiButton-label {
            justify-content: flex-start;
            text-align: left;
            color: $dark-grey;
            font-weight: 600;
            padding-left: 18px;
          }
        }
      }

      .parents:hover {
        background-color: transparent;
        color: #44A8A7;

        .MuiButton-label {
          color: $cyan-pastel;
        }

        &.lastClicked {
          cursor: default !important;

          .MuiButton-label {
            color: $dark-grey !important;
            cursor: default !important;
            pointer-events: none;
          }
        }
      }

      .searchCategoryTitle {
        color: $black;
        padding-top: 6px;
        border-bottom: 0;
        padding-left: 58px;
        padding-bottom: 6px;
        text-align: start;
        justify-content: flex-start;
        font: bold 14px/16px $open-sans;
      }

      .btnHolder {
        border: none;

        .MuiButton-label {
          justify-content: flex-start;
          text-align: left;
          color: $dark-grey;
        }

        svg {
          width: 28px;
          height: 28px;
          margin-right: 15px;
        }

        &.firstLevel {
          padding-bottom: 6px !important;
          padding-top: 6px !important;
        }

        &.nextLevels {
          padding-left: 70px !important;
        }

        &.MuiButton-text {
          padding: 0px 8px 0px 40px;
        }
      }

      .btnHolder:hover {
        background-color: transparent;
        color: #44A8A7;

        .MuiButton-label {
          color: $cyan-pastel;

          svg {
            // obarví ikonku (svg) na zelenou
            // https://codepen.io/sosuke/pen/Pjoqqp
            filter: invert(57%) sepia(63%) saturate(352%) hue-rotate(130deg) brightness(90%) contrast(90%);
          }
        }
      }
    }
  }
}