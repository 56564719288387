.conversation-detail {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1 !important;
  height: 100%;
  min-width: 300px;
  overflow: hidden;

  &__title {
    padding: 10px;
    font-size: 14px;
    font-family: $open-sans;
    text-align: center;

    @media all and (max-width: 959.98px) {
      padding: 20px 10px;
      background-color: #f8f8f8;
    }
  }

  &__report {
    position: absolute;
    top: 7px;
    right: 10px;
    width: 20px;
    height: 20px;
    object-fit: contain;
    cursor: pointer;

    @media all and (max-width: 959.98px) {
      top: 12px;
      right: 15px;
      width: 30px;
      height: 30px;
    }
  }

  &__messages {
    position: relative;
    flex-grow: 1;
    min-height: 400px;
  }

  &__messages-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    align-content: flex-start;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
    overflow: hidden auto;
  }

  &__empty {
    font-family: $open-sans;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
