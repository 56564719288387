@import "src/scss/variables";

.checkout-skeleton {
  width: calc(100% - 390px);
  margin: 5px;

  @media all and (max-width: $media-lg) {
    width: 100%;
    order: 2;
  }

  @media all and (max-width: $media-xs) {
    margin: 0;
  }

  &__box {
    padding: 30px;
    background-color: $white;
    border-radius: 4px;

    @media all and (max-width: $media-xs) {
      padding: 0;
      background-color: transparent;
      border-radius: 0;
    }

    &-title {
      font-weight: bold;
      padding-bottom: 5px;

      h5 {
        font-weight: bold;
      }

      @media all and (max-width: $media-xs) {
        font-size: 14px;
        margin: 10px 0 3px 10px;
        padding: 0;
      }
    }

    &-desktop {
      @media all and (max-width: $media-xs) {
        display: none;
      }
    }

    &-mobile {
      display: none;

      @media all and (max-width: $media-xs) {
        display: block;
        padding: 10px;
        background-color: $white;
      }
    }

    &-seller {
      @media all and (max-width: $media-xs) {
        padding: 10px;
        margin-bottom: 10px;
        background-color: $white;
      }
    }

    &-seller-title {
      margin-bottom: 15px;

      @media all and (max-width: $media-xs) {
        margin-bottom: 10px;
      }
    }

    &-item {
      display: flex;
      flex-wrap: wrap;

      @media all and (max-width: $media-xs) {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid $grey-mat-unfocus;
      }
    }

    &-grow {
      flex-grow: 1;
    }

    &-delivery {
      margin-bottom: 10px;

      @media all and (max-width: $media-xs) {
        display: none;
      }
    }

    &-delivery-mobile {
      display: none;

      @media all and (max-width: $media-xs) {
        display: block;
      }
    }

    &-note-mobile {
      display: none;
      padding: 12px 2px 2px;
      margin-top: 10px;
      border-top: 1px solid $grey-mat-unfocus;

      .skeleton-line {
        margin: 2px auto !important;
      }

      @media all and (max-width: $media-xs) {
        display: block;
      }
    }

    &:last-of-type {
      margin-top: 10px;
    }
  }

  &__divider {
    margin: 30px 0;

    @media all and (max-width: $media-xs) {
      display: none;
    }
  }

  .skeleton-line {
    display: block;

    &.f13 {
      height: 13px;
      margin: 2px 0 4px;

      &:last-of-type {
        margin-bottom: 2px;
      }
    }

    &.f14 {
      height: 14px;
      margin: 2px 0 4px;

      &:last-of-type {
        margin-bottom: 2px;
      }
    }

    &.f35 {
      height: 15px;
      margin: 10px 0;
    }

    $widths: 250, 170, 160, 150, 120, 100, 70, 50, 40;

    @each $width in $widths {
      &.w#{$width} {
        width: #{$width}px;
        max-width: calc(100% - 20px);
      }
    }
  }

  .skeleton-image {
    width: 80px;
    height: 80px;
    margin-right: 20px;

    @media all and (max-width: $media-xs) {
      margin-right: 10px;
    }
  }

  .skeleton-area {
    width: calc(100% - 30px);
    height: 50px;
    margin-top: 50px;

    @media all and (max-width: $media-xs) {
      display: none;
    }
  }
}
