@import "src/scss/variables";

.newPassHolder {
  background-color: $white;
  margin: 0 auto;
  padding: 15px 30px 30px 30px;
  border-radius: 4px;

  .Mui-focused {
    color: $grey-mat-focus;
  }

  form {
    width: 100%;
  }

  .inputsHolder {
    margin: 0 auto;

    .MuiFormControl-root {

      .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
        transform: translate(14px, 12px) scale(1);
      }

      .MuiInputBase-root {
        height: 36px;
      }
    }

    .passwordRules {
      color: $grey-mat-focus;
      font-family: $open-sans;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      margin-top: 2px;
      margin-bottom: 0;
    }

    .validation {
      font-family: $open-sans;
      margin-top: 2px;
      margin-bottom: 10px;
      text-align: left;
      color: $red;
      font-size: 12px;
      line-height: 24px;
      min-height: 24px;
      letter-spacing: 0.11px;
    }
  }

  .buttonHolder {
    margin: 0 auto;
    text-align: right;

    .MuiButton-root {
      background-color: $red-pastel;
      color: $white;
      font-size: 14px;
      font-weight: bold;
      height: 40px;

      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.26);
      }
    }

    .resetPasswordButtonSpinner {
      color: $white;
      margin-left: 10px;
    }
  }
}

@media all and (max-width: 499.98px) {
  .newPassHolder {
    background-color: transparent;
    padding: 15px 10px;

    .buttonHolder {
      .MuiButton-root {
        height: 45px;
        width: 100%;
      }
    }
  }
}
