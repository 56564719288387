@import "../../../scss/_variables";
@import "./components/UserRatingSummary/UserRatingSummary";
@import "./components/UserRatingOverview/UserRatingOverview";

.UserRatingPage {
  max-width: 1600px;
  margin: 0 auto !important;

  .MuiGrid-spacing-xs-1 > .MuiGrid-item {
      @media all and (max-width: 499.98px) {
        padding-right: 0;
        padding-left: 0;
      }
  }

  .notFoundWrapper {
    background-color: $white;
    padding: 25px 30px;
    border-radius: 4px;
    min-height: 300px;

    .notFoundMessage {
      font-family: $open-sans;
      font-size: 14px;
    }

    @media all and (max-width: 499.98px) {
      padding: 10px 15px;
    }
  }
}