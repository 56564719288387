.message-input {
  display: flex;
  padding: 8px;

  &__input {
    margin: 0 8px;
    flex-grow: 1;

    .MuiInputBase-root {
      height: unset !important;
      padding: 3px 5px;
    }
  }

  &__attachment {

    svg {
      color: $grey;
    }
  }

  &__send {

    &:disabled {
      svg {
        color: $grey;
      }
    }

    svg {
      color: $red-pastel-dark;
    }
  }
}

.attachments-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px 8px 0;

  &__item {
    display: flex;
    align-items: center;
    margin-right: 16px;
    margin-bottom: 4px;

    &--error {

      .attachments-input__file {
        color: $red !important;
        cursor: default;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;

    &--before {
      margin-right: 4px;
    }

    &--after {
      margin-left: 4px;
      cursor: pointer;
    }
  }

  &__file {
    text-transform: none !important;
    font-size: 14px;
    color: $cyan-pastel !important;
  }
}
