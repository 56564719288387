@import "src/scss/variables";

.PaymentMethod {
  .mr-20 {
    margin-right: 20px;
  }

  .dark {
    color: $dark-grey;
  }

  .cardImage {
    width: 24px;
    margin-right: 15px;
  }

  .textTransformed {
    text-transform: capitalize;
  }

  .noCard {
    margin-top: 10px;
    padding: 20px 50px;
    border: 2px dashed $grey;
    border-radius: 4px;
  }
}
