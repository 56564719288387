@import "../../../../../scss/_variables";

.detailedRating {
  // padding-right: 90px;
  padding-right: 15px;
  font-family: $open-sans;

  @media all and (max-width: 499.98px) {
    padding-right: 0px;
  }
  
  .titleAndStarsRatingWrapper {

    .title {
      font-size: 14px;
      padding-left: 2px;

      &__wrapper {
        padding: 0px;
      }

      &__skeleton {
        width: 50%;
        min-width: 200px;
        height: 14px;
      }
    }

    .starsRatingWrapper {
      padding-top: 10px;

      .starsRating {
        .MuiRating-root {
          font-size: 25px;
          color: #f6ca6e;
          margin-right: 15px;
        }

        .MuiRating-iconEmpty {
          color: #f6ca6e;
        }

        &__skeleton {
          width: 40%;
          min-width: 160px;
          height: 25px;
        }
      }
    }
  }
  
  .numberRatingWrapper {
    padding-left: 20px;
    font-family: $roboto;

    .numberRating {
      font-size: 24px;

      &__skeleton {
        width: 50px;
        height: 40px;
      }
    }

    .numberRatingScale {
      font-size: 14px;
    }
  }
}
